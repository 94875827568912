
import { Option } from '../Actions/ActionType';
import { SET_FISCAL_MONTHS,SET_DEFAULT_FISCAL_MONTHS,SET_SELECTED_FISCAL_MONTHS,SET_CURRENCY_TYPES,SET_SELECTED_CURRENCY_TYPES,SET_SELECTED_DISPLAY_OPTIONS,SET_SELECTED_PAID_HEAD_COUNTS, SET_DEFAULT_CURRENCY_TYPES, SET_DEFAULT_DISPLAY_OPTIONS, SET_DEFAULT_PAID_HEAD_COUNTS, SET_DISPLAY_OPTIONS } from '../ActionContants/constants';

interface SingleSelectState{
   fiscalMonths: Option[];
   currencyTypes:Option[];
   displayOptions:Option[];
   selectedFiscalMonths: Option[];
   selectedCurrencyTypes: Option[];
   selectedDisplayOptions:Option[];
   selectedPaidHeadCounts:Option[];
   defaultFiscalMonth: Option[];
   defaultCurrencyType: Option[];
   defaultDisplayOption:Option[];
   defaultPaidHeadCount:Option[];
}

const initialState:SingleSelectState = {
    fiscalMonths: [],
    currencyTypes:[],
    displayOptions:[],
    selectedFiscalMonths:[],
    selectedCurrencyTypes:[],
    selectedDisplayOptions:[],
    selectedPaidHeadCounts:[],
    defaultFiscalMonth:[],
    defaultCurrencyType:[],
    defaultDisplayOption:[],
    defaultPaidHeadCount:[]
}

const singleSelectReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_FISCAL_MONTHS:
      return {
        ...state,
        fiscalMonths: action.payload,
      };
    case SET_SELECTED_FISCAL_MONTHS:
      return {
        ...state,
        selectedFiscalMonths: action.payload,
        defaultFiscalMonth: action.payload,
      };
      case SET_DEFAULT_FISCAL_MONTHS:
        return {
          ...state,
          defaultFiscalMonth: action.payload,
          selectedFiscalMonths: action.payload,
        };
    case SET_CURRENCY_TYPES:
          return {
            ...state,
            currencyTypes: action.payload,
    };    
 
    case SET_SELECTED_CURRENCY_TYPES:
      return {
        ...state,
        selectedCurrencyTypes: action.payload,
        defaultCurrencyType:action.payload,
      };
      case SET_DEFAULT_CURRENCY_TYPES:
      return {
        ...state,
        defaultCurrencyType:action.payload,
        selectedCurrencyTypes: action.payload,
      };

      case SET_DISPLAY_OPTIONS:
          return {
            ...state,
            displayOptions: action.payload,
    };
      case SET_SELECTED_DISPLAY_OPTIONS:
      return {
        ...state,
        selectedDisplayOptions: action.payload,
        defaultDisplayOption:action.payload,
      };
      case SET_DEFAULT_DISPLAY_OPTIONS:
      return {
        ...state,
        defaultDisplayOption:action.payload,
        selectedDisplayOptions: action.payload,
      };

      case SET_SELECTED_PAID_HEAD_COUNTS:
      return {
        ...state,
        selectedPaidHeadCounts: action.payload,
      };
      case SET_DEFAULT_PAID_HEAD_COUNTS:
      return {
        ...state,
        defaultPaidHeadCount:action.payload,
        selectedPaidHeadCounts: action.payload,
      };
    default:
      return state;
  }
};

export default singleSelectReducer;