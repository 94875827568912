import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { CombinedProvider } from './Pages/PowerBIContext';
import Store from './Redux/Store/Store';
import msalApp from "./authConfig";
 
 
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
// Handle the redirect callback
msalApp.handleRedirectCallback(() => {
  root.render( <Provider store={Store}>
    <CombinedProvider>
    <App />
    </CombinedProvider>
  </Provider>);
});
 
// Check if a redirect has occurred
if (msalApp.getAccount()) {
  console.log("it's my msalApp.getAccount()", msalApp.getAccount());
  root.render( <Provider store={Store}>
    <CombinedProvider>
    <App />
    </CombinedProvider>
  </Provider>);
} else {
  msalApp.loginRedirect();
}
 
 
// root.render(
//   // <React.StrictMode>
//   <Provider store={Store}>
//     <App />
//   </Provider>
//   //</React.StrictMode>
// );
 
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();