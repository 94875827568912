import axios from "axios";
import Config from "../Config";
import { getToken, getUsername } from "../Components/Services/MsalService";

export const getDefaultPrefenceValue = async () => {
  const response = await axios.get( 
    `${Config.apiBaseAddress}${Config.apiGetDefaultPreferenceValues}?ReportID=1&UserAlias=${await getUsername()}`,
    { headers: {"Authorization" : `Bearer ${await getToken()}`}}
  ); 

  console.log(response)
       return response.data.selectedFilterForSegmentDataModel; 
}


