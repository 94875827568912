import React, { useEffect, useState } from "react";
import { TbFileExport } from "react-icons/tb";
import { FiFilter, FiColumns, FiSettings, FiHelpCircle, FiGrid, FiBarChart2 } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "../../Redux/Reducers/types";
import { setExpandAllAction, setFilterGridColumnAction, setFilterGridHeaderAction } from "../../Redux/Actions/qtdDataActions";
import Settings from "../Settings";
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
import Columns from "../Columns";
import { SelectedFilterValues } from "../Common/GetDataFromStore";
import { getPowerbiAccessToken } from "../../Services/PowerbiAccessToken";
import { SlGraph } from "react-icons/sl";
import axios from "axios";
import Config from "../../Config";
import { getToken } from "../Services/MsalService";
import { useCombinedContext } from "../../Pages/PowerBIContext";
import { UserguideUrl , ContactusUrl ,TrainingUrl} from "../../Config";


function DataGridControl({ ...props }: any) {
  const dispatch = useDispatch();
  const [showButton, setshowButton] = useState(false);
  const { intialdefaultFilterPreference } = useSelector(
    (state: RootReducer) => state.preferences
  );
  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    'desc', 'actuals', 'pyactuals', 'dollervar', 'percentagevar', 'cph', 'pycph', 'cphvar', 'cphpercentage',
    'cyqtdactual', 'pyqtdactual', 'qtddollarvar', 'qtdpercentagevar', 'cycphqtd', 'pycphqtd', 'cphdollarvarqtd', 'cphqtdpercentage',
    'cyytdactual', 'pyytdactual', 'ytddollarvar', 'ytdpercentagevar', 'cycphytd', 'pycphytd', 'cphdollarvarytd', 'cphytdpercentage'
  ]);
  const qtdColumns = ['cyqtdactual', 'pyqtdactual', 'qtddollarvar', 'qtdpercentagevar', 'cycphqtd', 'pycphqtd', 'cphdollarvarqtd', 'cphqtdpercentage'];
  const ytdColumns = ['cyytdactual', 'pyytdactual', 'ytddollarvar', 'ytdpercentagevar', 'cycphytd', 'pycphytd', 'cphdollarvarytd', 'cphytdpercentage'];

  const [actualformatNbr, setactualformatNbr] = useState(
    intialdefaultFilterPreference != null &&
      intialdefaultFilterPreference.formatNbr == "1"
      ? "Million"
      : intialdefaultFilterPreference != null &&
        intialdefaultFilterPreference.formatNbr == null
        ? "Thousand"
        : "Million"
  );
  const [formatNbr, setformatNbr] = useState(actualformatNbr);

  const { selectedDisplayOptions } = useSelector(
    (state: RootReducer) => state.singleSelect
  );
  const [toggle, setToggle] = useState(true);
  const buttonToggle = () => setToggle(!toggle);
  const { gridData, ExpandAllStatus } = useSelector(
    (state: RootReducer) => state.gridData
  );

  // const [chartMode, setChartMode] = useState(true);
  const { toggleView, setToggleView, chartMode, setChartMode, setApplyfilter, Gridloader , setPowerBiloader } = useCombinedContext();


  const handleButtonToggle = () => {
    setChartMode(!chartMode);
   // getPowerbiAccessToken();
    buttonToggle();
    props.handleGridChartToggle();
  };

  useEffect(() => {
    selectedDisplayOptions.length > 0 &&
      setformatNbr(selectedDisplayOptions[0].label);
  }, [selectedDisplayOptions]);

  useEffect(() => {
    setactualformatNbr(formatNbr.trim());
    setshowButton(
      gridData != null &&
        gridData.gridColumnHeaders != null &&
        Object.keys(gridData.gridColumnHeaders).length > 0
        ? true
        : false
    );
  }, [gridData]);

  // Settings Modal
  const [modalSettingsShow, setSettingsModalShow] = React.useState(false);
  const handleSettingsModalShow = () => {
    setSettingsModalShow(true);
  }

  // Columns Modal
  const [modalColumnsShow, setColumnsModalShow] = React.useState(false);
  const handleColumnsModalShow = () => {
    setColumnsModalShow(true);
  }
  const [btnFilterData, setBtnFilterData] = React.useState<any[]>([]);
  const [btnFilterAll, setBtnFilterAll] = React.useState<any>();
  const [btnFilterAllYtd, setbtnFilterAllYtd] = React.useState<any>();
  const [btnFilterDataYtd, setbtnFilterDataYtd] = React.useState<any[]>([]);
  const [originalLength, setOriginalLength] = React.useState<any>(0);
  const [originalLengthYtd, setOriginalLengthYtd] = React.useState<any>(0);
  let selectedValues = SelectedFilterValues();
  useEffect(() => {
    dispatch(setFilterGridColumnAction(selectedColumns));
  }, [selectedColumns, dispatch]);

 
  function areAllSelected(data: any[]): boolean {
    return data.every(item => item['1'][0].isSelected === true);
  }
  const [updatedDataQtd, setUpdatedDataQtd] = useState<any[]>([]);
  useEffect(() => {
    const updatedSelectedColumns: string[] = [];
    let data = Object.entries(gridData?.gridColumnHeadersSelected || {});
    const updatedData = data.map(([columnKey, columnArray]) => {
      if (Array.isArray(columnArray)) {
        columnArray.forEach(columnObj => {
          if (columnObj?.propertyName) {
            const propertyName = columnObj.propertyName.toLowerCase();

            //columnObj.isSelected = selectedColumns.includes(propertyName);
            
          if (propertyName.includes('false')) {
            // Set isSelected to false if propertyName contains 'false'
            columnObj.isSelected = false;
          } else {
            // Set isSelected to true if propertyName doesn't contain 'false'
            columnObj.isSelected = true;
          }

            updatedSelectedColumns.push(propertyName);
          }
        });
      }
      setSelectedColumns(updatedSelectedColumns);
      return [columnKey, columnArray];
    });

    const allSelected = updatedData.every(
      ([, columnArray]) =>
        Array.isArray(columnArray) &&
        columnArray.every(
          columnObj => columnObj.isSelected
        )
    );

    if (selectedValues.selectedType === "MTD") {

      
      setBtnFilterData(updatedData);
      setOriginalLength(updatedData.length);
      setBtnFilterAll(allSelected);
    } else {
      const updatedDataQtd = updatedData.slice(0, 8).map((item) => {
        return { ...item }
      });
      const updatedDataYtd = updatedData.slice(8, 16).map((item) => {
        return { ...item }
      });
      let selectedColumnsQtd = qtdColumns.filter((x) =>
        selectedColumns.includes(x)
      );
      let selectedColumnsYtd = ytdColumns.filter((x) =>
        selectedColumns.includes(x)
      );
      setBtnFilterData(updatedDataQtd);
      setbtnFilterDataYtd(updatedDataYtd);
      setOriginalLength(updatedDataQtd.length);
      setOriginalLengthYtd(updatedDataYtd.length);

      const allSelectedQTD = areAllSelected(updatedDataQtd);
      const allSelectedYTD = areAllSelected(updatedDataYtd);
      setBtnFilterAll(allSelectedQTD);      
      setbtnFilterAllYtd(allSelectedYTD);
      console.log("allSelectedQTD--allSelectedQTD:::   ",allSelectedQTD, allSelectedYTD);
    };

   


  }, [gridData]);


  const handlecallbackBtnClickr = (val: any) => {
    const returnedData = handlecallbackBtnClickCommonFunc(val, btnFilterData, originalLength);
    setBtnFilterData(returnedData.btnFilterData);
    setBtnFilterAll(returnedData.btnFilterAllData);
  };
  const handlecallbackBtnClickYtd = (val: any) => {
    const returnedData = handlecallbackBtnClickCommonFunc(val, btnFilterDataYtd, originalLengthYtd);
    setbtnFilterDataYtd(returnedData.btnFilterData);
    setbtnFilterAllYtd(returnedData.btnFilterAllData);
  };
  const handlecallbackBtnClickCommonFunc = (val: any, originalData: any[], originalLength: any) => {
    const data = originalData.findIndex((item) => item[0] === val);
    originalData[data][1][0].isSelected = !originalData[data][1][0].isSelected;
    const filterData = [...originalData];
    const filterAllSelected = filterData.filter((x) => x[1][0].isSelected === true);
    return { btnFilterData: [...originalData], btnFilterAllData: filterAllSelected.length === originalLength }
  };
  const handleCallBackAllBtnClick = (val: any) => {
    const filteredData = handleCallBackAllBtnClickCommonFunc(btnFilterData, btnFilterAll);
    setBtnFilterAll(!btnFilterAll);
    setBtnFilterData(filteredData.btnFilterData);
  };
  const handleCallBackAllYtdBtnClick = () => {
    const filteredData = handleCallBackAllBtnClickCommonFunc(btnFilterDataYtd, btnFilterAllYtd);
    setbtnFilterAllYtd(!btnFilterAllYtd);
    setbtnFilterDataYtd(filteredData.btnFilterData);
  };
  const handleCallBackAllBtnClickCommonFunc = (originalData: any[], btnFilterAll: boolean) => {
    for (let i = 0; i < originalData.length; i++) {
      originalData[i][1][0].isSelected = !btnFilterAll
    };
    return { btnFilterData: [...originalData] }
  };
  const handleCallBackApplyFilter = () => {
    const obj = {
      col1: ""
    }
    //dispatch(setFilterGridColumnAction(obj));
  };

  const [apiLink, setApiLink] = useState('');
  useEffect(() => {
    (async () => {
      try {
        const responseURL = await axios.get(`${Config.apiBaseAddress}${Config.apiGetAnomalyLink}`, {
          headers: { "Authorization": `Bearer ${await getToken()}` }
        });
  
        const resultURL = responseURL.data;  
        setApiLink(resultURL);
  
      } catch (error) {
        console.error("Error fetching Anomaly data:", error);
      }
    })();
  }, []);

  const handleswitch = () => {
    setApplyfilter(true);
  }



  return (
    <>
      <div className="grid-control-wrap">
        <div className="grid-views">
          <NavLink to="/mtd" onClick = {handleswitch}>MTD</NavLink>
          <NavLink to="/qtd-ytd" onClick = {handleswitch}>QTD/YTD</NavLink>
          {/* <NavLink to="/ytd">YTD</NavLink> */}
        </div>
        <div className="segmental-pl-title">
          {" "}
          Subsidiary P&L - (In {actualformatNbr})
        </div>
        <ul className="grid-controls">
        {showButton && (
  <li>
    {chartMode && (
    <button type="button" title="Columns" onClick={handleColumnsModalShow}>
      <FiColumns />
    </button>
    )}
  </li>
)}
 <li>
 <a href={apiLink} target="_blank" rel="noopener noreferrer">
        <button type="button" title="Anomaly">
          <SlGraph />
        </button>
      </a>
          </li>
{showButton && (
<li>
            <button type="button" title={chartMode || toggleView ? "Charts" : "Grid"} onClick={handleButtonToggle}>
              {chartMode || toggleView  ? <FiBarChart2 /> : <FiGrid />}
            </button>
          </li>
)}
          <li>
            <button type="button" title="Field" onClick={props.handleShow}>
              <FiFilter />
            </button>
          </li>
          {chartMode && (
          <li>
            <button
              // disabled={gridData?.gridDataValues?.length===0}
              type="button"
              title="Export"
              onClick={() => {
                props.handleModalShow();
                if (gridData?.gridDataValues?.length === 0) {
                  props.setModalShow(false);
                  props.setNoGridDataAlert(true);
                }
              }}
            >
              <TbFileExport />
            </button>
          </li>
    
 ) }
          <li>
            <button type="button" title="Settings" onClick={handleSettingsModalShow}>
              <FiSettings />
            </button>
          </li>
          {/* <li>
          <Dropdown>
              <Dropdown.Toggle title="Help">
                <FiHelpCircle />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="https://microsoft.sharepoint.com/:fl:/s/c277c028-a75e-42fa-948c-cb401af889f3/ERDU8z8cH0lKp4vrB-uIfgsBLhALnZGCubf3xa4W_lGJ5A?e=zX1YY0&nav=cz0lMkZzaXRlcyUyRmMyNzdjMDI4LWE3NWUtNDJmYS05NDhjLWNiNDAxYWY4ODlmMyZkPWIlMjE5UF9uckpPTHRrNkRBclB6SnJXeEdqRlVpUlJ5YnJaQWhHRHpzQVRrbGs4dlBHeXIzNlhDVExjcW14QXd0b0NBJmY9MDFLTFBSMlhBUTJUWlQ2SEE3SkZGS1BDN0xBN1ZZUTdRTCZjPSUyRiZhPUxvb3BBcHAmcD0lNDBmbHVpZHglMkZsb29wLXBhZ2UtY29udGFpbmVyJng9JTdCJTIydyUyMiUzQSUyMlQwUlRVSHh0YVdOeWIzTnZablF1YzJoaGNtVndiMmx1ZEM1amIyMThZaUU1VUY5dWNrcFBUSFJyTmtSQmNsQjZTbkpYZUVkcVJsVnBVbEo1WW5KYVFXaEhSSHB6UVZScmJHczRkbEJIZVhJek5saERWRXhqY1cxNFFYZDBiME5CZkRBeFMweFFVakpZUjFSUFEwSTBVbG8wU1ROYVNFcEhURFZLU2tFek5WbFJXbG8lM0QlMjIlMkMlMjJpJTIyJTNBJTIyZjUyMDc1NTctZmJkMC00YjU0LTk5ZTYtODg4ZjUwYTE0OGFlJTIyJTdE" target="_blank">User Guide</Dropdown.Item>
                <Dropdown.Item href="https://onefinance.microsoftcrmportals.com/finsuphome/" target="_blank">Contact Us</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li> */}
       <li>
          <Dropdown>
              <Dropdown.Toggle title="Help">
                <FiHelpCircle />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href={UserguideUrl} target="_blank">User Guide</Dropdown.Item>
                <Dropdown.Item href={TrainingUrl} target="_blank">Training</Dropdown.Item>
                <Dropdown.Item href={ContactusUrl} target="_blank">Contact Us</Dropdown.Item>

              </Dropdown.Menu>
            </Dropdown>
          </li>
          {/* {toggle ? (
        <li>
            <button onClick={handleButtonToggle}>
              <FiBarChart2 />
              Chart
            </button>
          ) : (
            <button onClick={handleButtonToggle}>
              <FiGrid />
              Grid
            </button>
        </li>
          )} */}
        </ul>
      </div>

      {/* Settings Modal */}
      <Settings show={modalSettingsShow} onHide={() => setSettingsModalShow(false)} loading={false} Commentchk={false} handlecommentchn={function (): void {
        throw new Error("Function not implemented.");
      } } />

      {/* Columns Modal */}
      
      <Columns
        celldata={btnFilterData}
        show={modalColumnsShow}
        onHide={() => setColumnsModalShow(false)}
        handlecallbackBtnClickr={handlecallbackBtnClickr}
        handleCallBackAllBtnClick={handleCallBackAllBtnClick}
        btnFilterAll={btnFilterAll}
        handleCallBackApplyFilter={handleCallBackApplyFilter}
        setSelectedColumns={setSelectedColumns}
        handleCallBackAllYtdBtnClick={handleCallBackAllYtdBtnClick}
        btnFilterAllYtd={btnFilterAllYtd}
        celldataYtd={btnFilterDataYtd}
        handlecallbackBtnClickYtd={handlecallbackBtnClickYtd}
      />
    </>

  );
}

export default DataGridControl;
