import store from "../../Redux/Store/Store";
export const getDownloadExcelData = (result: any) => {
  console.log(result);
  const disposition = result.payload.headers["content-disposition"];

  let filename = "";

  if (disposition && disposition.indexOf("attachment") !== -1) {
    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

    let matches = filenameRegex.exec(disposition);

    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
    }
  }

  let fileExt: any = filename.split(".").pop();

  if (fileExt.indexOf("xlsx") != -1) {
    let conentType = result.payload.headers["content-type"];

    let blob = new Blob([result.payload.data], {
      type: conentType,
    });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");

    link.id = "exportLink";

    document.body.appendChild(link);

    link.href = url;

    link.setAttribute("download", filename);

    //document.body.appendChild(link);

    link.click();

    const a: any = document.getElementById("exportLink");

    a.remove();
  }
};
export const getDownloadPPTData = (result: any) => {
  console.log(result);
  const disposition = result.payload.headers["content-disposition"];

  let filename = "";

  if (disposition && disposition.indexOf("attachment") !== -1) {
    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

    let matches = filenameRegex.exec(disposition);

    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
    }
  }

  let fileExt: any = filename.split(".").pop();

  if (fileExt.indexOf("pptx") != -1) {
    let conentType = result.payload.headers["content-type"];

    let blob = new Blob([result.payload.data], {
      type: conentType,
    });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");

    link.id = "exportLink";

    document.body.appendChild(link);

    link.href = url;

    link.setAttribute("download", filename);

    //document.body.appendChild(link);

    link.click();

    const a: any = document.getElementById("exportLink");

    a.remove();
  }
};

export const getSelectedFiltersFromPreferences = (
  selerectedPrefData: any,
  filterOption: any,
  prefFilterOption: any,
  id: any,
  name: any,
  fieldFlag:any=0
) => {
  const storeData = store.getState();
  // console.log("selerectedPrefData==>",selerectedPrefData)
  let preData = selerectedPrefData[0];
  preData = String(preData[prefFilterOption]).split(",");
  let filterOptions = fieldFlag?filterOption:storeData.preferences.preferenceData[filterOption]
  // if(prefFilterOption==="selectExecSummaryOrg")
  // {
  //   console.log("selerectedPrefData===>",selerectedPrefData[0])
  //   console.log("preData===>",preData)
  //   console.log("filterOptions===>",filterOptions)
  // }

  // console.log("filterOptions:-",filterOptions)
  //console.log("Pref==>",preData)
  const getSelectedFilterDDOptions =
    preData[0] === "All"
      ? filterOptions.map((e: any) => {
          return {
            value: filterOption == "wWSubRegion"? e[id].toString():e[id],
            label: e[name],
          };
        })
      : preData[0]==="" || preData[0]===undefined?[]:preData.map((item: any) => {
          const selections = filterOptions.filter((e: any) => item == e[id]);
          if(selections.length>0)
          return {
            value: filterOption == "wWSubRegion"? selections[0][id].toString():selections[0][id],
            label: selections[0][name],
          }
          else
          return {
            value:"NoValue",
            label:"NoLabel"
          }
        }).filter((e:any)=>e.value!=="NoValue"); // to handle unavailable option in list
  return getSelectedFilterDDOptions;
};


export const getSelectedData = (
  fieldName?: any,
  selectedItems?: any,
  isPrefSelected?: boolean
) => {
  const storeData = store.getState();
  return {
    selectedFiscalMonth: isPrefSelected
      ? selectedItems.value
      : storeData.singleSelect.selectedFiscalMonths[0].value,
    selectedCompanies: isPrefSelected
      ? selectedItems.map((e: any) => e.value).join(",")
      : storeData.customMultiSelect.selectedSAPCompanyCodes
          .map((e: any) => e.value)
          .join(","),
    // selectedCompanies: storeData.customMultiSelect.selectedSAPCompanyCodes.map((e: any) => e.value).join(storeData.customMultiSelect.selectedSAPCompanyCodes.length === allOptions.length ? ',ALL,' : ','),
    selectedCurrencyType:
      storeData.singleSelect.selectedCurrencyTypes.value &&
      storeData.singleSelect.selectedCurrencyTypes.value.length !== 0
        ? storeData.singleSelect.selectedCurrencyTypes.value
        : storeData.singleSelect.defaultCurrencyType.length !== 0
        ? storeData.singleSelect.defaultCurrencyType[0].value
        : 0,
    selectedDisplayIn:
      storeData.singleSelect.selectedDisplayOptions.value &&
      storeData.singleSelect.selectedDisplayOptions.value.length !== 0
        ? storeData.singleSelect.selectedDisplayOptions.value
        : storeData.singleSelect.defaultDisplayOption.length !== 0
        ? storeData.singleSelect.defaultDisplayOption[0].value
        : 0,
    selectedClass:
      fieldName === "classes"
        ? selectedItems.map((e: any) => e.value).join(",")
        : storeData.cascadingDropDown.selectedClassesValue
            .map((e: any) => e.value)
            .join(","),
    selectedSubClass:
      fieldName === "subclasses"
        ? selectedItems.map((e: any) => e.value).join(",")
        : storeData.cascadingDropDown.selectedSubClassesValue
            .map((e: any) => e.value)
            .join(","),
    selectedChannelGroup:
      fieldName === "groupclasses"
        ? selectedItems.map((e: any) => e.value).join(",")
        : storeData.cascadingDropDown.selectedGroupClassesValue
            .map((e: any) => e.value)
            .join(","),
    selectedChannelLineItem:
      fieldName === "lineitems"
        ? selectedItems.map((e: any) => e.value).join(",")
        : storeData.cascadingDropDown.selectedLineItemValue
            .map((e: any) => e.value)
            .join(","),
    selectedFinancialAccount:
      fieldName === "finaccount"
        ? selectedItems.map((e: any) => e.value).join(",")
        : storeData.cascadingDropDown.selectedFinAccountValue
            .map((e: any) => e.value)
            .join(","),
    selectedInternalOrder:
      fieldName === "intorder"
        ? selectedItems.map((e: any) => e.value).join(",")
        : storeData.cascadingDropDown.selectedOrderValue
            .map((e: any) => e.value)
            .join(","),
    selectedProfitCenter:
      fieldName === "profitcenter"
        ? selectedItems.map((e: any) => e.value).join(",")
        : storeData.cascadingDropDown.selectedProfitCenterValue
            .map((e: any) => e.value)
            .join(","),
    selectedCostCenter:
      fieldName === "costcenter"
        ? selectedItems.map((e: any) => e.value).join(",")
        : storeData.cascadingDropDown.selectedCostCenterValue
            .map((e: any) => e.value)
            .join(","),
    selectExecOrg:
      fieldName === "execorg"
        ? selectedItems.map((e: any) => e.value).join(",")
        : storeData.cascadingDropDown.selectedOrgValue
            .map((e: any) => e.value)
            .join(","),
    selectedOrgSummary:
      fieldName === "orgsummary"
        ? selectedItems.map((e: any) => e.value).join(",")
        : storeData.cascadingDropDown.selectedOrgSummaryValue
            .map((e: any) => e.value)
            .join(","),
    userAlias: "",
  };
};

export const getSelectedDataForCDDFromPref = (
  payload: any,
  fieldFlag: any=0
) => {
  
  const storeData = store.getState();
  // getSelectedFiltersFromPreferences(payload.selectedPreferenceData,"currencyType","selectedCurrencyType","currencyTypeHierarchyID","currencyTypeHierarchyName")
  //console.log("Payload:-------",payload)
  const payloadObj = {
    selectedFiscalMonth: payload.selectedFiscalMonth.value,
    selectedCompanies:
     // payload.selectedCompanies.length ===
     // storeData.customMultiSelect.sapCompanyCodes.length
      //  ? "All"
      //  :
       payload.selectedCompanies.map((e: any) => e.value).join(","),
    selectedCurrencyType: payload.selectedCurrencyType.value,
    selectedDisplayIn: payload.selectedDisplayIn.value,

    selectedClass:
     // payload.selectedPreferenceData.selectedClass ===
     //"All"
      //  ? "All"
      //  : 
      payload.selectedClass.map((e: any) => e.value).join(","),
    selectedSubClass:
      fieldFlag > 1
        ? 
        //payload.selectedPreferenceData.selectedSubClass === "All"
        //  ? "All"
        //  : 
        //payload.selectedSubClass.map((e: any) => e.value).join(",")
        payload.selectedSubClass
        : "",
    selectedChannelGroup:
      fieldFlag > 2
        ? 
        //payload.selectedPreferenceData.selectedChannelGroup === "All"
         // ? "All"
        //  :
        // payload.selectedChannelGroup.map((e: any) => e.value).join(",")
        payload.selectedChannelGroup
        : "",
    selectedChannelLineItem:
      fieldFlag > 3
        ?
        //payload.selectedPreferenceData.selectedChannelLineItem === "All"
          //? "All"
         // : 
       //  payload.selectedChannelLineItem.map((e: any) => e.value).join(",")
       payload.selectedChannelLineItem

        : "",
    selectedFinancialAccount:
      fieldFlag > 4
        ? 
        //payload.selectedPreferenceData.selectedFinancialAccount === "All"
        //  ? "All"
        //  :
       //  payload.selectedFinancialAccount.map((e: any) => e.value).join(",")
       payload.selectedFinancialAccount

        : "",
    selectedInternalOrder:
      fieldFlag > 5
        ? 
        //payload.selectedPreferenceData.selectedInternalOrder === "All"
          //? "All"
          //: 
         // payload.selectedInternalOrder.map((e: any) => e.value).join(",")
         payload.selectedInternalOrder

        : "",
    selectedProfitCenter:
      fieldFlag > 6
        ? 
        //payload.selectedPreferenceData.selectedProfitCenter === "All"
         // ? "All"
        //  :
        // payload.selectedProfitCenter.map((e: any) => e.value).join(",")
        payload.selectedProfitCenter
        : "",
    selectedCostCenter:
      fieldFlag > 7
        ? 
        //payload.selectedPreferenceData.selectedCostCenter === "All"
         // ? "All"
         // :
         // payload.selectedCostCenter.map((e: any) => e.value).join(",")
         payload.selectedCostCenter

        : "",
    selectExecOrg:
      fieldFlag > 8
        ? 
        //payload.selectedPreferenceData.selectExecOrg === "All"
        //  ? "All"
          //:
          // payload.selectExecOrg.map((e: any) => e.value).join(",")
          payload.selectExecOrg

        : "",
    selectedOrgSummary:
      fieldFlag > 9
        ? 
        //payload.selectedPreferenceData.selectedOrgSummary === "All"
         // ? "All"
        //  :
         //payload.selectedOrgSummary.map((e: any) => e.value).join(",")
         payload.selectedOrgSummary

        : "",
  };

  return payloadObj;
};
