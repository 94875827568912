import { useSelector } from "react-redux";
import Config from "../../Config";
import {
  SET_CLASS_FILTER_OPTIONS,
  INIT_CLASS_FILTER_OPTIONS,
  INIT_SUBCLASS_FILTER_OPTIONS,
  ERROR_CLASS_FILTER_OPTIONS,
  SET_SELECTED_CLASS_FILTER_OPTIONS,
  SET_SUBCLASS_FILTER_OPTIONS,
  SET_SELECTED_SUBCLASS_FILTER_OPTIONS,
  ERROR_SUBCLASS_FILTER_OPTIONS,
  SET_GROUPCLASS_FILTER_OPTIONS,
  ERROR_GROUPCLASS_FILTER_OPTIONS,
  SET_SELECTED_GROUPCLASS_FILTER_OPTIONS,
  INIT_GROUPCLASS_FILTER_OPTIONS,
  SET_LINEITEM_FILTER_OPTIONS,
  ERROR_LINEITEM_FILTER_OPTIONS,
  SET_SELECTED_LINEITEM_FILTER_OPTIONS,
  INIT_LINEITEM_FILTER_OPTIONS,
  SET_FINACCOUNT_FILTER_OPTIONS,
  ERROR_FINACCOUNT_FILTER_OPTIONS,
  SET_SELECTED_FINACCOUNT_FILTER_OPTIONS,
  INIT_FINACCOUNT_FILTER_OPTIONS,
  SET_SELECTED_ORDER_FILTER_OPTIONS,
  SET_ORDER_FILTER_OPTIONS,
  ERROR_ORDER_FILTER_OPTIONS,
  INIT_ORDER_FILTER_OPTIONS,
  SET_PROFITCENTER_FILTER_OPTIONS,
  ERROR_PROFITCENTER_FILTER_OPTIONS,
  SET_SELECTED_PROFITCENTER_FILTER_OPTIONS,
  INIT_PROFITCENTER_FILTER_OPTIONS,
  SET_COSTCENTER_FILTER_OPTIONS,
  ERROR_COSTCENTER_FILTER_OPTIONS,
  SET_SELECTED_COSTCENTER_FILTER_OPTIONS,
  INIT_COSTCENTER_FILTER_OPTIONS,
  SET_ORG_FILTER_OPTIONS,
  ERROR_ORG_FILTER_OPTIONS,
  SET_SELECTED_ORG_FILTER_OPTIONS,
  INIT_ORG_FILTER_OPTIONS,
  SET_ORGSUMMARY_FILTER_OPTIONS,
  ERROR_ORGSUMMARY_FILTER_OPTIONS,
  SET_SELECTED_ORGSUMMARY_FILTER_OPTIONS,
  INIT_ORGSUMMARY_FILTER_OPTIONS,
  INIT_CASCADING_DROPDOWN,
  SET_CASCADINGDROPDOWN_DATA_FROM_PREFERENCE,
} from "../ActionContants/constants";
import axios from "axios";
import { getToken, getUsername } from "../../Components/Services/MsalService";
import cascadingDropdownServices from "../../Components/Services/cascadingDropdownServices";
import {
  getSelectedDataForCDDFromPref,
  getSelectedFiltersFromPreferences,
} from "../../Components/Common/SupportingFunctions";

export const cascadingDropdownDataFromPrefAction = async (payload: any) => {
  
  let localPayload = payload;
  localPayload = { ...localPayload, userAlias: await getUsername() };
  // // console.log("localPayload==>", localPayload);
  let data = {
    classes: localPayload.classes || [],
    selectedClassesValue: localPayload.selectedClass || [],
    subclasses: localPayload.subclasses || [],
    selectedSubClassesValue: localPayload.selectedSubClassesValue || [],
    groupclasses: localPayload.groupclasses || [],
    selectedGroupClassesValue: localPayload.selectedGroupClassesValue || [],
    lineitem: localPayload.lineitem || [],
    selectedLineItemValue: localPayload.selectedLineItemValue || [],
    finaccount: localPayload.finaccount || [],
    selectedFinAccountValue: localPayload.selectedFinAccountValue || [],
    order: localPayload.order || [],
    selectedOrderValue: localPayload.selectedOrderValue || [],
    profitcenter: localPayload.profitcenter || [],
    selectedProfitCenterValue: localPayload.selectedProfitCenterValue || [],
    costcenter: localPayload.costcenter || [],
    selectedCostCenterValue: localPayload.selectedCostCenterValue || [],
    org: localPayload.org || [],
    selectedOrgValue: localPayload.selectedOrgValue || [],
    orgsummary: localPayload.orgsummary || [],
    selectedOrgSummaryValue: localPayload.selectedOrgSummaryValue || [],
  };

  const selectedClasses = getSelectedFiltersFromPreferences(
    localPayload.selectedPreferenceData,
    "channelClass",
    "selectedClass",
    "channelClassId",
    "channelClassDesc"
  );
  console.log("selectedClasses==>", selectedClasses);

  localPayload = {
    ...localPayload,
    selectedClass: selectedClasses,
  };
  // data.classes = localPayload.selectedPreferenceData.channelClass.map((item:any)=>({
  //   value: item.channelClassId.toString(),
  //   label: item.channelClassDesc
  // }))
  // data.classes = getSelectedFiltersFromPreferences(
  //   localPayload.selectedPreferenceData,
  //   "channelClass",
  //   "selectedClass",
  //   "channelClassId",
  //   "channelClassDesc"
  // );
  data.selectedClassesValue = selectedClasses;
  // // console.log("data==>", data);
  //// console.log("getSelectedDataForCDDFromPref(payload):",getSelectedDataForCDDFromPref(payload))

  // ==== SubClasses ===
  const subclasses = await cascadingDropdownServices.GetSubClassesData(
    getSelectedDataForCDDFromPref(localPayload)
  );
  if (subclasses) {
    data.subclasses = subclasses;
    localPayload = {
      ...localPayload,
      //selectedSubClass: subclasses,
      selectedSubClass: localPayload.selectedPreferenceData[0].selectedSubClass,
    };
    data.selectedSubClassesValue = getSelectedFiltersFromPreferences(
      localPayload.selectedPreferenceData,
      subclasses,
      "selectedSubClass",
      "value",
      "label",
      1
    );

    let groupclasses = await cascadingDropdownServices.GetGroupClassesData(
      getSelectedDataForCDDFromPref(localPayload, 2)
    );
    if (groupclasses ) {
      data.groupclasses = groupclasses;
      localPayload = {
        ...localPayload,
        //selectedChannelGroup: groupclasses,
        selectedChannelGroup: localPayload.selectedPreferenceData[0].selectedChannelGroup,
      };
      data.selectedGroupClassesValue = getSelectedFiltersFromPreferences(
        localPayload.selectedPreferenceData,
        groupclasses,
        "selectedChannelGroup",
        "value",
        "label",
        1
      );
      const lineitem = await cascadingDropdownServices.GetLineItemsData(
        getSelectedDataForCDDFromPref(localPayload, 3)
      );

      if (lineitem) {
        data.lineitem = lineitem;
        localPayload = {
          ...localPayload,
          //selectedChannelLineItem: lineitem,
          selectedChannelLineItem: localPayload.selectedPreferenceData[0].selectedChannelLineItem,
        };
        data.selectedLineItemValue = getSelectedFiltersFromPreferences(
          localPayload.selectedPreferenceData,
          lineitem,
          "selectedChannelLineItem",
          "value",
          "label",
          1
        );
        const finaccount = await cascadingDropdownServices.GetFinAccountsData(
          getSelectedDataForCDDFromPref(localPayload, 4)
        );
        if (finaccount) {
          data.finaccount = finaccount;
          localPayload = {
            ...localPayload,
            //selectedFinancialAccount: finaccount,
            selectedFinancialAccount: localPayload.selectedPreferenceData[0].selectedFinancialAccount,
          };
          data.selectedFinAccountValue = getSelectedFiltersFromPreferences(
            localPayload.selectedPreferenceData,
            finaccount,
            "selectedFinancialAccount",
            "value",
            "label",
            1
          );
          //data.selectedSubClassesValue = getSelectedFiltersFromPreferences(localPayload.selectedPreferenceData.);
          const order = await cascadingDropdownServices.GetOrderData(
            getSelectedDataForCDDFromPref(localPayload, 5)
          );
          if (order) {
            data.order = order;
            localPayload = {
              ...localPayload,
              //selectedInternalOrder: order,
              selectedInternalOrder: localPayload.selectedPreferenceData[0].selectedInternalOrder,

            };
            data.selectedOrderValue = getSelectedFiltersFromPreferences(
              localPayload.selectedPreferenceData,
              order,
              "selectedInternalOrder",
              "value",
              "label",
              1
            );
            //data.selectedSubClassesValue = getSelectedFiltersFromPreferences(localPayload.selectedPreferenceData.);
            const profitcenter =
              await cascadingDropdownServices.GetProfitCenterData(
                getSelectedDataForCDDFromPref(localPayload, 6)
              );
            if (profitcenter) {
              data.profitcenter = profitcenter;
              localPayload = {
                ...localPayload,
               // selectedProfitCenter: profitcenter,
               selectedProfitCenter: localPayload.selectedPreferenceData[0].selectedProfitCenter,

              };
              data.selectedProfitCenterValue =
                getSelectedFiltersFromPreferences(
                  localPayload.selectedPreferenceData,
                  profitcenter,
                  "selectedProfitCenter",
                  "value",
                  "label",
                  1
                );
              //data.selectedSubClassesValue = getSelectedFiltersFromPreferences(localPayload.selectedPreferenceData.);
              const costcenter =
                await cascadingDropdownServices.GetCostCenterData(
                  getSelectedDataForCDDFromPref(localPayload, 7)
                );
              if (costcenter) {
                data.costcenter = costcenter;
                localPayload = {
                  ...localPayload,
                 // selectedCostCenter: costcenter,
                 selectedCostCenter: localPayload.selectedPreferenceData[0].selectedCostCenter,

                };
                data.selectedCostCenterValue =
                  getSelectedFiltersFromPreferences(
                    localPayload.selectedPreferenceData,
                    costcenter,
                    "selectedCostCenter",
                    "value",
                    "label",
                    1
                  );
                //data.selectedSubClassesValue = getSelectedFiltersFromPreferences(localPayload.selectedPreferenceData.);
                const org = await cascadingDropdownServices.GetOrgData(
                  getSelectedDataForCDDFromPref(localPayload, 8)
                );
                if (org) {
                  data.org = org;
                  localPayload = {
                    ...localPayload,
                   // selectExecOrg: org,
                   selectExecOrg: localPayload.selectedPreferenceData[0].selectExecOrg,
                  };
                  data.selectedOrgValue = getSelectedFiltersFromPreferences(
                    localPayload.selectedPreferenceData,
                    org,
                    "selectExecOrg",
                    "value",
                    "label",
                    1
                  );
                  //data.selectedSubClassesValue = getSelectedFiltersFromPreferences(localPayload.selectedPreferenceData.);
                  const orgsummary =
                    await cascadingDropdownServices.GetOrgSummaryData(
                      getSelectedDataForCDDFromPref(localPayload, 9)
                    );
                  if (orgsummary) {
                    data.orgsummary = orgsummary;
                    localPayload = {
                      ...localPayload,
                     // selectedOrgSummary: orgsummary,
                     selectedOrgSummary: localPayload.selectedPreferenceData[0].selectedOrgSummary,
                    };
                    data.selectedOrgSummaryValue =
                      getSelectedFiltersFromPreferences(
                        localPayload.selectedPreferenceData,
                        orgsummary,
                        "selectExecSummaryOrg",
                        "value",
                        "label",
                        1
                      );
                      // // console.log("data==>",data)
                      // // console.log("localPayload==>",localPayload)
                    //data.selectedSubClassesValue = getSelectedFiltersFromPreferences(localPayload.selectedPreferenceData.);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // // console.log("data==>", data);
  // // console.log("localpayload==>", localPayload);
  return {
    type: SET_CASCADINGDROPDOWN_DATA_FROM_PREFERENCE,
    payload: data,
  };
};

export const setClassesAction = async (payload: any) => {
  try {
    const response = await axios.post(
      `${Config.apiBaseAddress}${Config.apiGetClass}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getToken()}`,
        },
      }
    );
    if (response.data) {
      const classOption = response.data.map(
        (item: {
          channelClassId: { toString: () => any };
          channelClassDesc: any;
        }) => ({
          value: item.channelClassId.toString(),
          label: item.channelClassDesc,
        })
      );

      return {
        type: SET_CLASS_FILTER_OPTIONS,
        payload: classOption,
      };
    }
    return {
      type: ERROR_CLASS_FILTER_OPTIONS,
    };
  } catch (error) {
    console.error("Failed to fetch classes:", error);
    return {
      type: ERROR_CLASS_FILTER_OPTIONS,
    };
  }
};

export const setClassesFromPreferences = (payload: any) => {
  return {
    type: SET_CLASS_FILTER_OPTIONS,
    payload: payload,
  };
};

export const initSelectedClassesAction = () => ({
  type: INIT_CLASS_FILTER_OPTIONS,
});

export const initCascadingDropDowns = () => ({
  type: INIT_CASCADING_DROPDOWN,
});

export const setSelectedClassesAction = (payload: any) => ({
  type: SET_SELECTED_CLASS_FILTER_OPTIONS,
  payload: payload,
});

//SUBCLASS ACTIONCREATOR
export const setSubClassesAction = async (payload: any) => {
  try {
    const response = await cascadingDropdownServices.GetSubClassesData(payload);
    return {
      type: SET_SUBCLASS_FILTER_OPTIONS,
      payload: response,
    };
  } catch (error) {
    console.error("Failed to fetch subclasses:", error);
    return {
      type: ERROR_SUBCLASS_FILTER_OPTIONS,
    };
  }
};

export const setSelectedSubClassesAction = (payload: any) => ({
  type: SET_SELECTED_SUBCLASS_FILTER_OPTIONS,
  payload: payload,
});

export const initiateSubClassesAction = () => ({
  type: INIT_SUBCLASS_FILTER_OPTIONS,
});

//GROUPCLASS
export const setGroupClassesAction = async (payload: any) => {
  try {
    const response = await cascadingDropdownServices.GetGroupClassesData(
      payload
    );

    return {
      type: SET_GROUPCLASS_FILTER_OPTIONS,
      payload: response,
    };
  } catch (error) {
    console.error("Failed to fetch groupclasses:", error);
    return {
      type: ERROR_GROUPCLASS_FILTER_OPTIONS,
    };
  }
};

export const setSelectedGroupClassesAction = (payload: any) => ({
  type: SET_SELECTED_GROUPCLASS_FILTER_OPTIONS,
  payload: payload,
});

export const initiateGroupClassesAction = () => ({
  type: INIT_GROUPCLASS_FILTER_OPTIONS,
});

//LINEITEM
export const setLineItemAction = async (payload: any) => {
  try {
    const response = await cascadingDropdownServices.GetLineItemsData(payload);
    return {
      type: SET_LINEITEM_FILTER_OPTIONS,
      payload: response,
    };
  } catch (error) {
    console.error("Failed to fetch lineitem:", error);
    return {
      type: ERROR_LINEITEM_FILTER_OPTIONS,
    };
  }
};
export const setSelectedLineItemAction = (payload: any) => ({
  type: SET_SELECTED_LINEITEM_FILTER_OPTIONS,
  payload: payload,
});

export const initiateLineItemAction = () => ({
  type: INIT_LINEITEM_FILTER_OPTIONS,
});

//FINACCOUNT
export const setFinAccountAction = async (payload: any) => {
  try {
    const response = await cascadingDropdownServices.GetFinAccountsData(
      payload
    );
    return {
      type: SET_FINACCOUNT_FILTER_OPTIONS,
      payload: response,
    };
  } catch (error) {
    console.error("Failed to fetch finaccount:", error);
    return {
      type: ERROR_FINACCOUNT_FILTER_OPTIONS,
    };
  }
};
export const setSelectedFinAccountAction = (payload: any) => ({
  type: SET_SELECTED_FINACCOUNT_FILTER_OPTIONS,
  payload: payload,
});
export const initiateFinAccountAction = () => ({
  type: INIT_FINACCOUNT_FILTER_OPTIONS,
});

//ORDER
export const setOrderAction = async (payload: any) => {
  try {
    const response = await cascadingDropdownServices.GetOrderData(payload);

    return {
      type: SET_ORDER_FILTER_OPTIONS,
      payload: response,
    };
  } catch (error) {
    console.error("Failed to fetch Order:", error);
    return {
      type: ERROR_ORDER_FILTER_OPTIONS,
    };
  }
};
export const setSelectedOrderAction = (payload: any) => ({
  type: SET_SELECTED_ORDER_FILTER_OPTIONS,
  payload: payload,
});
export const initiateOrderAction = () => ({
  type: INIT_ORDER_FILTER_OPTIONS,
});

//PROFIT CENTER
export const setProfitCenterAction = async (payload: any) => {
  try {
    // console.log("payload==>", payload);
    const response = await cascadingDropdownServices.GetProfitCenterData(
      payload
    );
    return {
      type: SET_PROFITCENTER_FILTER_OPTIONS,
      payload: response,
    };
  } catch (error) {
    console.error("Failed to fetch Order:", error);
    return {
      type: ERROR_PROFITCENTER_FILTER_OPTIONS,
    };
  }
};
export const setSelectedProfitCenterAction = (payload: any) => ({
  type: SET_SELECTED_PROFITCENTER_FILTER_OPTIONS,
  payload: payload,
});
export const initiateProfitCenterAction = () => ({
  type: INIT_PROFITCENTER_FILTER_OPTIONS,
});

//COSTCENTER
export const setCostCenterAction = async (payload: any) => {
  try {
    const response = await cascadingDropdownServices.GetCostCenterData(payload);
    return {
      type: SET_COSTCENTER_FILTER_OPTIONS,
      payload: response,
    };
  } catch (error) {
    console.error("Failed to fetch cost center:", error);
    return {
      type: ERROR_COSTCENTER_FILTER_OPTIONS,
    };
  }
};
export const setSelectedCostCenterAction = (payload: any) => ({
  type: SET_SELECTED_COSTCENTER_FILTER_OPTIONS,
  payload: payload,
});
export const initiateCostCenterAction = () => ({
  type: INIT_COSTCENTER_FILTER_OPTIONS,
});

//ORG
export const setOrgAction = async (payload: any) => {
  try {
    const response = await cascadingDropdownServices.GetOrgData(payload);
    return {
      type: SET_ORG_FILTER_OPTIONS,
      payload: response,
    };
  } catch (error) {
    console.error("Failed to fetch org:", error);
    return {
      type: ERROR_ORG_FILTER_OPTIONS,
    };
  }
};
export const setSelectedOrgAction = (payload: any) => ({
  type: SET_SELECTED_ORG_FILTER_OPTIONS,
  payload: payload,
});
export const initiateOrgAction = () => ({
  type: INIT_ORG_FILTER_OPTIONS,
});

//ORGSUMMARY
export const setOrgSummaryAction = async (payload: any) => {
  try {
    const response = await cascadingDropdownServices.GetOrgSummaryData(payload);
    return {
      type: SET_ORGSUMMARY_FILTER_OPTIONS,
      payload: response,
    };
  } catch (error) {
    console.error("Failed to fetch org:", error);
    return {
      type: ERROR_ORGSUMMARY_FILTER_OPTIONS,
    };
  }
};
export const setSelectedOrgSummaryAction = (payload: any) => ({
  type: SET_SELECTED_ORGSUMMARY_FILTER_OPTIONS,
  payload: payload,
});
export const initiateOrgSummaryAction = () => ({
  type: INIT_ORGSUMMARY_FILTER_OPTIONS,
});
