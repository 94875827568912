import { Option } from './ActionType'; 
import { SET_SELECTED_FISCAL_MONTHS, SET_FISCAL_MONTHS, SET_DEFAULT_FISCAL_MONTHS, SET_SELECTED_CURRENCY_TYPES, SET_DEFAULT_CURRENCY_TYPES, SET_SELECTED_PAID_HEAD_COUNTS, SET_DEFAULT_PAID_HEAD_COUNTS, SET_DISPLAY_OPTIONS,SET_SELECTED_DISPLAY_OPTIONS, SET_DEFAULT_DISPLAY_OPTIONS, SET_CURRENCY_TYPES } from '../ActionContants/constants';

export const setSelectedFiscalMonths = (selectedOptions: Option[]) => ({
  type: SET_SELECTED_FISCAL_MONTHS,
  payload: selectedOptions,
});

export const setFiscalMonths = (fiscalMonths: Option[]) => ({
  type: SET_FISCAL_MONTHS,
  payload: fiscalMonths,
});

export const setDefaultFiscalMonths = (selectedOptions: Option[]) => ({
  type: SET_DEFAULT_FISCAL_MONTHS,
  payload: selectedOptions,
});

export const setCurrencyTypes = (currencyTypes: Option[]) => ({
  type: SET_CURRENCY_TYPES,
  payload: currencyTypes,
});

export const setSelectedCurrencyTypes = (selectedOptions: Option[]) => ({
  type: SET_SELECTED_CURRENCY_TYPES,
  payload: selectedOptions,
});

export const setDefaultCurrencyTypes = (selectedOptions: Option[]) => ({
  type: SET_DEFAULT_CURRENCY_TYPES,
  payload: selectedOptions,
});

export const setSelectedPaidHeadCounts = (selectedOptions: Option[]) => ({
    type: SET_SELECTED_PAID_HEAD_COUNTS,
    payload: selectedOptions,
  });

export const setDefaultPaidHeadCounts = (selectedOptions: Option[]) => ({
  type: SET_DEFAULT_PAID_HEAD_COUNTS,
  payload: selectedOptions,
});

export const setDisplayOptions = (displayOptions: Option[]) => ({
  type: SET_DISPLAY_OPTIONS,
  payload: displayOptions,
});
export const setSelectedDisplayOptions = (selectedOptions: Option[]) => ({
  type: SET_SELECTED_DISPLAY_OPTIONS,
  payload: selectedOptions,
});

export const setDefaultDisplayOptions = (selectedOptions: Option[]) => ({
  type: SET_DEFAULT_DISPLAY_OPTIONS,
  payload: selectedOptions,
});