import { SET_CHECKBOX_VALUE, SET_SHOWFIELDSFLAG } from "../ActionContants/constants";

export const setCheckBoxValue = (value: boolean) => {
  return {
    type: SET_CHECKBOX_VALUE,
    payload: value,
  };
};

export const setShowFieldsFlagAction = (value: boolean) => {
  return {
    type: SET_SHOWFIELDSFLAG,
    payload: value,
  };
};
