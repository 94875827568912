import React from 'react'
import Config from '../Config';
 
const mercuryAccessUrl = Config.MercuryAccessUrl;
function AccessError() {
  return (
    <div className="access-details">
      <div className='title'>Oops!</div>
      <div className='request-access'>Looks like you do not have permission to access Mercury data. Please request access <a className='link' href={mercuryAccessUrl} target="_blank">here.</a></div>
      {/* <div>If you need any further assistance please visit the <a className='link' href="https://microsoft.sharepoint.com/teams/FinAccessMercuryUAT/Pages/Mercury-Access-Request.aspx" target="_blank">FinSup Portal.</a></div> */}
    </div>
  )
}
 
export default AccessError