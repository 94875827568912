import { useSelector } from "react-redux";
import { Option } from "../Actions/ActionType";

import {
  SET_CLASS_FILTER_OPTIONS,
  ERROR_CLASS_FILTER_OPTIONS,
  SET_SELECTED_CLASS_FILTER_OPTIONS,
  SET_SUBCLASS_FILTER_OPTIONS,
  SET_SELECTED_SUBCLASS_FILTER_OPTIONS,
  ERROR_SUBCLASS_FILTER_OPTIONS,
  SET_GROUPCLASS_FILTER_OPTIONS,
  SET_SELECTED_GROUPCLASS_FILTER_OPTIONS,
  ERROR_GROUPCLASS_FILTER_OPTIONS,
  INIT_SUBCLASS_FILTER_OPTIONS,
  INIT_CLASS_FILTER_OPTIONS,
  INIT_LINEITEM_FILTER_OPTIONS,
  SET_LINEITEM_FILTER_OPTIONS,
  SET_SELECTED_LINEITEM_FILTER_OPTIONS,
  ERROR_LINEITEM_FILTER_OPTIONS,
  INIT_FINACCOUNT_FILTER_OPTIONS,
  SET_FINACCOUNT_FILTER_OPTIONS,
  SET_SELECTED_FINACCOUNT_FILTER_OPTIONS,
  ERROR_FINACCOUNT_FILTER_OPTIONS,
  INIT_ORDER_FILTER_OPTIONS,
  SET_ORDER_FILTER_OPTIONS,
  SET_SELECTED_ORDER_FILTER_OPTIONS,
  ERROR_ORDER_FILTER_OPTIONS,
  INIT_PROFITCENTER_FILTER_OPTIONS,
  SET_PROFITCENTER_FILTER_OPTIONS,
  SET_SELECTED_PROFITCENTER_FILTER_OPTIONS,
  ERROR_PROFITCENTER_FILTER_OPTIONS,
  INIT_COSTCENTER_FILTER_OPTIONS,
  SET_COSTCENTER_FILTER_OPTIONS,
  SET_SELECTED_COSTCENTER_FILTER_OPTIONS,
  ERROR_COSTCENTER_FILTER_OPTIONS,
  INIT_ORG_FILTER_OPTIONS,
  SET_ORG_FILTER_OPTIONS,
  SET_SELECTED_ORG_FILTER_OPTIONS,
  ERROR_ORG_FILTER_OPTIONS,
  INIT_GROUPCLASS_FILTER_OPTIONS,
  INIT_ORGSUMMARY_FILTER_OPTIONS,
  SET_ORGSUMMARY_FILTER_OPTIONS,
  SET_SELECTED_ORGSUMMARY_FILTER_OPTIONS,
  ERROR_ORGSUMMARY_FILTER_OPTIONS,
  INIT_CASCADING_DROPDOWN,
  SET_CASCADINGDROPDOWN_DATA_FROM_PREFERENCE,
} from "../ActionContants/constants";
import { RootReducer } from "./types";

interface cascadingDropDownState {
  classes: Option[];
  selectedClassesValue: Option[];
  subclasses: Option[];
  selectedSubClassesValue: Option[];
  groupclasses: Option[];
  selectedGroupClassesValue: Option[];
  lineitem: Option[];
  selectedLineItemValue: Option[];
  finaccount: Option[];
  selectedFinAccountValue: Option[];
  order: Option[];
  selectedOrderValue: Option[];
  profitcenter: Option[];
  selectedProfitCenterValue: Option[];
  costcenter: Option[];
  selectedCostCenterValue: Option[];
  org: Option[];
  selectedOrgValue: Option[];
  orgsummary: Option[];
  selectedOrgSummaryValue: Option[];

  classerrors: any;
  subclasserrors: any;
  groupclasserrors: any;
  lineitemerrors: any;
  finaccounterrors: any;
  ordererrors: any;
  profitcentererrors: any;
  costcentererrors: any;
  orgerrors: any;
  orgsummaryerrors: any;
}

const initialState: cascadingDropDownState = {
  classes: [],
  selectedClassesValue: [],
  subclasses: [],
  selectedSubClassesValue: [],
  groupclasses: [],
  selectedGroupClassesValue: [],
  lineitem: [],
  selectedLineItemValue: [],
  finaccount: [],
  selectedFinAccountValue: [],
  order: [],
  selectedOrderValue: [],
  profitcenter: [],
  selectedProfitCenterValue: [],
  costcenter: [],
  selectedCostCenterValue: [],
  org: [],
  selectedOrgValue: [],
  orgsummary: [],
  selectedOrgSummaryValue: [],

  classerrors: { classError: "" },
  subclasserrors: { subclassError: "" },
  groupclasserrors: { groupclassError: "" },
  lineitemerrors: { lineitemError: "" },
  finaccounterrors: { finaccountError: "" },
  ordererrors: { orderErrors: "" },
  profitcentererrors: { profitcenterError: "" },
  costcentererrors: { costcenterErrors: "" },
  orgerrors: { orgErrors: "" },
  orgsummaryerrors: { orgsummaryErrors: "" },
};

const cascadingDropDownReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case INIT_CASCADING_DROPDOWN:
      return initialState;
    //class
    case INIT_CLASS_FILTER_OPTIONS:
      return {
        ...state,
        classes: [],
        selectedClassesValue: [],
      };
    case SET_CLASS_FILTER_OPTIONS:
      //console.log("action.payload:",action.payload)
      return {
        ...state,
        classes: action.payload,
        //selectedClassesValue: []
      };
    case SET_SELECTED_CLASS_FILTER_OPTIONS:
      //console.log("action.payload selected classes:",action.payload)
      return {
        ...state,
        selectedClassesValue: action.payload,
      };
    //subclass
    case INIT_SUBCLASS_FILTER_OPTIONS:
      return {
        ...state,
        subclasses: [],
        selectedSubClassesValue: [],
      };
    case SET_SUBCLASS_FILTER_OPTIONS:
      return {
        ...state,
        subclasses: action.payload,
        selectedSubClassesValue: [],
      };
    case SET_SELECTED_SUBCLASS_FILTER_OPTIONS:
      return {
        ...state,
        selectedSubClassesValue: action.payload,
      };
    //groupclass
    case INIT_GROUPCLASS_FILTER_OPTIONS:
      return {
        ...state,
        groupclasses: [],
        selectedGroupClassesValue: [],
      };
    case SET_GROUPCLASS_FILTER_OPTIONS:
      return {
        ...state,
        groupclasses: action.payload,
        selectedGroupClassesValue: [],
      };
    case SET_SELECTED_GROUPCLASS_FILTER_OPTIONS:
      return {
        ...state,
        selectedGroupClassesValue: action.payload,
      };

    //lineitem
    case INIT_LINEITEM_FILTER_OPTIONS:
      return {
        ...state,
        lineitem: [],
        selectedLineItemValue: [],
      };
    case SET_LINEITEM_FILTER_OPTIONS:
      return {
        ...state,
        lineitem: action.payload,
        selectedLineItemValue: [],
      };
    case SET_SELECTED_LINEITEM_FILTER_OPTIONS:
      return {
        ...state,
        selectedLineItemValue: action.payload,
      };

    //FinAccount
    case INIT_FINACCOUNT_FILTER_OPTIONS:
      return {
        ...state,
        finaccount: [],
        selectedFinAccountValue: [],
      };
    case SET_FINACCOUNT_FILTER_OPTIONS:
      return {
        ...state,
        finaccount: action.payload,
        selectedFinAccountValue: [],
      };
    case SET_SELECTED_FINACCOUNT_FILTER_OPTIONS:
      return {
        ...state,
        selectedFinAccountValue: action.payload,
      };

    //Order
    case INIT_ORDER_FILTER_OPTIONS:
      return {
        ...state,
        order: [],
        selectedOrderValue: [],
      };
    case SET_ORDER_FILTER_OPTIONS:
      return {
        ...state,
        order: action.payload,
        selectedOrderValue: [],
      };
    case SET_SELECTED_ORDER_FILTER_OPTIONS:
      return {
        ...state,
        selectedOrderValue: action.payload,
      };

    //Profit Center
    case INIT_PROFITCENTER_FILTER_OPTIONS:
      return {
        ...state,
        profitcenter: [],
        selectedProfitCenterValue: [],
      };
    case SET_PROFITCENTER_FILTER_OPTIONS:
      return {
        ...state,
        profitcenter: action.payload,
        selectedProfitCenterValue: [],
      };
    case SET_SELECTED_PROFITCENTER_FILTER_OPTIONS:
      return {
        ...state,
        selectedProfitCenterValue: action.payload,
      };

    //Cost Center
    case INIT_COSTCENTER_FILTER_OPTIONS:
      return {
        ...state,
        costcenter: [],
        selectedCostCenterValue: [],
      };
    case SET_COSTCENTER_FILTER_OPTIONS:
      return {
        ...state,
        costcenter: action.payload,
        selectedCostCenterValue: [],
      };
    case SET_SELECTED_COSTCENTER_FILTER_OPTIONS:
      return {
        ...state,
        selectedCostCenterValue: action.payload,
      };

    //org
    case INIT_ORG_FILTER_OPTIONS:
      return {
        ...state,
        org: [],
        selectedOrgValue: [],
      };
    case SET_ORG_FILTER_OPTIONS:
      return {
        ...state,
        org: action.payload,
        selectedOrgValue: [],
      };
    case SET_SELECTED_ORG_FILTER_OPTIONS:
      return {
        ...state,
        selectedOrgValue: action.payload,
      };

    //OrgSummary
    case INIT_ORGSUMMARY_FILTER_OPTIONS:
      return {
        ...state,
        orgsummary: [],
        selectedOrgSummaryValue: [],
      };
    case SET_ORGSUMMARY_FILTER_OPTIONS:
      return {
        ...state,
        orgsummary: action.payload,
        selectedOrgSummaryValue: [],
      };
    case SET_SELECTED_ORGSUMMARY_FILTER_OPTIONS:
      return {
        ...state,
        selectedOrgSummaryValue: action.payload,
      };

    //error class
    case ERROR_CLASS_FILTER_OPTIONS:
      return {
        ...state,
        errors: {
          ...state.classerrors,
          classError: "Error in getting the classes, please try again later!",
        },
      };
    //error subclass
    case ERROR_SUBCLASS_FILTER_OPTIONS:
      return {
        ...state,
        errors: {
          ...state.subclasserrors,
          subclassError:
            "Error in getting the subclasses, please try again later!",
        },
      };
    //error groupclass
    case ERROR_GROUPCLASS_FILTER_OPTIONS:
      return {
        ...state,
        errors: {
          ...state.groupclasserrors,
          groupclassError:
            "Error in getting the groupclasses, please try again later!",
        },
      };
    //lineitem
    case ERROR_LINEITEM_FILTER_OPTIONS:
      return {
        ...state,
        errors: {
          ...state.lineitemerrors,
          lineitemError:
            "Error in getting the lineitemError, please try again later!",
        },
      };
    //finAccount
    case ERROR_FINACCOUNT_FILTER_OPTIONS:
      return {
        ...state,
        errors: {
          ...state.finaccounterrors,
          finaccountErrors:
            "Error in getting the finaccounterrors, please try again later!",
        },
      };
    //order
    case ERROR_ORDER_FILTER_OPTIONS:
      return {
        ...state,
        errors: {
          ...state.ordererrors,
          orderErrors:
            "Error in getting the ordererrors, please try again later!",
        },
      };

    //profit center
    case ERROR_PROFITCENTER_FILTER_OPTIONS:
      return {
        ...state,
        errors: {
          ...state.profitcentererrors,
          profitcenterErrors:
            "Error in getting the profitcentererrors, please try again later!",
        },
      };

    //cost center error
    case ERROR_COSTCENTER_FILTER_OPTIONS:
      return {
        ...state,
        errors: {
          ...state.costcentererrors,
          costcenterErrors:
            "Error in getting the costcenterErrors, please try again later!",
        },
      };
    //org error
    case ERROR_ORG_FILTER_OPTIONS:
      return {
        ...state,
        errors: {
          ...state.orgerrors,
          orgErrors: "Error in getting the orgerrors, please try again later!",
        },
      };

    //org summary
    case ERROR_ORGSUMMARY_FILTER_OPTIONS:
      return {
        ...state,
        errors: {
          ...state.orgsummaryerrors,
          orgsummaryErrors:
            "Error in getting the orgsummaryerrors, please try again later!",
        },
      };

    case SET_CASCADINGDROPDOWN_DATA_FROM_PREFERENCE:
      const data = action.payload;
      return {
        ...state,
        classes: data.classes,
        selectedClassesValue: data.selectedClassesValue,
        subclasses: data.subclasses,
        selectedSubClassesValue: data.selectedSubClassesValue,
        groupclasses: data.groupclasses,
        selectedGroupClassesValue: data.selectedGroupClassesValue,
        lineitem: data.lineitem,
        selectedLineItemValue: data.selectedLineItemValue,
        finaccount: data.finaccount,
        selectedFinAccountValue: data.selectedFinAccountValue,
        order: data.order,
        selectedOrderValue: data.selectedOrderValue,
        profitcenter: data.profitcenter,
        selectedProfitCenterValue: data.selectedProfitCenterValue,
        costcenter: data.costcenter,
        selectedCostCenterValue: data.selectedCostCenterValue,
        org: data.org,
        selectedOrgValue: data.selectedOrgValue,
        orgsummary: data.orgsummary,
        selectedOrgSummaryValue: data.selectedOrgSummaryValue,
      };

    default:
      return state;
  }
};

export default cascadingDropDownReducer;
