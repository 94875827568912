import { SET_MERCURY_REFRESH_TIME_VALUE } from "../ActionContants/constants";

const initialState = {
  mercuryRefreshTimeValue: "",
};

const mercuryRefreshTimeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_MERCURY_REFRESH_TIME_VALUE:
      return {
        ...state,
        mercuryRefreshTimeValue: action.payload,
      };
    default:
      return state;
  }
};

export default mercuryRefreshTimeReducer;
