import store from "../../Redux/Store/Store";

export const savePreferenceData = (storeData = store.getState()) => {
    let selectedCompanies =
    storeData.customMultiSelect.selectedSAPCompanyCodes.length > 1
      ? storeData.customMultiSelect.selectedSAPCompanyCodes
        .map((e: any) => e.value)
        .join(",")
      : storeData.customMultiSelect.selectedSAPCompanyCodes[0].value.toString();
  selectedCompanies =
    // storeData.customMultiSelect.selectedSAPCompanyCodes.length ===
    //   storeData.customMultiSelect.sapCompanyCodes.length
    //   ? "All"
    //   : 
      selectedCompanies;
  const objPayload = {
    preferenceName: storeData.textBox.textboxValue,
    preferenceID: 0,
    reportID: 0,
    userAlias: "", // username will be added in action
    // selectedFiscalMonth: storeData.singleSelect.selectedFiscalMonths[0].value,
    selectedCurrencyType: storeData.singleSelect.selectedCurrencyTypes[0].value,
    selectedCompanies: selectedCompanies,
    selectedClass:
      // storeData.cascadingDropDown.selectedClassesValue.length ===
      //   storeData.cascadingDropDown.classes.length
      //   ? "All"
      //   : 
        storeData.cascadingDropDown.selectedClassesValue
          .map((e: any) => e.value)
          .join(","),
    selectedSubClass:
      // storeData.cascadingDropDown.selectedSubClassesValue.length ===
      //   storeData.cascadingDropDown.subclasses.length
      //   ? "All"
      //   :
         storeData.cascadingDropDown.selectedSubClassesValue
          .map((e: any) => e.value)
          .join(","),
    selectedInternalOrder:
      // storeData.cascadingDropDown.selectedOrderValue.length ===
      //   storeData.cascadingDropDown.order.length
      //   ? "All"
      //   :
         storeData.cascadingDropDown.selectedOrderValue
          .map((e: any) => e.value)
          .join(","),
    selectedChannelLineItem:
      // storeData.cascadingDropDown.selectedLineItemValue.length ===
      //   storeData.cascadingDropDown.lineitem.length
      //   ? "All"
      //   :
         storeData.cascadingDropDown.selectedLineItemValue
          .map((e: any) => e.value)
          .join(","),
    selectedFinancialAccount:
      // storeData.cascadingDropDown.selectedFinAccountValue.length ===
      //   storeData.cascadingDropDown.finaccount.length
      //   ? "All"
      //   : 
        storeData.cascadingDropDown.selectedFinAccountValue
          .map((e: any) => e.value)
          .join(","),
    selectedProfitCenter:
      // storeData.cascadingDropDown.selectedProfitCenterValue.length ===
      //   storeData.cascadingDropDown.profitcenter.length
      //   ? "All"
      //   : 
        storeData.cascadingDropDown.selectedProfitCenterValue
          .map((e: any) => e.value)
          .join(","),
    SelectExecSummaryOrg:
      // storeData.cascadingDropDown.selectedOrgSummaryValue.length ===
      //   storeData.cascadingDropDown.orgsummary.length
      //   ? "All"
      //   : 
        storeData.cascadingDropDown.selectedOrgSummaryValue
          .map((e: any) => e.value)
          .join(","),
    selectExecOrg: storeData.cascadingDropDown.selectedOrgValue
      .map((e: any) => e.value)
      .join(","),
    selectedCostCenter:
      // storeData.cascadingDropDown.selectedCostCenterValue.length ===
      //   storeData.cascadingDropDown.costcenter.length
      //   ? "All"
      //   : 
        storeData.cascadingDropDown.selectedCostCenterValue
          .map((e: any) => e.value)
          .join(","),

    isValid: true,
    isDefault: true,
    sortOption: storeData.fieldSort.sortData.filter((x:any) => x.checked === true).map( (y:any) => y.columnName).join(","),

    selectedChannelGroup:
      // storeData.cascadingDropDown.selectedGroupClassesValue.length ===
      //   storeData.cascadingDropDown.groupclasses.length
      //   ? "All"
      //   : 
        storeData.cascadingDropDown.selectedGroupClassesValue
          .map((e: any) => e.value)
          .join(","),
          excludeDataWithInsignificantBalance: storeData.checkboxes.excludeInsignificantBalance,
    excludeZeroBalances: storeData.checkboxes.excludeZeroBalances,
    allDataCenters: true,
    formatID: storeData.singleSelect.selectedDisplayOptions[0].value,
    formatNbr: storeData.singleSelect.selectedDisplayOptions[0].label,
  };
   console.log("objPayload:", objPayload);
  return objPayload;
};
