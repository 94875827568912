import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FiPlusSquare, FiMinusSquare } from "react-icons/fi";
import Draggable from "react-draggable";
import { BiCommentAdd, BiCopy, BiSolidCommentAdd } from 'react-icons/bi';
import { FaRobot } from "react-icons/fa";
import { PiCopyLight } from "react-icons/pi";
import { getToken, getUsername } from '../Services/MsalService';
import axios from 'axios';
import Config from '../../Config';
import { useSelector, useDispatch } from 'react-redux';
import { RootReducer } from '../../Redux/Reducers/types';
import CommentsValidationError from "../Common/CommentsValidationError";
import SpinnerComponent from '../Common/SpinnerComponent';
import { setCommentByParent } from '../../Redux/Actions/commentActions';
import { RiExchangeDollarLine } from "react-icons/ri";
import LegalIntity from '../../Assests/Images/LegalIntitylogo.png';

type Props = {
    show: boolean;
    onHide: () => void;
    commentslst: Comment[];
    showAddCommentsSection?: boolean;
    shownewaddcomment?: boolean;
    showviewhistory?: boolean;
    title: any;
    entity: any;
    parentID: any;
    onUpdateCommentsList: (newComment: any) => void;
    setmodalshow?: boolean;

}
interface Comment {
    isSystemGenerated: boolean;
    commentType?: string;
    comment?: string;
    commentID?: number;
    userAlias?: string;

}

const Commentary = (props: Props) => {

    const [newCommentText, setNewCommentText] = useState<string>('');

    const dispatch = useDispatch();



    const [loggedInUserAlias, setLoggedInUserAlias] = useState('');

    useEffect(() => {
        const fetchLoggedInUserAlias = () => {
            getUsername().then((userAlias) => {
                setLoggedInUserAlias(userAlias);
            }).catch((error) => {
                console.error('Error fetching logged-in userAlias:', error);
            });
        };

        fetchLoggedInUserAlias();
    }, []);

    const [toggleIcon, setToggleIcon] = useState(false);
    const [commentData, setcommentData] = useState<any>([]);

    const [showAddComments, setShowAddComments] = useState(false);
    const [addCommentClicked, setAddCommentClicked] = useState(false);
    const [copyIconVisible, setCopyIconVisible] = useState(false);
    const [viewhistory, setviewhistory] = useState(false);

    const [showSaveCommentAlert, setShowSaveCommentAlert] = useState(false);
    const [showEditComment, setshowEditComment] = useState(false);
    const handleShowAlert = () => setShowSaveCommentAlert(true);

    const [showCommentaryHistory, setShowCommentaryHistory] = useState(true);

    const [historyItems, setHistoryItems] = useState<number[]>([])
    const [currentIndex, setCurrentIndex] = useState(0);
    const [nextclicked, setnextclicked] = useState(false);

    const [loading, setloading] = useState(false);
    const [viewhistoryloader, setViewHistoryLoader] = useState(false);
    const [edittextbox, setEditTextBox] = useState(false);

    const [isTextareaFocused, setIsTextareaFocused] = useState(false);
    const fieldSort = useSelector(
        (state: RootReducer) => state.fieldSort
      );

    const [roleID, setRolIDAction] = useState(fieldSort.RoleID);

    const handleTextareaFocus = () => {
        setIsTextareaFocused(true);
    };

    const handleTextareaBlur = () => {
        setIsTextareaFocused(false);
    };

    const handleMouseDown = (e: any) => {

        const isSelectableElement =
            (e.target as HTMLElement).tagName === 'TEXTAREA' ||
            (e.target as HTMLElement).tagName === 'DIV' ||
            (e.target as HTMLElement).tagName === 'SPAN' ||
            (e.target as HTMLElement).tagName === 'P';


        if (!isSelectableElement) {
            e.preventDefault();
            setIsTextareaFocused(false);
        }
    };

    const [isTitleClicked, setIsTitleClicked] = useState(false);

    const handleTitleClick = () => {
      setIsTitleClicked(true);
    };
  
    const handleDragStart = () => {
        setIsTitleClicked(false);
    }
    

    const [errorMessage, setErrorMessage] = useState('');
    const handletextarea = (e:any) => {
        const inputValue = e.target.value;
        const invalidXmlCharRegex = /[^\x09\x0A\x0D\x20-\xD7FF\xE000-\xFFFD\x10000-x10FFFF]/g;
        const invalidTagCharRegex = /<(\w+)((?:\s+\w+(?:\s*=\s*(?:(?:"[^"]*")|(?:'[^']*')|[^>\s]+))?)*)\s*(\/?)>/;
        const specialCharRegex = /[&<>\"\'\/]/g;
         
        let invalidXmlCharMatches = inputValue.match(invalidXmlCharRegex);
        let invalidTagCharMatches = inputValue.match(invalidTagCharRegex);
        let specialCharMatches = inputValue.match(specialCharRegex);

        let matches = new Set<string>();

        if (invalidXmlCharMatches) {
            invalidXmlCharMatches.forEach((char: string) => matches.add(char));
        }
        if (invalidTagCharMatches) {
            invalidTagCharMatches.forEach((char: string) => matches.add(char));
        }
        if (specialCharMatches) {
            specialCharMatches.forEach((char: string) => matches.add(char));
        }

        if (matches.size > 0) {
            setErrorMessage("The following characters are not allowed: " + Array.from(matches).join(", ") + ". Please remove these characters.");
        } else {
            setErrorMessage('');
        }

        setNewComment(inputValue)
    }
    const [showhistoryerroralert, setShowHistoryErrorAlert] = useState(false);
    const [showsubmiterroralert, setShowSubmitErrorAlert] = useState(false);

    const [commenttype, setcommentrype] = useState("");
    const [useralias, setUserAlias] = useState("");
    const [commentID, setcommentID] = useState(0);
    useEffect(() => {
        if (props.commentslst?.length > 0) {
            let relevantComment = props.commentslst.find(comment => !comment.isSystemGenerated) || props.commentslst[0];

            if (relevantComment) {
                setcommentrype(relevantComment.commentType || '');
                setNewCommentText(relevantComment.comment || '');
                setcommentID(relevantComment.commentID || 0);

                if (relevantComment.userAlias && relevantComment.userAlias.length > 0) {
                    setUserAlias(relevantComment.userAlias);
                }
            }
        }
    }, [props.commentslst]);





    // const [showroboticon, setShowRobotIcon] = useState(false);



    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleNext = () => {
        setnextclicked(true);
        if (currentIndex < historyItems.length - 1) {
            setCurrentIndex(currentIndex + 1);
            setnextclicked(false);
        }
    };

    interface CommentaryItem {
        user: string;
        fiscalMonthName: string;
        commentDate: string;
        comment: string;
    }

    const [commentaryPriorData, setCommentaryPriorData] = useState<CommentaryItem[]>([]);


    const renderDummyDiv = () => {
        let dataToRender;

        if (currentIndex === 0) {
            if (props.commentslst.length > 0) {
                if (props.commentslst[0]?.isSystemGenerated && props.commentslst.length > 1) {
                    dataToRender = props.commentslst[1];
                } else if (!props.commentslst[0]?.isSystemGenerated) {
                    dataToRender = props.commentslst[0];
                }
            }
        } else {
            dataToRender = commentaryPriorData[currentIndex];
        }

        if (dataToRender) {
            const { user, fiscalMonthName, commentDate, comment } = dataToRender as CommentaryItem;

            const date = new Date(Date.parse(commentDate.replace('T', 'z')))
                .toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });

            const time = new Date(Date.parse(commentDate.replace('T', 'z')))
                .toLocaleTimeString('en-US', { timeStyle: 'short' }).toUpperCase();

            return (
                <>
                    <div className="commentary-info">
                        <div className="commentary-details CommentUser">
                            <span className="month">{user.split('(')[0]}</span>
                            <span className="time-stamp">{fiscalMonthName}</span>
                            <span className="time-stamp">{date}, {time}</span>
                        </div>
                        <div className="commentary-desp" style={{ height: '80px', overflow: 'auto' }}>{comment}</div>
                    </div>
                </>
            );
        } else {
            return null;
        }


        return null;
    };







    const handleaddcommentclick = () => {
        setShowAddComments(true);
        setAddCommentClicked(true);
        setShowCommentaryHistory(false);
        setCopyIconVisible(true);
        setEditTextBox(false);
        setNewComment("");
        setCommentActionType('SAVE');
        setErrorMessage('');

    };

    const handleupdatecommentclick = () => {
        setEditTextBox(true);
        setshowEditComment(true);
        setNewComment(newCommentText);
        setCommentActionType('UPDATE');
    };

    const handleviewhistory = async () => {
        setViewHistoryLoader(true);

        try {
            await getPriorComment();
        } catch (error) {
        } finally {
            setviewhistory(!viewhistory);
            setnextclicked(false);
            setCurrentIndex(0);
        }
    }
    const handlebackbutton = () => {

        setviewhistory(!viewhistory);
        setnextclicked(false);
        setCurrentIndex(0);

    }

    const handleCancelClick = () => {
        setShowAddComments(false);
        setNewComment("");
        setAddCommentClicked(false);
        setCopyIconVisible(false);
        setShowCommentaryHistory(true);
        setviewhistory(false);
        setCurrentIndex(0);
        setshowEditComment(false);
        setEditTextBox(false);
        setCommentActionType('SAVE');
    };

    const onshowevt = () => {
        setexpdval();
    }

    const setexpdval = () => {
        if ((props.commentslst != null && props.commentslst.length > 0)) {
            const allCommentsWithExpand = props.commentslst.map((item: any) => {
                return { ...item, expand: false };
            });
            setcommentData([...allCommentsWithExpand]);
        }
    }

    const handleIconToggle = (commentID: any) => {


        commentData.map((item: any) => {
            if (item.commentID === commentID)
                item.expand = !item.expand;
        });
        setcommentData([...commentData]);
    }


    const [newComment, setNewComment] = useState<string>('');

    const handleCopyToNewComment = (comment: string) => {

        setNewComment(comment);
    };

    const handlehide = () => {
        setShowAddComments(false);
        setNewComment("");
        setShowCommentaryHistory(true);
        setviewhistory(false);
        setCurrentIndex(0);
        setshowEditComment(false);
        setEditTextBox(false);
        setErrorMessage('');
        props.onHide();
    }
    const handleClose = () => {
        setShowAddComments(false);
        setNewComment("");
        setShowCommentaryHistory(true);
        setviewhistory(false);
        setCurrentIndex(0);
        setshowEditComment(false);
        setEditTextBox(false);
        setShowHistoryErrorAlert(false);
        setShowSubmitErrorAlert(false);
        props.onHide(); 
    }

    const handleOK = () => {
        setShowSaveCommentAlert(false);
        setShowAddComments(false);
        setNewComment("");
        setShowCommentaryHistory(true);
        setviewhistory(false);
        setCurrentIndex(0);
        setshowEditComment(false);
        setEditTextBox(false);
    }

    const newcommentcancel = () => {
        setNewComment("");
        props.onHide();
    }
    const store = useSelector(
        (state: RootReducer) => state
    );

    const [showValidationError, setShowValidationError] = React.useState(false);
    const handleModalHide = () => {
        setShowValidationError(false);
    };
    const [apiResponseData, setApiResponseData] = useState<any[]>([])

    const [commentActionType, setCommentActionType] = useState('SAVE');
    useEffect(() => {
        setCommentActionType('SAVE');
        renderDummyDiv();
    }, []);

    const submitComment = async (actionType: any) => {
        //debugger
        try {
            if (!newComment || newComment.trim() === '') {
                setShowValidationError(true);
                return;
            }
            setloading(true);

            const month = store.singleSelect.selectedFiscalMonths?.[0]?.label || 'DefaultMonth';
            const monthId = store.singleSelect.selectedFiscalMonths?.[0]?.value || 0;
            const sAPCompanyCodes = store.customMultiSelect.selectedSAPCompanyCodes.length > 0 ? store.customMultiSelect.selectedSAPCompanyCodes.map((e: any) => e.label).join(",") : "";
            const value = store.customMultiSelect.selectedSAPCompanyCodes.length > 0 ? store.customMultiSelect.selectedSAPCompanyCodes.map((e: any) => e.value).join(",") : "";
            const sAPCompanyID = value != null ? value.toString() : '';
            const channelClassId = store.cascadingDropDown.selectedClassesValue.length > 0 ? store.cascadingDropDown.selectedClassesValue.map((e: any) => e.value).join(",") : "";
            const channelSubClassId = store.cascadingDropDown.selectedSubClassesValue.length > 0 ? store.cascadingDropDown.selectedSubClassesValue.map((e: any) => e.value).join(",") : "";
            const financialAccountId = store.cascadingDropDown.selectedFinAccountValue.length > 0 ? store.cascadingDropDown.selectedFinAccountValue.map((e: any) => e.value).join(",") : "";
            const type = actionType;
            const parentId = commentData[0] && commentData[0].ParentID !== undefined ? commentData[0].ParentID : props.parentID;
            const title = commentData[0] && commentData[0].Title !== undefined ? commentData[0].Title : props.title;
            const periodType = store.gridData.gridType.payload;

            const Commentparam = {
                Comment: newComment,
                Title: commentData[0] && commentData[0].Title !== undefined ? commentData[0].Title : props.title,
                FiscalMonthName: month,
                SAPCompanyCode: sAPCompanyCodes,
                Entity: commentData[0] && commentData[0].Entity !== undefined ? commentData[0].Entity : props.entity,
                ParentID: commentData[0] && commentData[0].ParentID !== undefined ? commentData[0].ParentID : props.parentID,
                SAPCompanyID: sAPCompanyID,
                FiscalMonthID: monthId,
                ChannelClassId: channelClassId,
                ChannelSubClassId: channelSubClassId,
                FinancialAccountId: financialAccountId,
                Type: type,
                CommentID: commentID,
                PeriodType: periodType

            };


            const token = await getToken();
            const response = await axios.post(
                `${Config.apiBaseAddress}${Config.apiSaveComment}`,
                Commentparam,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.status === 200) {
                setloading(false);
                props.onHide();

                const result = Array.isArray(response.data) && response.data.length > 0 && Array.isArray(response.data[0])
                    ? response.data[0]
                    : response.data;

                props.onUpdateCommentsList(result);

                let commentType, userAlias, newComment, commentID, parentID, selectedFiscalMonthID, selectedSAPCompanyID;

                if (Array.isArray(result) && result.length > 0) {
                    const relevantComment = result[0].isSystemGenerated && result.length > 1
                        ? result[1]
                        : result[0];

                    commentType = relevantComment.commentType;
                    userAlias = relevantComment.userAlias;
                    newComment = relevantComment.comment;
                    commentID = relevantComment.commentID;
                    parentID = relevantComment.parentID;
                    selectedFiscalMonthID = relevantComment.selectedFiscalMonthID;
                    selectedSAPCompanyID = relevantComment.selectedSAPCompanyID;
                }

                if (commentType) {
                    setcommentrype(commentType);
                    setNewCommentText(newComment);
                    setcommentID(commentID);
                }
                if (userAlias) {
                    setUserAlias(userAlias);
                }

                dispatch(setCommentByParent({
                    desc: title, 
                    commentList: result,
                    parentID: parentID,
                    selectedFiscalMonthID: selectedFiscalMonthID,
                    selectedSAPCompanyID: selectedSAPCompanyID
                  }));
                  
            }
            handleShowAlert();
            setNewComment("");
        } catch (error) {
            setloading(false);
            setShowSubmitErrorAlert(true);
            console.error('Error config:', error);
        }
    };

    const getPriorComment = async () => {
        try {

            const month = store.singleSelect.selectedFiscalMonths?.[0]?.value || 0;
            const value = store.customMultiSelect.selectedSAPCompanyCodes.length > 0 ? store.customMultiSelect.selectedSAPCompanyCodes.map((e: any) => e.label).join(",") : "";
            const valueId = store.customMultiSelect.selectedSAPCompanyCodes.length > 0 ? store.customMultiSelect.selectedSAPCompanyCodes.map((e: any) => e.value).join(",") : "";
            const channelClassId = store.cascadingDropDown.selectedClassesValue.length > 0 ? store.cascadingDropDown.selectedClassesValue.map((e: any) => e.value).join(",") : "";
            const channelSubClassId = store.cascadingDropDown.selectedSubClassesValue.length > 0 ? store.cascadingDropDown.selectedSubClassesValue.map((e: any) => e.value).join(",") : "";
            const financialAccountId = store.cascadingDropDown.selectedFinAccountValue.length > 0 ? store.cascadingDropDown.selectedFinAccountValue.map((e: any) => e.value).join(",") : "";

            const sAPCompanyCode = value != null ? value.toString() : '';
            const sAPCompanyId = valueId != null ? valueId.toString() : '';
            const parentID = props.parentID;
            const entity = commentData[0] && commentData[0].Entity !== undefined ? commentData[0].Entity : props.entity;
            const title = commentData[0] && commentData[0].Title !== undefined ? commentData[0].Title : props.title;
            const periodType = store.gridData.gridType.payload;

            const PriorCommentparam = {
                FiscalMonthID: month,
                SAPCompanyCode: sAPCompanyCode,
                SAPCompanyID: sAPCompanyId,
                ChannelClassId: channelClassId,
                ChannelSubClassId: channelSubClassId,
                FinancialAccountId: financialAccountId,
                Title: title,
                UserAlias: await getUsername(),
                Entity: entity,
                ParentID: commentData[0] && commentData[0].ParentID !== undefined ? commentData[0].ParentID : props.parentID,
                PeriodType: periodType
            };


            const token = await getToken();
            const response = await axios.post(
                `${Config.apiBaseAddress}${Config.apiGetPriorComments}`,
                PriorCommentparam,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setCommentaryPriorData(response.data);
            console.log('Count is not a number', response.data.length);
            if (typeof response.data.length === 'number') {
                setHistoryItems(new Array(response.data.length).fill(null).map((_, index) => index + 1));
            } else {
                console.log('Count is not a number', response.data);
            }
            if (response.status === 200) {
                setViewHistoryLoader(false);
            }
        } catch (error) {
            setViewHistoryLoader(false);
            setShowHistoryErrorAlert(true);
            console.error('Error config:');
        }
    };
    

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onShow={onshowevt}
                onHide={handlehide}
                backdrop="static"
                onMouseDown={handleDragStart}
                
            >
                <Draggable disabled={isTextareaFocused || isTitleClicked} onMouseDown={handleDragStart}>
                    <div>
                        <Modal.Header closeVariant="white" closeButton={!loading && !viewhistoryloader} title='' onClick={handleTitleClick}>
                            <Modal.Title id="contained-modal-title-vcenter">Commentary</Modal.Title>
                        </Modal.Header>
                        <Modal.Body title='' >
                            {(commentData != null && commentData.length > 0) &&
                                <div className="commentary-item" style={{
                                    marginRight: (showAddComments || props.showAddCommentsSection) && commentData.length > 1 ? '7%' :
                                        (showAddComments || props.showAddCommentsSection) && commentData.length === 1 ? '5%' :
                                            '0%'
                                }}
                                onClick={handleTitleClick} 
                                >
                                    <div className="commentary-info">
                                        <div className="category ">{commentData[0].category}
                                            {!props.showAddCommentsSection && !showAddComments && store.fieldSort.CanEnter  && (
                                                <div className="AddComments"> <BiCommentAdd onClick={(loading || viewhistoryloader) ? undefined : handleaddcommentclick} title='Add New Comment' style={{ fontSize: '20px' }} />
                                                </div>
                                            )}
                                        </div>
                                        {props.showAddCommentsSection && (
                                            <div className="blank-div">
                                                <div className='textcontainer'>
                                                    <textarea className='textarea-theme'
                                                        value={newComment}
                                                        onChange={handletextarea}
                                                        onFocus={handleTextareaFocus}
                                                        onBlur={handleTextareaBlur}
                                                        placeholder="Enter your comments here..."
                                                        rows={3}
                                                        cols={50}
                                                        style={{ width: '100%', marginTop: '1px', border: '1px solid #ddd' }}
                                                        disabled={loading}
                                                        spellCheck={false}
                                                    />
                                                    {errorMessage && (
                                                        <div style={{ color: 'red', marginTop: '5px' }}>
                                                            {errorMessage}
                                                        </div>
                                                    )}
                                                    {loading && (
                                                        <div className='Submit-spinner'>
                                                            <SpinnerComponent text="Loading..." />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='button-container'>
                                                    {!loading && !errorMessage && (
                                                        <button
                                                            className='btn-submit'
                                                            onClick={() => submitComment(commentActionType)}
                                                            title=''
                                                            disabled={loading}
                                                        >
                                                            {commentActionType === 'UPDATE' ? 'Update' : 'Submit'}
                                                        </button>

                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {edittextbox && (
                                            <div className="blank-div">
                                                <div className='textcontainer'>
                                                    <textarea className='textarea-theme'
                                                        value={newComment}
                                                        onChange={handletextarea}
                                                        onFocus={handleTextareaFocus}
                                                        onBlur={handleTextareaBlur}
                                                        placeholder="Enter your comments here..."
                                                        rows={3}
                                                        cols={50}
                                                        style={{ width: '100%', marginTop: '1px', border: '1px solid #ddd' }}
                                                        disabled={loading || viewhistoryloader}
                                                        spellCheck={false}
                                                    />
                                                    {errorMessage && (
                                                        <div style={{ color: 'red', marginTop: '5px' }}>
                                                            {errorMessage}
                                                        </div>
                                                    )}
                                                    {loading && (
                                                        <div className='Submit-spinner'>
                                                            <SpinnerComponent text="Loading..." />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='button-container'>
                                                    {!loading && !errorMessage && (
                                                        <>
                                                            <button
                                                                className='btn-submit'
                                                                onClick={() => submitComment(commentActionType)}
                                                                title=''
                                                                disabled={loading || viewhistoryloader} 
                                                            >
                                                                {commentActionType === 'UPDATE' ? 'Update' : 'Submit'}
                                                            </button>
                                                            <button className="btn-cancel" onClick={handleCancelClick} title='' disabled={loading || viewhistoryloader}>Cancel</button>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {!props.showAddCommentsSection && showAddComments && (
                                            <div className="blank-div">
                                                <div className='textcontainer'>
                                                    <textarea className='textarea-theme'
                                                        value={newComment}
                                                        onChange={handletextarea}
                                                        onFocus={handleTextareaFocus}
                                                        onBlur={handleTextareaBlur}
                                                        placeholder="Enter your comments here..."
                                                        rows={3}
                                                        cols={50}
                                                        style={{ width: '100%', marginTop: '1px', border: '1px solid #ddd' }}
                                                        disabled={loading}
                                                        spellCheck={false}
                                                    />
                                                    {loading && (
                                                        <div className='Submit-spinner'>
                                                            <SpinnerComponent text="Loading..." />
                                                        </div>
                                                    )}
                                                    {loading && (
                                                        <div className='Submit-spinner'>
                                                            <SpinnerComponent text="Loading..." />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="button-container">
                                                    {!loading && !errorMessage && (
                                                        <>
                                                            <button className="btn-submit" onClick={() => submitComment(commentActionType)} title='' disabled={loading}>Submit</button>
                                                            <button className="btn-cancel" onClick={handleCancelClick} title='' disabled={loading}>Cancel</button>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {!showAddComments && !props.showAddCommentsSection && commentData && commentData.length > 0 && (
                                            <div className="dummy-container">
                                                {renderDummyDiv()}
                                            </div>
                                        )}
                                        {/* {showEditComment && (
                                            <div className="dummy-container">
                                                {renderDummyDiv()}
                                            </div>
                                        )} */}
                                        {/* Important code dont delete */}
                                        {/* {!showAddComments && !props.showAddCommentsSection && (
                                            <>
                                                <div className="commentary-details CommentUser">
                                                    <span className="user-name">{commentData[0].user.split('(')[0]}</span>
                                                    <span className="time-stamp">{commentData[0].fiscalMonthName}</span>
                                                    <span className="time-stamp">
                                                        {new Date(Date.parse(commentData[0].commentDate.replace('T', 'z')))
                                                            .toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })}
                                                        ,{" "}
                                                        {new Date(Date.parse(commentData[0].commentDate.replace('T', 'z')))
                                                            .toLocaleTimeString('en-US', { timeStyle: 'short' }).toUpperCase()}
                                                    </span>
                                                </div>
                                                <div className="commentary-desp">{commentData[0].comment}</div>
                                                {props.showAddCommentsSection && (
                                                    <div className='editcontainer'>
                                                        <button className='EditComment'>Edit Comment</button>
                                                    </div>
                                                )}
                                            </>
                                        )} */}
                                    </div>
                                </div>
                            }



                            {/* { showEditComment && (
                                <div className="commentary-history">
                                    {
                                        (commentData != null && commentData.length > 0) &&
                                        commentData.map((item: any, index: any) => {
                                            if (index !== 0) 
                                            return null;
                                            var theDate = new Date(Date.parse(item.commentDate.replace('T', 'z')));
                                            var local_date = theDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
                                                + ", "
                                                + theDate.toLocaleTimeString('en-US', { timeStyle: 'short' }).toUpperCase();
                                              

                                            return (
                                                <div className="commentary-history-item-container">
                                                    <div className={item.expand ? "commentary-history-item auto-height" : "commentary-history-item"}>
                                                        <div className="commentary-info">
                                                            <div className="commentary-details CommentUser"> */}
                            {/* {showroboticon === true && (
                                                            <div className='btn-robot'><FaRobot className='robot-icon' />
                                                                </div>
                                                                )} */}

                            {/* <span className="month">{item.user.split('(')[0]}</span>
                                                                <span className="time-stamp">{item.fiscalMonthName}</span>

                                                                <span className="time-stamp">{local_date}</span>
                                                            </div>
                                                            <div className="commentary-desp">{item.comment}</div>

                                                        </div>

                                                    </div>

                                                </div>
                                            )

                                        })
                                    }
                                </div>
                            )} */}

                            {props.showviewhistory && showCommentaryHistory && (
                                <div >
                                    {viewhistory ? (
                                        <div className='viewhistory-container'>
                                            <>
                                                <div className='nav-buttons'>
                                                    <button className={`btn-previous  ${nextclicked || currentIndex > 0 ? 'colored' : ''}`} onClick={handlePrevious} disabled={currentIndex === 0} title='Previous'>
                                                        {'<'}
                                                    </button>
                                                    <span className="button-gap" />
                                                    <button className={`btn-next ${nextclicked || currentIndex < historyItems.length - 1 ? 'colored' : ''}`} onClick={handleNext} disabled={currentIndex === historyItems.length - 1} title='Next'>
                                                        {'>'}
                                                    </button>
                                                </div>
                                                <button className='btn-back' onClick={handlebackbutton} title=''>
                                                    Back
                                                </button>
                                            </>
                                        </div>
                                    ) : (
                                        <>
                                            {viewhistoryloader && (
                                                <div className='Viewhistory-Spinner'>
                                                    <SpinnerComponent text="Loading..." />
                                                </div>
                                            )}
                                            <div className='viewhistory'>
                                                {!viewhistoryloader && (
                                                    <>


                                                        {commenttype === "PandL" && useralias === loggedInUserAlias && store.fieldSort.CanEnter && (
                                                            <button className='btn-viewhistory' onClick={handleupdatecommentclick} title='' disabled={viewhistoryloader}>
                                                                Edit Comment
                                                            </button>
                                                        )}
                                                        <span className="button-gap" />
                                                        <button className='btn-viewhistory' onClick={loading ? undefined : handleviewhistory} title='' disabled={viewhistoryloader}>
                                                            View History
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}


                            {!props.shownewaddcomment && !showCommentaryHistory && (
                                <div className="commentary-history">
                                    {
                                        (commentData != null && commentData.length > 0) &&
                                        commentData.map((item: any, index: any) => {
                                            // if (index == 0)
                                            //     return null;
                                            var theDate = new Date(Date.parse(item.commentDate.replace('T', 'z')));
                                            var local_date = theDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
                                                + ", "
                                                + theDate.toLocaleTimeString('en-US', { timeStyle: 'short' }).toUpperCase();

                                            return (
                                                <div className="commentary-history-item-container">
                                                    <div className={item.expand ? "commentary-history-item auto-height" : "commentary-history-item"}>
                                                        <div className="commentary-info">
                                                            <div className="commentary-details CommentUser">
                                                                {item.commentType === "PandL" && (
                                                                    <div className='btn-robot' title="Subsidiary P&L"><RiExchangeDollarLine />
                                                                    </div>
                                                                )}
                                                                {item.isSystemGenerated == true && (
                                                                    <div className='btn-robot' title="IC"><FaRobot /></div>
                                                                )}
                                                                {item.commentType === "LegalEntity" && item.isSystemGenerated == false && (
                                                                  <img  src={LegalIntity} alt='Legal Entity' style = {{height: '18px'}} title = 'Legal Entity'  />  
                                                                )}
                                                                <span className="toggle-btn">
                                                                    <button type="button" className="c-btn-icon-only" aria-label="" onClick={() => handleIconToggle(item.commentID)}>
                                                                        {item.expand ? <FiMinusSquare className="icon-minus" title='Collapse' /> : <FiPlusSquare className="icon-plus" title='Expand' />}</button>
                                                                </span>
                                                                <span className="month">{item.user.split('(')[0]}</span>
                                                                <span className="time-stamp">{item.fiscalMonthName}</span>

                                                                <span className="time-stamp">{local_date}</span>
                                                            </div>
                                                            <div className="commentary-desp"
                                                            
                                                            >{item.comment}</div>

                                                        </div>

                                                    </div>
                                                    {(addCommentClicked || props.showAddCommentsSection == true) && (
                                                        <div className="copy-icon-container" onClick={() => loading ? undefined : handleCopyToNewComment(item.comment)}>
                                                            <PiCopyLight />

                                                        </div>
                                                    )}
                                                </div>
                                            )

                                        })
                                    }
                                </div>
                            )}
                            {props.showAddCommentsSection && (
                                <div className="commentary-history">
                                    {
                                        (commentData != null && commentData.length > 0) &&
                                        commentData.map((item: any, index: any) => {
                                            // if (index == 0)
                                            //     return null;
                                            var theDate = new Date(Date.parse(item.commentDate.replace('T', 'z')));
                                            var local_date = theDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
                                                + ", "
                                                + theDate.toLocaleTimeString('en-US', { timeStyle: 'short' }).toUpperCase();



                                            return (
                                                <div className="commentary-history-item-container">
                                                    <div className={item.expand ? "commentary-history-item auto-height" : "commentary-history-item"}>
                                                        <div className="commentary-info">
                                                            <div className="commentary-details CommentUser">
                                                                {item.commentType === "PandL" && (
                                                                    <div className='btn-robot' title="Subsidiary P&L"><RiExchangeDollarLine />
                                                                    </div>
                                                                )}
                                                                {item.isSystemGenerated === true && (
                                                                    <div className='btn-robot' title="IC"><FaRobot /></div>
                                                                )}
                                                                {item.commentType === "LegalEntity" && item.isSystemGenerated == false && (
                                                                    <img  src={LegalIntity} alt='Legal Entity' style = {{height: '18px'}} title = 'Legal Entity'/>
                                                                )}
                                                                <span className="toggle-btn">
                                                                    <button type="button" className="c-btn-icon-only" aria-label="" onClick={() => handleIconToggle(item.commentID)}>
                                                                        {item.expand ? <FiMinusSquare className="icon-minus" title='Collapse' /> : <FiPlusSquare className="icon-plus" title='Expand' />}</button>
                                                                </span>
                                                                <span className="month">{item.user.split('(')[0]}</span>
                                                                <span className="time-stamp">{item.fiscalMonthName}</span>

                                                                <span className="time-stamp">{local_date}</span>
                                                            </div>
                                                            <div className="commentary-desp">{item.comment}</div>

                                                        </div>

                                                    </div>
                                                    {(addCommentClicked || props.showAddCommentsSection == true) && (
                                                        <div className="copy-icon-container" onClick={() => loading ? undefined : handleCopyToNewComment(item.comment)}>
                                                            <PiCopyLight />

                                                        </div>
                                                    )}
                                                </div>
                                            )

                                        })

                                    }


                                </div>
                            )}

                            {props.shownewaddcomment && (
                                <>
                                    <div className="commentary-item">
                                        <div className="commentary-info">
                                            <div className="category " onClick={handleTitleClick}>{props.title}

                                            </div>
                                            <div className="blank-div">
                                                <div className='textcontainer'>
                                                    <textarea className='textarea-theme'
                                                        value={newComment}
                                                        onChange={handletextarea}
                                                        onFocus={handleTextareaFocus}
                                                        onBlur={handleTextareaBlur}
                                                        placeholder="Enter your comments here..."
                                                        rows={3}
                                                        cols={50}
                                                        style={{ width: '100%', marginTop: '1px', border: '1px solid #ddd' }}
                                                        disabled={loading}
                                                        spellCheck={false}
                                                    />
                                                    {errorMessage && (
                                                        <div style={{ color: 'red', marginTop: '5px' }}>
                                                            {errorMessage}
                                                        </div>
                                                    )}
                                                    {loading && (
                                                        <div className='Submit-spinnerblnk'>
                                                            <SpinnerComponent text="Loading..." />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="button-container">
                                                    {!loading && !errorMessage && (
                                                        <>
                                                            <button className="btn-submit" onClick={() => submitComment(commentActionType)} title=''>Submit</button>
                                                            <button className="btn-cancel" onClick={newcommentcancel} title=''>Cancel</button>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Modal.Body>

                    </div>
                </Draggable>
            </Modal>
            {showSaveCommentAlert && (
                <Modal show={true} size="sm">
                    <Modal.Body>
                        <p>{commentActionType === 'SAVE' ? 'Comment Added Successfully!' : 'Comment Updated Successfully!'}</p>

                        <div className="d-flex justify-content-end">
                            <button
                                className="btn-sm btn btn-dark px-3"
                                onClick={handleOK}
                            >
                                Ok
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            )}
            {showhistoryerroralert && (
                <Modal show={true} size="sm">
                    <Modal.Body>
                        <p>Error while fetching previous comment, please try again later!</p>
                        <div>
                            <button
                                className="btn-sm btn btn-dark"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            )}
            {showsubmiterroralert && (
                <Modal show={true} size="sm">
                    <Modal.Body>
                        <p>Error while submitting the comment, please try again later!</p>
                        <div>
                            <button
                                className="btn-sm btn btn-dark"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            )}
            <CommentsValidationError
                show={showValidationError}
                handleModalHide={handleModalHide}
            />
        </>
    )


}

export default Commentary



