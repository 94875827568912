import { Option } from '../Actions/ActionType';
import { SET_SELECTED_OPTIONS } from '../ActionContants/constants';

interface MultiSelectAllState {
  selectedOptions: { [group: string]: Option[] };
}

const initialState: MultiSelectAllState = {
  selectedOptions: {},
};

const multiSelectAllReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_SELECTED_OPTIONS:
      return {
        ...state,
        selectedOptions: {
          ...state.selectedOptions,
          [action.payload.group]: action.payload.selectedOptions,
        },
      };
    default:
      return state;
  }
};

export default multiSelectAllReducer;