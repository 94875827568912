import { Option } from "../Actions/ActionType";
import { ERROR_COMPANYREGION_OPTIONS, SET_COMPANYREGION_OPTIONS, SET_SELECTED_COMPANYREGION_OPTIONS } from "../ActionContants/constants";

interface companyRegionState {
    companyregion:Option[],
    selectedCompanyRegionValue:Option[],
    companyregionerrors:any,
}
const initialState: companyRegionState = {
  companyregion:[],
  selectedCompanyRegionValue:[],
  companyregionerrors:{companyregionErrors:""}
}
const companyRegionReducer = (state = initialState, action: any) => {
  // console.log("companySubRegion reducer",action)
    switch (action.type) {
        //class
        case SET_COMPANYREGION_OPTIONS: 
        return {
            ...state,
            companyregion: action.payload,
          };
          case SET_SELECTED_COMPANYREGION_OPTIONS:
      return {
        ...state,
        selectedCompanyRegionValue: action.payload,
      };
      case ERROR_COMPANYREGION_OPTIONS:
      return {
        ...state,
        errors: {
          ...state.companyregionerrors,
          companyregionerrors: "Error in getting the companyregionerrors, please try again later!",
        },
      };
      default:
      return state;

    }
}
export default companyRegionReducer
 
