import React, { useEffect, useState } from "react";
interface Props {
    celldata: any;
    selected: any;
    handlecallbackBtnClick: (val: any) => void;
    col:any;
    disabled?: boolean;
}

export const ButtonsAsColumns: React.FC<Props> = ({
    celldata,
    selected,
    handlecallbackBtnClick,
    col,
    disabled
}) => {
    const selectedBtn = selected === true ? "selected" : "";
    //const data = celldata === "" ? "Expand/Collapse" : celldata;
    const handleBtnClick = (e: any) => {
        handlecallbackBtnClick(e.currentTarget.value);
    };
    return (
        <>
            <div>
                <button className={selectedBtn} type="button"
                    onClick={handleBtnClick}
                    disabled={disabled}
                    value={col}>{celldata}
                </button>
            </div>
        </>
    )
};