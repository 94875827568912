import React, { useState } from "react";
import Select, { components } from "react-select";
import { CloseButton } from "react-bootstrap";
import { Modal, ModalBody } from "react-bootstrap";

interface Option {
  value: string;
  label: string;
}

interface SingleSelectProps {
  label: string;
  options: Option[];
  selectedValue: Option[];
  onChange: (selectedValue: Option[]) => void;
  defaultValue: Option[];
  required?: boolean;
  parentCallback: any;
}

const PreferenceSelect: React.FC<SingleSelectProps> = ({
  label,
  options,
  selectedValue,
  onChange,
  defaultValue,
  required,
  parentCallback,
}) => {
  const [showDeletePrefAlert, setShowDeletePrefAlert] = useState(false);
  const [valueToDelete, setValueToDelete] = useState("");

  const handleDeleteOption = (e: any, value: string) => {
    e.stopPropagation();
    setValueToDelete(value);
    setShowDeletePrefAlert(true);
  };

  const handleDeleteConfirmation = () => {

    if (options.length === 1) {
      const updatedSelectedValue = selectedValue.filter(
        (option) => option.value !== valueToDelete
      );
      onChange(updatedSelectedValue);
      setShowDeletePrefAlert(false);
      parentCallback(valueToDelete,true);
    
    } else if (options.length > 0) {
      const updatedSelectedValue = selectedValue.filter(
        (option) => option.value !== valueToDelete
      );
      onChange(updatedSelectedValue);
      setShowDeletePrefAlert(false);
      parentCallback(valueToDelete,false);
    }
  };

  const optionWithDelete = (props: any) => {
    const { children, data } = props;

    return (
      <div className="Preference-delete-wrap">
        <components.Option {...props}>{children}</components.Option>
        <CloseButton
          className="preference-delete-btn"
          onClick={(e) => handleDeleteOption(e, data.value)}
        />
      </div>
    );
  };

  const handleSelectChange = (selectedValue: any) => {
   
    onChange([selectedValue]);
  };

  return (
    <>
      <div className="mb-3">
        <div className="d-flex justify-content-between form-label">
          <label htmlFor={label}>
            {label}
            {required && <span> *</span>}
          </label>
        </div>
        <Select
          options={options}
          components={{
            IndicatorSeparator: () => null,
            Option: optionWithDelete,
          }}
          value={selectedValue}
          onChange={handleSelectChange}
          defaultValue={defaultValue}
          required={required}
          aria-label={label}
        />
      </div>

      {showDeletePrefAlert && (
        <Modal show={true} size="sm">
          <ModalBody>
            <p>Are you sure you want to delete?</p>
            <div className="d-flex justify-content-end">
              <button
                className="btn-sm btn btn-dark px-3 me-2"
                onClick={handleDeleteConfirmation}
              >
                Ok
              </button>
              <button
                className="btn-sm btn btn-dark"
                onClick={() => setShowDeletePrefAlert(false)}
              >
                Cancel
              </button>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default PreferenceSelect;
