import React from 'react';
import Spinner from 'react-bootstrap/esm/Spinner';

interface SpinnerProps {
  text: string;
}

const SpinnerComponent: React.FC<SpinnerProps> = ({ text }) => {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: 'auto' }}>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">{text}</span>
      </Spinner>
    </div>
  );
};

export default SpinnerComponent;
