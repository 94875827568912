import { UserAgentApplication } from "msal";
import Config from "./Config"; 
  
export const config: any = {
  
  auth: {
    clientId: Config.ClientID,
    authority: 
      "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
    redirectUri: window.location.pathname == '/' ? Config.redirectUri : `${Config.redirectUri}${window.location.pathname}` 
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

const msalApp = new UserAgentApplication(config);

export default msalApp;
