import {
  SET_QTD_DATA,
  SET_SELECTED_QTD_DATA,
  ERROR_QTD_DATA,
  SET_TD_TYPE,
  SET_EXPAND_COLLAPSE_TYPE,
  INIT_GRID_DATA,
  SET_EXPANDALL,
  SET_GET_SEGMENTAL_DATA_QTD_API,
} from "../ActionContants/constants";

interface gridDataState {
  gridData?: gridData;
  griderrors: any;
  gridType: any;
  ExpColStatus: any;
  ExpandAllStatus: boolean;
  getSegmentalDataApiRes: any;
  apiRes: any;
}
interface gridData {
  gridData?: any[];
  actualsClasses?:any[];
  gridColumnHeaders?: any;
  gridColumnHeadersSelected?: any;
}

const initialState: gridDataState = {
  gridData: {
    gridData: [],
    actualsClasses:[],
    gridColumnHeaders: {},
    gridColumnHeadersSelected:{}
  },
  griderrors: { gridError: "" },
  gridType: "",
  ExpColStatus: "",
  ExpandAllStatus: false,
  getSegmentalDataApiRes: "",
  apiRes: "",
};

const gridReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_QTD_DATA:
      return {
        ...state,
        gridData: action.payload,
        apiRes: action.apiResp,
      };
    case SET_TD_TYPE:
      return {
        ...state,
        gridType: action,
      };
    case SET_EXPAND_COLLAPSE_TYPE:
      return {
        ...state,
        ExpColStatus: action,
      };
    case INIT_GRID_DATA:
      return {
        ...state,
        gridData: {
          gridData: [],
          actualsClasses:[],
          gridColumnHeaders: {},
          gridColumnHeadersSelected:{}
        },
      };
    case SET_EXPANDALL:
      return {
        ...state,
        ExpandAllStatus: action.payload,
      };

    //error grid
    case ERROR_QTD_DATA:
      return {
        ...state,
        errors: {
          ...state.griderrors,
          gridError: "Error in getting the grid, please try again later!",
        },
      };

    default:
      return state;
  }
};

export default gridReducer;
