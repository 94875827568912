import React, { useEffect, useState } from 'react'
import MainLayout from './MainLayout'
import MercuryAccessLayout from './MercuryAccessLayout'
import axios from "axios";
import Config from "../Config";
import { getToken } from './Services/MsalService'; 

function Layout() {

    const [hasAccess, setHasAccess] = useState(true);

    useEffect(() => {
        (async () => {
          axios
          .get(`${Config.apiBaseAddress}${Config.apiMercuryAllCompanyCodeAccess}`, { headers: {"Authorization" : `Bearer ${await getToken()}`}})
          .then((response) => {
            const result = response.data;
            if (result.hasMercuryGAAPandAllCompanyAccess) {
                setHasAccess(true);
            } else {
                setHasAccess(false);
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          }); 
         
        })();
      }, []); 

    return (
        <>
            { hasAccess ? <MainLayout /> : <MercuryAccessLayout /> }
        </>
    )
}

export default Layout