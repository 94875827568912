import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ValidationError({ ...props }) {

    return (
        <Modal {...props} onHide={props.handleModalHide} size="sm">
            <Modal.Body>
                <p>Please select the required filters.</p>
                <div className="d-flex justify-content-end">
                    <Button className='btn btn-dark btn-sm px-3' onClick={props.handleModalHide}>Ok</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ValidationError;