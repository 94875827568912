import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "../../Redux/Reducers/types";
import {
  setSelectedSAPCompanyCodes,
} from "../../Redux/Actions/customMultiSelectActions";
import {
  setSelectedFiscalMonths,
  setSelectedCurrencyTypes,
  setSelectedDisplayOptions,
} from "../../Redux/Actions/singleSelectActions";
import MultiSelectAll from "../Filter/MultiSelectAll";
import SingleSelectControl from "./SingleSelect";
import SpinnerComponent from "../Common/SpinnerComponent";
import { Modal, ModalBody } from "react-bootstrap";

interface Option {
  value: string;
  label: string;
}

const CustomMultiSelect = (props: {
  showFieldsFlag?: boolean;
  handleShowFieldsFlag: (flag: any) => void;
}) => {
  const dispatch = useDispatch();

  const {
    sapCompanyCodes,
    selectedSAPCompanyCodes,
   
  } = useSelector((state: RootReducer) => state.customMultiSelect);
  const {
    fiscalMonths,
    selectedFiscalMonths,
    defaultFiscalMonth,
    currencyTypes,
    selectedCurrencyTypes,
    defaultCurrencyType,
    displayOptions,
    selectedDisplayOptions,
    defaultDisplayOption,
  } = useSelector((state: RootReducer) => state.singleSelect);

  const { selectedFilterPreference } = useSelector(
    (state: RootReducer) => state.preferences
  );
  const { classes } = useSelector(
    (state: RootReducer) => state.cascadingDropDown
  );
  const didMount = useRef(false);
  const [loading, setLoading] = useState(false); // Loading state
  const [maxCompaniesSelected, setMaxCompaniesSelected]=useState(false)
  const [isFocused, setFocused] = useState(false);
  const [selOptions, setSelOptions]=useState(0);


  useEffect(() => {
    if (selectedFilterPreference.length > 0) {
      setMaxCompaniesSelected(false);      
      props.handleShowFieldsFlag(true);
    }
  }, [selectedFilterPreference]);  
  
  const onSelectAllChange = (
    event: any,
    selectedOptions: Option[],
    group: string
  ) => {
    const { checked } = event.target;

    switch (group) {
      case "sapCompanyCodes":
        dispatch(setSelectedSAPCompanyCodes([]));
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (selectedFilterPreference.length === 0 && fiscalMonths.length === 0) {
      props.handleShowFieldsFlag(false);
    }
    if (props.showFieldsFlag && didMount.current) {
      props.handleShowFieldsFlag(false);
    } else didMount.current = true;
  }, [selectedSAPCompanyCodes]);  

 

  const handleBlur = () => {
    setFocused(false);

  };
  const onMenuToggle=(isOpen:any)=>{
    if(isOpen===true){
      setFocused(true)
    }
   if(isOpen===false){
    setFocused(false);
   }
  }

  return (
    <div>
      {loading ? (
        <SpinnerComponent text="Loading..." />
      ) : (
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <SingleSelectControl
              label="Month"
              options={fiscalMonths}
              selectedValue={selectedFiscalMonths}
              onChange={(selectedValue: Option[]) => {
                dispatch(setSelectedFiscalMonths(selectedValue));
              }}
              defaultValue={defaultFiscalMonth}
              required={true}
            />
          </div>

          <div className="col-md-6 col-lg-4"
         
           style={{maxHeight:'100px'}}
           >
          <MultiSelectAll
  options={sapCompanyCodes}
  selectedOptions={selectedSAPCompanyCodes}
  initialSelectedOptions={selectedSAPCompanyCodes}
  onSelectAllChange={onSelectAllChange}
  onChange={(selectedOptions) => {
    dispatch(setSelectedSAPCompanyCodes(selectedOptions));
    setSelOptions(selectedOptions.length)
    if (selectedOptions.length <= 10) {
      setMaxCompaniesSelected(false);

    } else {
      setMaxCompaniesSelected(true);
  
         }
  }}
  label="Company Code"
  group={"sapCompanyCodes"}
  onMenuToggle={(
    isOpen: boolean,
  ) =>
    onMenuToggle(isOpen)
  }  
/>
{(selectedSAPCompanyCodes.length>0 && isFocused) && (
        <div className={!maxCompaniesSelected?"message":"message-error"}>
         {!maxCompaniesSelected?"Select up to 10 Company Codes":"Exceeded company code limit"}
        </div>
      )}
      

          </div>          

          <div className="col-md-6 col-lg-4">
            <SingleSelectControl
              label="Currency Type"
              options={currencyTypes}
              selectedValue={selectedCurrencyTypes}
              onChange={(selectedValue: Option[]) =>
                dispatch(setSelectedCurrencyTypes(selectedValue))
              }
              defaultValue={defaultCurrencyType}
              required={true}
            />
          </div>

          <div className="col-md-6 col-lg-4">
            <SingleSelectControl
              label="Data Format"
              options={displayOptions}
              selectedValue={selectedDisplayOptions}
              onChange={(selectedValue: Option[]) =>
                dispatch(setSelectedDisplayOptions(selectedValue))
              }
              defaultValue={defaultDisplayOption}
              required={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomMultiSelect;
