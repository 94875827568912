import axios from "axios";
import { getToken } from "./MsalService";
import Config from "../../Config";

export default class cascadingDropdownServices {
  public static async GetSubClassesData(payload: any) {
    try {
      const response = await axios.post(
        `${Config.apiBaseAddress}${Config.apiSubClass}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await getToken()}`,
          },
        }
      );
      if (response.data) {
        const subclassOption = response.data.map(
          (item: {
            channelSubClassId: { toString: () => any };
            channelSubClassDesc: any;
          }) => ({
            value: item.channelSubClassId.toString(),
            label: item.channelSubClassDesc,
          })
        );
        return subclassOption;
      } else return [];
    } catch (e) {
      console.log("Error:", e);
      return [];
    }
  }

  public static async GetGroupClassesData(payload: any) {
    try {
      const response = await axios.post(
        `${Config.apiBaseAddress}${Config.apiGroupClass}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await getToken()}`,
          },
        }
      );
      if (response.data) {
        const groupclassOption = response.data.map(
          (item: {
            channelGroupClassId: { toString: () => any };
            channelGroupClassDesc: any;
          }) => ({
            value: item.channelGroupClassId.toString(),
            label: item.channelGroupClassDesc,
          })
        );
        return groupclassOption;
      } else return [];
    } catch (e) {
      console.log("Error:", e);
      return [];
    }
  }

  public static async GetLineItemsData(payload: any) {
    try {
      const response = await axios.post(
        `${Config.apiBaseAddress}${Config.apiLineItem}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await getToken()}`,
          },
        }
      );
      if (response.data) {
        const lineItemOption = response.data.map(
          (item: {
            channelLineItemId: { toString: () => any };
            channelLineItemDesc: any;
          }) => ({
            value: item.channelLineItemId.toString(),
            label: item.channelLineItemDesc,
          })
        );
        return lineItemOption;
      } else return [];
    } catch (e) {
      console.log("Error:", e);
      return [];
    }
  }

  public static async GetFinAccountsData(payload: any) {
    try {
      const response = await axios.post(
        `${Config.apiBaseAddress}${Config.apiFinacialAccount}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await getToken()}`,
          },
        }
      );
      if (response.data) {
        const finaccountOption = response.data.map(
          (item: {
            financialAccountId: { toString: () => any };
            financialAccountCodeDesc: any;
          }) => ({
            value: item.financialAccountId.toString(),
            label: item.financialAccountCodeDesc,
          })
        );

        return finaccountOption;
      } else return [];
    } catch (e) {
      console.log("Error:", e);
      return [];
    }
  }

  public static async GetOrderData(payload: any) {
    try {
      const response = await axios.post(
        `${Config.apiBaseAddress}${Config.apiOrder}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await getToken()}`,
          },
        }
      );
      if (response.data) {
        const orderOption = response.data.map(
          (item: {
            internalOrderID: { toString: () => any };
            internalOrderDesc: any;
          }) => ({
            value: item.internalOrderID.toString(),
            label: item.internalOrderDesc,
          })
        );

        return orderOption;
      } else return [];
    } catch (e) {
      console.log("Error:", e);
      return [];
    }
  }

  public static async GetProfitCenterData(payload: any) {
    try {
      const response = await axios.post(
        `${Config.apiBaseAddress}${Config.apiProfitCenter}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await getToken()}`,
          },
        }
      );
      if (response.data) {
        const profitcenterOption = response.data.map(
          (item: {
            profitCenterID: { toString: () => any };
            profitCenterCodeDesc: any;
          }) => ({
            value: item.profitCenterID.toString(),
            label: item.profitCenterCodeDesc,
          })
        );

        return profitcenterOption;
      } else return [];
    } catch (e) {
      console.log("Error:", e);
      return [];
    }
  }

  public static async GetCostCenterData(payload: any) {
    try {
      const response = await axios.post(
        `${Config.apiBaseAddress}${Config.apiCostCenter}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await getToken()}`,
          },
        }
      );
      if (response.data) {
        const costcenterOption = response.data.map(
          (item: {
            costCenterId: { toString: () => any };
            costCenterDesc: any;
          }) => ({
            value: item.costCenterId.toString(),
            label: item.costCenterDesc,
          })
        );
        return costcenterOption;
      } else return [];
    } catch (e) {
      console.log("Error:", e);
      return [];
    }
  }

  public static async GetOrgData(payload: any) {
    try {
      const response = await axios.post(
        `${Config.apiBaseAddress}${Config.apiOrg}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await getToken()}`,
          },
        }
      );
      if (response.data) {
        const orgOption = response.data.map(
          (item: {
            channelOrgId: { toString: () => any };
            channelOrgDesc: any;
          }) => ({
            value: item.channelOrgId.toString(),
            label: item.channelOrgDesc,
          })
        );
        return orgOption;
      } else return [];
    } catch (e) {
      console.log("Error:", e);
      return [];
    }
  }

  public static async GetOrgSummaryData(payload: any) {
    try {
      const response = await axios.post(
        `${Config.apiBaseAddress}${Config.apiOrgSummary}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await getToken()}`,
          },
        }
      );
      if (response.data) {
        const orgSummaryOption = response.data.map(
          (item: {
            channelOrgSummaryId: { toString: () => any };
            channelOrgSummaryDesc: any;
          }) => ({
            value: item.channelOrgSummaryId.toString(),
            label: item.channelOrgSummaryDesc,
          })
        );
        return orgSummaryOption;
      } else return [];
    } catch (e) {
      console.log("Error:", e);
      return [];
    }
  }
}
