import { getToken } from "../Services/MsalService"; 
import Config from "../../Config";

export const GetToken = async function (api: any) {
  const data = {
    APIEndpoint: api,
  };
  const tokenAPI = `${Config.apiBaseAddress}${Config.tokenUserProfile}`; 
  try { 
    let result = await fetch(tokenAPI, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await getToken()}`,
      },
    });

    var tokenDetails = await result.json();
    return tokenDetails.token;
  } catch (error) {
    console.log(error);
  }
};
