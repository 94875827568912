import { SET_PARENT_ID_AND_COMMENTS,CLEAR_COMMENT_DATA } from "../ActionContants/constants";

interface CommentState {
  desc: string | null;
  commentList: any[]; 
  parentID: string | null;
  selectedFiscalMonthID: string | null;
  selectedSAPCompanyID: string | null;
}

const initialState: CommentState = {
  desc: null,
  commentList: [],
  parentID: null,
  selectedFiscalMonthID: null,
  selectedSAPCompanyID: null
};

// Define the action type
interface Action {
  type: string;
  payload?: CommentState;
}

const commentReducer = (state: CommentState = initialState, action: Action): CommentState => {
  switch (action.type) {
    case SET_PARENT_ID_AND_COMMENTS:
      return {
        ...state,
        ...action.payload
      };
    case CLEAR_COMMENT_DATA:
      return {
        ...state,
        desc: null,
        commentList: [],
        parentID: null,
        selectedFiscalMonthID: null,
        selectedSAPCompanyID: null
      };
    default:
      return state;
  }
};

export default commentReducer;