import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import CustomMultiSelect from "../Filter/CustomMultiSelect";
import Preferences from "../Filter/Preferences";
import CascadingDropdown from "../Filter/CascadingDropdown";
import { setExpandAllAction, setGridAction, setGridDataAction, setGridDataActionQtdYtd } from "../../Redux/Actions/qtdDataActions";
import { useSelector, useDispatch } from "react-redux";
import SpinnerComponent from "../Common/SpinnerComponent";
import { FilterValuesValidate, SelectedFilterValues } from "../Common/GetDataFromStore";
import { RootReducer } from "../../Redux/Reducers/types";
import ValidationError from "../Common/ValidationError";
import axios from "axios";
import Config from "../../Config";
import store from "../../Redux/Store/Store";
import {
  setSAPCompanyCodes,
  setSelectedSAPCompanyCodes,
} from "../../Redux/Actions/customMultiSelectActions";
import {
  setFiscalMonths,
  setDefaultFiscalMonths,
  setCurrencyTypes,
  setDefaultCurrencyTypes,
  setDisplayOptions,
  setDefaultDisplayOptions,
} from "../../Redux/Actions/singleSelectActions";
import { getToken } from "../Services/MsalService";
import {
  cascadingDropdownDataFromPrefAction,
  initCascadingDropDowns,
} from "../../Redux/Actions/cascadingDropDownActions";
import { getSelectedFiltersFromPreferences } from "../Common/SupportingFunctions";
import { setShowFieldsFlagAction } from "../../Redux/Actions/includeFilterAction";
import {
  setSelectedSortItems,
  setSortData,
} from "../../Redux/Actions/fieldSortAction";
import {
  setDefaultFilterpreferncesAction,
  setIsPreferenceChangedAction,
} from "../../Redux/Actions/preferencesDataActions";
import { setFilterSpinnerAction } from "../../Redux/Actions/CommonActionTypes";
import {
  toggleInsignificantBalance,
  toggleZeroBalances,
} from "../../Redux/Actions/checkboxActions";
import arrayMove from "array-move";
// import { getPowerbiAccessToken } from "../../Services/PowerbiAccessToken";
import { useCombinedContext } from "../../Pages/PowerBIContext"; 
interface Props {
  handleSlidePanelshow: () => void;
}

const FieldFilter: React.FC<Props> = (Props) => {
  const { handleSlidePanelshow } = Props;
  const didMount = useRef(false);

  const dispatch = useDispatch();
  const cascadingDropDownState = useSelector(
    (state: RootReducer) => state.cascadingDropDown
  );

  const {
    selectedFiscalMonths,
    selectedCurrencyTypes,
    selectedDisplayOptions,
  } = useSelector((state: RootReducer) => state.singleSelect);
  const { selectedSAPCompanyCodes } = useSelector(
    (state: RootReducer) => state.customMultiSelect
  );
  const preferences = useSelector((state: RootReducer) => state.preferences);
  const { sortData, showFieldsFlag } = useSelector(
    (state: RootReducer) => state.fieldSort
  );
  const checkboxState = useSelector((state: RootReducer) => state.checkboxes);
  const handleCheckboxChange = (event: any) => {
    dispatch(toggleInsignificantBalance(event.target.checked));
  };

  const handleExcludeZeroDataCheckboxChange = (event: any) => {
    dispatch(toggleZeroBalances(event.target.checked));
  };

  const [gridLoading, setgridLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showValidationError, setShowValidationError] = useState(false);
  const [clearCascadingFlag, setClearCascadingFlag] = useState(false);
  const { setApplyfilter , setGridloader } = useCombinedContext();
  const store = useSelector(
    (state: RootReducer) => state
  );

  useEffect(() => {
    let initiateStatesFlag =
      cascadingDropDownState.selectedClassesValue.length === 0 &&
      cascadingDropDownState.selectedSubClassesValue.length === 0 &&
      cascadingDropDownState.selectedGroupClassesValue.length === 0 &&
      cascadingDropDownState.selectedLineItemValue.length === 0 &&
      cascadingDropDownState.selectedFinAccountValue.length === 0 &&
      cascadingDropDownState.selectedOrderValue.length === 0 &&
      cascadingDropDownState.selectedProfitCenterValue.length === 0 &&
      cascadingDropDownState.selectedCostCenterValue.length === 0 &&
      cascadingDropDownState.selectedOrgValue.length === 0 &&
      cascadingDropDownState.selectedOrgSummaryValue.length === 0;
    if (!initiateStatesFlag && !showFieldsFlag) {
      handleShowFieldsFlag(true);
    }
    if (
      selectedSAPCompanyCodes.length === 0 &&
      selectedFiscalMonths.length === 0 &&
      selectedCurrencyTypes.length === 0 &&
      selectedDisplayOptions.length === 0 &&
      preferences.selectedFilterPreference.length === 0
    )
      fetchData(false);
  }, []);

  const fetchFiltersData = async () => {
    if (preferences.selectedFilterPreference.length > 0) {
      const previousPreferencevalue =
        Object.keys(preferences.isPreferenceChanged).length == 0
          ? ""
          : preferences.isPreferenceChanged[0].label.toString();
      // if (
      //  ( previousPreferencevalue !=
      //   preferences.selectedFilterPreference[0].value.toString())||(
      //     previousPreferencevalue ==
      //     preferences.selectedFilterPreference[0].value.toString())
      // ) {
      if (previousPreferencevalue !=
        preferences.selectedFilterPreference[0].label.toString()) {
        dispatch(
          setIsPreferenceChangedAction(preferences.selectedFilterPreference)
        );

        setFilterSpinnerRedux(true);
        await dispatchFilterData(preferences.preferenceData, true);
        setFilterSpinnerRedux(false);
      }
      else if (preferences.selectedFilterPreference.length == 0) {
        dispatch(setShowFieldsFlagAction(false));
        fetchData(true);
      }
      else {
        setFilterSpinnerRedux(false);
      }
    }
  };

  useEffect(() => {
    if (preferences.selectedFilterPreference.length > 0) {
      if (!didMount.current) fetchFiltersData();
      else didMount.current = true;
    }
  }, [preferences.selectedFilterPreference]);

  //filterPreferncesList
  const handleShowValidationError = () => {
    setShowValidationError(true);
  };

  const handleModalHide = () => {
    setShowValidationError(false);
  };

  const handleShowFieldsFlag = (flag: boolean) => {
    if (flag !== showFieldsFlag) {
      dispatch(initCascadingDropDowns());
      // // console.log("setShowFieldsFlag===>",showFieldsFlag)
      // // console.log("flag==>",flag)
      dispatch(setShowFieldsFlagAction(flag));
      // setShowFieldsFlag(true);
    } else {
      // dispatch(setShowFieldsFlagAction(flag))
      // setShowFieldsFlag(flag);
    }
  };

  const handleShowFieldsBtn = (flag: boolean) => {
    if (
      selectedFiscalMonths.length === 0 ||
      selectedCurrencyTypes.length === 0 ||
      selectedSAPCompanyCodes.length === 0 ||
      selectedDisplayOptions.length === 0
    ) {
      handleShowValidationError();
      dispatch(setShowFieldsFlagAction(false));
      // setShowFieldsFlag(false);
    } else {
      if (handleCascadingData()) {
        setError("");
        dispatch(setShowFieldsFlagAction(flag));
        // setShowFieldsFlag(flag);
      } else {
        setError("");
        dispatch(setShowFieldsFlagAction(flag));
        //setShowFieldsFlag(flag);
      }
    }
  };

  const handleCascadingData = () => {
    if (
      cascadingDropDownState.selectedClassesValue.length === 0 ||
      cascadingDropDownState.selectedSubClassesValue.length === 0 ||
      cascadingDropDownState.selectedGroupClassesValue.length === 0 ||
      cascadingDropDownState.selectedLineItemValue.length === 0 ||
      cascadingDropDownState.selectedFinAccountValue.length === 0 ||
      cascadingDropDownState.selectedOrderValue.length === 0 ||
      cascadingDropDownState.selectedProfitCenterValue.length === 0 ||
      cascadingDropDownState.selectedCostCenterValue.length === 0 ||
      cascadingDropDownState.selectedOrgValue.length === 0 ||
      cascadingDropDownState.selectedOrgSummaryValue.length === 0
    ) {
      return false; // Return false dropdown not selected
    } else {
      return true; // Return true dropdown selected
    }
  };





  const handleapplyclick = () => {
    // const cascadingDropdownSelected = handleCascadingData();
    const cascadingDropdownSelected = FilterValuesValidate();

    if (!cascadingDropdownSelected) {
      handleShowValidationError();
      setFilterSpinnerRedux(false);
      return;
    }
    setError("");
    setFilterSpinnerRedux(true);
    console.log("On Apply button=>" + SelectedFilterValues());
    GetGridData(SelectedFilterValues());
    setApplyfilter(true);
    setGridloader(false);
  };


  const GetGridData = async (gridparametrs: any) => {
    const startDatTime = new Date();
    saveUserLog(startDatTime);
    gridparametrs.browserTabUniqueId = sessionStorage.getItem('BrowserTabUniqueId');
    dispatch(setDefaultFilterpreferncesAction(null));
    dispatch(await setGridAction([]));
    dispatch(setExpandAllAction(false));
    if (gridparametrs.selectedType !== "QTD/YTD") {
      dispatch(await setGridDataAction(gridparametrs));
    } else {
      dispatch(await setGridDataActionQtdYtd(gridparametrs));
    };

    setFilterSpinnerRedux(false);

  };

  const handlecancelclick = () => {
    handleSlidePanelshow();
    setApplyfilter(false);
  };

  const fetchData = async (loader: any) => {
    setFilterSpinnerRedux(true);
    try {
      const response: any = await axios.get(
        `${Config.apiBaseAddress}${Config.apiGetFilter}`,
        { headers: { Authorization: `Bearer ${await getToken()}` } }
      );
      setFilterSpinnerRedux(false);
      const dropdownData = response.data;
      await dispatchFilterData(dropdownData, false);
      !loader && setFilterSpinnerRedux(false);
    } catch (error) {
      //// console.log("Error fetching dropdown data: ", error);
      //setLoading(false);
    }
  };

  const dispatchFilterData = async (
    data: any,
    isPreferenceSelected: boolean
  ) => {
    if (data) {
      // setLoading(true);
      let selectedPreferenceData = [];
      if (isPreferenceSelected) {
        selectedPreferenceData = data.preference.filter(
          (e: any) => e.value === preferences.selectedFilterPreference[0].value
        );
      }
      const fiscalMonthOptions = data.fiscalmonth.map((fiscalMonth: any) => ({
        value: fiscalMonth.fiscalMonthID.toString(),
        label: fiscalMonth.fiscalMonth,
      }));
      dispatch(setFiscalMonths(fiscalMonthOptions));
      dispatch(setDefaultFiscalMonths([fiscalMonthOptions[0]]));

      const currencyTypeOptions = data.currencyType.map(
        (currencyType: any) => ({
          value: currencyType.currencyTypeHierarchyID.toString(),
          label: currencyType.currencyTypeHierarchyName,
        })
      );
      dispatch(setCurrencyTypes(currencyTypeOptions)); //filter((e:any)=>e.currencyTypeHierarchyID===            )
      const selectedCurrency = isPreferenceSelected
        ? getSelectedFiltersFromPreferences(
          selectedPreferenceData,
          "currencyType",
          "selectedCurrencyType",
          "currencyTypeHierarchyID",
          "currencyTypeHierarchyName"
        )[0] || []
        : currencyTypeOptions[0];
      const selectedCurrencyType = {
        ...selectedCurrency,
        value: selectedCurrency.value.toString()
      }

      //// console.log("selectedCurrencyType==>",selectedCurrencyType)
      dispatch(setDefaultCurrencyTypes([selectedCurrencyType]));

      const sapCompanyCodeOptions = data.sAPCompanyCode.map(
        (sapCompanyCode: any) => ({
          value: sapCompanyCode.companyCode,
          label: sapCompanyCode.sapCompanyCodeName,
        })
      );
      dispatch(setSAPCompanyCodes(sapCompanyCodeOptions));
      const selectedSapCompanyCodeOptions = isPreferenceSelected
        ? getSelectedFiltersFromPreferences(
          selectedPreferenceData,
          "sAPCompanyCode",
          "selectedCompanies",
          "companyCode",
          "sapCompanyCodeName"
        ) : selectedSAPCompanyCodes
      dispatch(setSelectedSAPCompanyCodes(selectedSapCompanyCodeOptions));


      //  dispatch(toggleInsignificantBalance(selectedPreferenceData[0].excludeDataWithInsignificantBalance));
      //  dispatch(toggleZeroBalances(selectedPreferenceData[0].excludeZeroBalances));

      const displayOptions = data.displayIn.map((display: any) => ({
        value: display.id.toString(),
        label: display.rounddesc,
      }));
      dispatch(setDisplayOptions(displayOptions));
      const displayOption = isPreferenceSelected
        ? getSelectedFiltersFromPreferences(
          selectedPreferenceData,
          "displayIn",
          "formatID",
          "id",
          "rounddesc"
        )[0] || []
        : displayOptions[0];
      console.log("selectedDisplayOption==>", displayOptions)

      const selectedDisplayOption = {
        ...displayOption,
        value: displayOption.value.toString()
      }
      dispatch(setDefaultDisplayOptions([selectedDisplayOption]));

      console.log('selected preferences data2', selectedPreferenceData)
      dispatch(toggleInsignificantBalance(selectedPreferenceData[0].excludeDataWithInsignificantBalance));
      dispatch(toggleZeroBalances(selectedPreferenceData[0].excludeZeroBalances));


      if (isPreferenceSelected) {
        const payload = {
          selectedPreferenceData: selectedPreferenceData,
          selectedFiscalMonth: fiscalMonthOptions[0],
          selectedCompanies: selectedSapCompanyCodeOptions,
          selectedCurrencyType: selectedCurrencyType,
          excludeDataWithInsignificantBalance: selectedPreferenceData[0].excludeDataWithInsignificantBalance,
          excludeZeroBalances: selectedPreferenceData[0].excludeZeroBalances,
          selectedDisplayIn: selectedDisplayOption,
          classes: data.channelClass.map((e: any) => {
            return {
              value: e.channelClassId,
              label: e.channelClassDesc,
            };
          }),
        };

        dispatch(await cascadingDropdownDataFromPrefAction(payload));
        setClearCascadingFlag(true);
        // setLoading(false);
        dispatch(setShowFieldsFlagAction(true));
        // console.log("selectedPreferenceData==>",selectedPreferenceData)
        const selectedSortOptions =
          selectedPreferenceData[0].sortOption != ""
            ? selectedPreferenceData[0].sortOption.split(",")
            : [];
        //console.log("selectedSortOptions:",selectedSortOptions)
        if (selectedSortOptions.length > 0) {
          let selectedItems = sortData
            .filter((e: any) => selectedSortOptions.indexOf(e.columnName) != -1)
            .map((e: any) => {
              return {
                ...e,
                checked: true,
              };
            });
          let updatedSortData = sortData.map((e: any) => {
            let checkedFlag = false;
            checkedFlag =
              selectedItems.filter(
                (a: any) => a.columnName === e.columnName
              )?.[0]?.checked || false;
            return {
              ...e,
              checked: checkedFlag,
            };
          });



          selectedSortOptions.map((e: any, index: number) => {
            let oldIndex = updatedSortData.findIndex((obj: any) => obj.columnName === e);
            let newIndex = index;
            (newIndex != oldIndex) &&
              (updatedSortData = arrayMove(updatedSortData, oldIndex, newIndex));
          })



          console.log("selectedItems==>", selectedItems);
          dispatch(setSelectedSortItems(selectedItems));
          dispatch(setSortData(updatedSortData));
        } else {
          let intialSortData = sortData.map((e: any) => {
            return {
              ...e,
              checked: false,
            };
          });
          dispatch(setSelectedSortItems([]));
          dispatch(setSortData(intialSortData));
        }
      }
    }
  };

  const setFilterSpinnerRedux = async (val: boolean) => {
    dispatch(await setFilterSpinnerAction(val));
  };


  const saveUserLog = async (startTime: any) => {
    try {

      const endTime = new Date();
      const startTimeDate = new Date(startTime);
      const month = store.singleSelect.selectedFiscalMonths?.[0]?.label || 'DefaultMonth';
      const currencyType = store.singleSelect.selectedCurrencyTypes?.[0]?.label || 'CurrencyTypes';
      const value = store.customMultiSelect.selectedSAPCompanyCodes.length > 0 ? store.customMultiSelect.selectedSAPCompanyCodes.map((e: any) => e.label).join(",") : "";
      const excludeInsignificantBalance = store.checkboxes.excludeInsignificantBalance;
      const excludeZeroBalances = store.checkboxes.excludeZeroBalances;
      const sAPCompanyCode = value != null ? value.toString() : '';
      const roundingType = store.singleSelect.selectedDisplayOptions?.[0]?.label.trim();
      const reportType = store.gridData.gridType.payload;
      let refreshTime = (endTime.getTime() - startTimeDate.getTime()) / 1000;
      if (refreshTime < 1000) {
        refreshTime = 1000;
      }
      const mercuryRefreshTimeInSeconds = Math.ceil(refreshTime / 1000);

      const refreshTimeInSeconds = mercuryRefreshTimeInSeconds.toString();


      const UserLogparam = {
        selectedFiscalMonth: month,
        selectedCurrencyType: currencyType,
        SelectedCompanies: sAPCompanyCode,
        ExcludeDataWithInsignificantBalance: excludeInsignificantBalance,
        ExcludeZeroBalances: excludeZeroBalances,
        refreshTime: refreshTimeInSeconds,
        roundingType: roundingType,
        reportType: reportType,
      };


      const token = await getToken();
      const response = await axios.post(
        `${Config.apiBaseAddress}${Config.apiCreateLogFile}`,
        UserLogparam,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("saveUserLog after Apply", response);

    } catch (error) {
      console.error('Error config:');
    }
  };




  return (
    <div className="field-filters">
      <Form>
        <p>* indicates a required field.</p>
        <Preferences
          handleShowFieldsFlag={(flag: any) => handleShowFieldsFlag(flag)}
        />

        <CustomMultiSelect
          showFieldsFlag={showFieldsFlag}
          handleShowFieldsFlag={(flag: any) => handleShowFieldsFlag(flag)}
        />
        <div className="row">
          <div className="col-12 d-flex justify-content-end">
            <Button
              variant="dark"
              type="button"
              className="btn-sm"
              onClick={() => handleShowFieldsBtn(true)}
              disabled={selectedSAPCompanyCodes.length <= 10 ? false : true}
            >
              Show Fields
            </Button>
          </div>
        </div>
        {error && <p className="error-message">{error}</p>}

        <CascadingDropdown
          showFieldsFlag={showFieldsFlag}
          handleShowFieldsFlag={(flag: any) => handleShowFieldsFlag(flag)}
          clearCascadingFlag={clearCascadingFlag}
        />

        <div className="mt-1 mb-3">
          <Form.Check
            inline
            label="Exclude data with insignificant balance"
            type="checkbox"
            checked={checkboxState.excludeInsignificantBalance}
            onChange={handleCheckboxChange}
            aria-label="Exclude data with insignificant balance"
            id={"Exclude data with insignificant balance"}
          />
          <Form.Check
            inline
            label="Exclude zero balances"
            type="checkbox"
            checked={checkboxState.excludeZeroBalances}
            onChange={handleExcludeZeroDataCheckboxChange}
            aria-label="Exclude zero balances"
            id={"Exclude zero balances"}
          />
        </div>
        {gridLoading && (
          <div>
            <SpinnerComponent text="Loading..."></SpinnerComponent>
          </div>
        )}
        <ValidationError
          show={showValidationError}
          handleModalHide={handleModalHide}
        />
      </Form>
      {showFieldsFlag && (
        <div className="slide-panel-footer">
          <div className="col-12 d-flex justify-content-end mt-auto">
            <Button
              variant="dark"
              type="button"
              className="btn-sm me-2"
              onClick={handleapplyclick}
            >
              Apply
            </Button>
            <Button
              variant="dark"
              type="button"
              className="btn-sm"
              onClick={handlecancelclick}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FieldFilter;
