
import React, { memo, useMemo } from "react";
import { useState, useEffect, useReducer } from "react";
import { RowColumn } from "../RowColumn/RowColumn";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "../../Redux/Reducers/types";
import Columns from "../Columns";
import { FilterValuesValidate, SelectedFilterValues } from "../Common/GetDataFromStore";
import {
  setExpandCollapseAction,
  setTDTypeAction,
} from "../../Redux/Actions/qtdDataActions";
import {
  setEmptyGridDataAction,
  setExpandAllAction,
  setGridAction,
  setGridDataAction,
  setGridDataActionQtdYtd
} from "../../Redux/Actions/qtdDataActions";
import {
  setGlobalSpinnerAction,
  setDefaultPreferenceStatusAction,
} from "../../Redux/Actions/CommonActionTypes";
import { getDefaultPrefenceValue } from "../../Services/Service";
import { setDefaultFilterpreferncesAction } from "../../Redux/Actions/preferencesDataActions";
import { setDefaultDisplayOptions, setDefaultCurrencyTypes } from "../../Redux/Actions/singleSelectActions";
import { INTIAL_STATE, GridReducer } from './GridReducer';
import { ACTION_TYPES } from "./GridActionTypes";
import axios from "axios";
import Config from "../../Config";
import { getToken } from "../Services/MsalService";
import { TableVirtuoso } from 'react-virtuoso'
import { setCanEnterAction, setCanViewAction } from "../../Redux/Actions/fieldSortAction";
import { useCombinedContext } from "../../Pages/PowerBIContext";

const TIMEOUT_DURATION = 1660000;
const apiClient = axios.create({
  baseURL: Config.apiBaseAddress,
  timeout: TIMEOUT_DURATION
})

interface Props {
  handlePanalClose: () => void;
  todate?: any;
  classValue?: any;
}
const GridComponent: React.FC<Props> = ({ handlePanalClose, todate, classValue }) => {
  //const selectedColumnsFromRedux = useSelector((state: any) => state.selectedCol);
  //const selectedGridHeaders = useSelector((state: any) => state.selectedGridHeader);
  const selectedGridHeaders = useSelector((state: RootReducer) => state.filteredGridColHeader);

  const dispatch = useDispatch();
  //const { handlePanalClose, todate, classValue } = Props;

  const [state, setstate] = useReducer(GridReducer, INTIAL_STATE);
  const [gDatastate, setgDatastate] = useState<any>([]);
  const [gHeight, setgHeight] = useState<number>(window.innerHeight);

  const Data = useSelector((state: RootReducer) => state);
  const { ExpColStatus } = useSelector((state: RootReducer) => state.gridData);
  const { apiRes } = useSelector(
    (state: RootReducer) => state.gridData
  );
  const {setGridloader} = useCombinedContext();
  const [activeButton, setActiveButton] = useState(false);
  const { ExpandAllStatus } = useSelector(
    (state: RootReducer) => state.gridData
  );
  const [errorStatus, setErrorStatus] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    'desc', 'actuals', 'pyActuals', 'dollerVar', 'percentageVar', 'cpH', 'pyCpH', 'cpHVar', 'cpHPercentage'
  ]);
  useEffect(() => {
    setSelectedColumns(selectedGridHeaders.ColHeaders);
  }, [selectedGridHeaders]);
  useEffect(() => {
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  });

  useEffect(() => {

    setGlobalSpinner(true);
    dispatch(setEmptyGridDataAction());
    setErrorStatus(false)
    setstate({
      type: ACTION_TYPES.SET_CORGRID, payload: {
        Data: [],
        ColHeaders: {},
        errorMSG: "Please apply filters to load data."
      }
    });
    console.log("localstate => ", state);

    fetchDefaultValues(Data.commonStore.DefaultPrefrenceStatus);
  }, []);
  useEffect(() => {


    var tableobj = document.querySelectorAll('table')[0];

    if (tableobj != null && tableobj != undefined) {
      tableobj.setAttribute("style", `border-spacing: 0px; overflow-anchor: none;width:100%`);
    }


    var tfootobj = document.querySelectorAll('tfoot')[0];

    if (tfootobj != null && tfootobj != undefined) {
      tfootobj.setAttribute("style", `z-index: 1;position: sticky;bottom: 0; padding-top: 10px`);
    }


    // if (targetDiv != null && targetDiv != undefined) {
    //     // Find the first table element inside the target div
    //     var table = targetDiv.querySelector('table');

    //     // Apply styles only if the table is found
    //     if (table) {
    //         //const width1 = selectedColumns.length > 5 ? "max-content" : "100%";
    //         table.style.width = width1;
    //     }
    // }

    //   var obj  = document.getElementsByClassName("c-row");
    //   document.querySelectorAll('[data-foo="value"]')

    //   if(  obj != null && obj[0] != null){
    //  // obj[0].nextElementSibling?.removeAttribute("style");
    //   //obj[0].nextElementSibling?.setAttribute("style","position:relative;height:100%");

    //     debugger;
    //    var targetdiv =  document.querySelectorAll('[data-viewport-type="element"]')[0];
    //     targetdiv.setAttribute("style","width:max-content;height:100%;position:absolute;top:0px");
    //     //
    //   //  obj[0].nextElementSibling?.childNodes[0].ATTRIBUTE_NODE ("style","position:relative");

    //   }
  })

  useEffect(() => {


    if (apiRes == 500) {
      setstate({
        type: ACTION_TYPES.SET_CORGRID, payload: {
          Data: [],
          ColHeaders: {},
          errorMSG: "A problem has occurred while retrieving report data.  Please refresh the page to reload the report data."
        }
      });
      setgDatastate([]);
      setErrorStatus(true)
    }
    else if ((apiRes === 200 && (Data.gridData.gridData.actualsClasses == null && Data.gridData.gridData.actualsClasses?.length == 0))) {
      setstate({
        type: ACTION_TYPES.SET_CORGRID, payload: {
          Data: [],
          ColHeaders: {},
          errorMSG: "No records to display."
        }
      });
      setgDatastate([]);

      setErrorStatus(false)

    }
    else if (Data.gridData.gridData.actualsClasses != null && Data.gridData.gridData.actualsClasses.length > 0) {

      Data.gridData.gridData.actualsClasses.map((item: any) => {
        //let Parentids = getparentids(item.parentId);
        // item.visiable = item.groupType == "SubClass" ? true:false;
        //item.isChilderen = item.desc == "Grand Total" ? false : Data.gridData.gridData.actualsClasses.filter((i: any) => i.parentId == item.id).length > 0 ? true : false;
        // item.parentIds = Parentids;
        // item.sortParentIds = Parentids;
      });

      setstate({
        type: ACTION_TYPES.SET_CORGRID, payload: {
          Data: [...Data.gridData.gridData.actualsClasses],
          ColHeaders: Data.gridData.gridData.gridColumnHeaders,
          //ColHeaders: Data.filteredGridColHeader.ColHeaders,
          errorMSG: ""
        }
      });
      debugger
      setgDatastate([...Data.gridData.gridData.actualsClasses.filter((e: any) => e.visiable == true)]);
      dispatch(setCanViewAction(Data.gridData.gridData.canView));
      dispatch(setCanEnterAction(Data.gridData.gridData.canEnter));
      setErrorStatus(false);
      //setActiveButton(false);
    }
    else {
      setstate({
        type: ACTION_TYPES.SET_CORGRID, payload: {
          Data: [],
          ColHeaders: {},
          errorMSG: "Please apply filters to load data."
        }
      });
      setgDatastate([]);

      setErrorStatus(false)

    }
    apiRes !== undefined &&
      handlePanalClose();

  }, [Data.gridData.gridData]
    //[Data.filteredGridColHeader.ColHeaders]
  );
  useEffect(() => {
    setstate({ type: ACTION_TYPES.SET_NUMBER, payload: Data.singleSelect.selectedDisplayOptions[0]?.value == "1" ? 1000000 : 1000 })
  }, [Data.singleSelect.selectedDisplayOptions]);
  useEffect(() => {


    if (state.data.length > 0) {
      ExpndColpsAll(ExpColStatus.payload != "Expand" ? false : true);
      // ExpColStatus.payload != "Expand"  && ExpndColpsAll(false);
      // ExpColStatus.payload == "Expand"  && GetAllClassValues();
    }
  }, [ExpColStatus]);

  useEffect(() => {
    dispatch(setExpandAllAction(false));
    //console.log("gridData?.gridDataValues?.length==>",gridData)
  }, []);

  useEffect(() => {
    setActiveButton(ExpandAllStatus);
  }, [ExpandAllStatus]);
  useEffect(() => {
    console.log("RowColumn received new selectedColumns: in GridComponent", selectedColumns);
  }, [selectedColumns]);

  const GetAllClassValues = async () => {

    const Token = await getToken();
    let payload = { ...Data.preferences.intialdefaultFilterPreference };
    if (payload == null || (payload != null && Object.entries(payload).length == 0)) {
      let selectedValues = { ...SelectedFilterValues() };
      selectedValues.selectedType = todate;
      payload = Object.entries(selectedValues).filter((x) => x[0] != "selectedSortOptions" && x[0] != "chkExcludeData" && x[0] != "chkExcludeZeroData" && x[0] != "isDefault" && (x[1] == "" || x[1] == undefined)).length == 0 ? selectedValues : null;
    }

    if (payload == null)
      return;
    const colapsedclasses: any[] = [];
    const selectedclasses = state.data.filter((e: any) => e.parentIds == null && e.id != '0').map((item: any) =>
      item.id);

    selectedclasses.filter((item: any) => {
      (state.data.filter((e: any) => e.parentIds == item).length == 0) &&
        colapsedclasses.push(item)
    })

    const axiosapilst: any[] = [];
    colapsedclasses.map((item: any) => {
      payload.selectedClass = item;
      axiosapilst.push(apiClient.post(`${Config.apiBaseAddress}${Config.apiGetSegmentalData}`,
        payload, { headers: { "Authorization": `Bearer ${Token}` } }));
    })

    if (axiosapilst.length == 0) {
      ExpndColpsAll(true); return;
    }
    setGlobalSpinner(true);
    axios.all(axiosapilst).then(
      axios.spread((...alldata) => {
        console.log("alldata ==> ", alldata);
        const lst = [];
        let k = 0;
        for (var i = 0; i < state.data.length; i++) {
          if (state.data[i].parentId != null && state.data[i].parentId != "0")
            state.data[i].visiable = true;
          state.data[i].exapnded = true;
          lst.push(state.data[i]);
          let filtereddata: any = []
          if (alldata[k] != null && alldata[k].data.filter((x: any) => x.id == state.data[i].id).length > 0) {
            filtereddata = alldata[k].data.filter((x: any) => x.parentId != '');
            for (var j = 0; j < filtereddata.length; j++) {
              let Parentids = getparentids(filtereddata[j].parentId);
              console.log(filtereddata[j]);
              filtereddata[j].visiable = true;
              filtereddata[j].exapnded = true;
              filtereddata[j].isChilderen = alldata[k].data.filter((i: any) => i.parentId == filtereddata[j].id).length > 0 ? true : false;
              filtereddata[j].parentIds = Parentids;
              filtereddata[j].sortParentIds = Parentids;
              lst.push(filtereddata[j]);
            }
            k++;
          }
        }
        //debugger

        dispatch(setGridAction({ actualsClasses: lst, gridColumnHeaders: Data.gridData.gridData.gridColumnHeaders }));
        dispatch(setCanViewAction(Data.gridData.gridData.canView));
        dispatch(setCanEnterAction(Data.gridData.gridData.canEnter));
        console.log("lst =>", lst);
        setGlobalSpinner(false);
      })
    )
  };
  const ExpndColpsAll = (isexpanded: boolean) => {
    state.data.map((e: any) => {
      if (e.parentId != null && e.parentId != "0") {
        e.visiable = isexpanded;
      }
      e.exapnded = isexpanded;
    });
    setstate({
      type: ACTION_TYPES.SET_CORGRID, payload: {
        Data: [...state.data],
        ColHeaders: Data.gridData.gridData.gridColumnHeaders,
        errorMSG: ""
      }
    })
    setgDatastate([...state.data.filter((e: any) => e.visiable == true)]);
    setErrorStatus(false)

  }
  const updateHeight = () => {
    setstate({ type: ACTION_TYPES.SET_HEIGHT, payload: window.innerHeight });
    setgHeight(window.innerHeight);

    console.log(Data);
  };
  const fetchDefaultValues = async (Reload: any) => {
    let selectedValues = SelectedFilterValues();
    const allFilterSelected = FilterValuesValidate();
    const selectedValuescount = Object.entries(selectedValues).length;
    // .filter(
    //   (x) =>
    //     x[0] != "selectedSortOptions" &&
    //     x[0] != "chkExcludeData" &&
    //     x[0] != "chkExcludeZeroData" &&
    //     x[0] != "isDefault" &&
    //     (x[1] == "" || x[1] == undefined)
    // ).length;
    //if (selectedValuescount == 0) {

    //}
    if (allFilterSelected) {
      selectedValues.selectedType = todate;
      GetGridData(selectedValues);
    }
    else {
      const response = Reload
        ? Data.preferences.intialdefaultFilterPreference
        : await getDefaultPrefenceValue();
      !Reload && dispatch(setDefaultFilterpreferncesAction(response));
      !Reload && setDefaultPreferenceStatusStore(true);

      if (response != null && response.selectedClass != null) {
        response.selectedType = todate;
        dispatch(
          setDefaultDisplayOptions([
            {
              value: response.formatNbr,
              label: response.formatNbr == "1" ? "Million" : "Thousand",
            },
          ])
        );
        response.selectedType = todate;
        dispatch(
          setDefaultCurrencyTypes([
            {
              value: response.selectedCurrencyType,
              label: response.selectedCurrencyType == 3 ? "Corporate (USD)" : "Local Statutory",
            },
          ])
        );
        GetGridData(response);
      } else {
        setstate({
          type: ACTION_TYPES.SET_CORGRID, payload: {
            Data: [],
            ColHeaders: {},
            errorMSG: "Please apply filters to load data."
          }
        })
        setErrorStatus(false)

        dispatch(setEmptyGridDataAction());
        setGlobalSpinner(false);
      }
    }
  };

  const GetGridData = async (gridparametrs: any) => {
    const startDatTime = new Date();
    saveUserLog(startDatTime);
    if (gridparametrs.selectedType !== "QTD/YTD") {
      dispatch(await setGridDataAction(gridparametrs));
    } else {
      dispatch(await setGridDataActionQtdYtd(gridparametrs));
    };
    setGlobalSpinner(false);
    setGridloader(false);
  };
  const store = useSelector(
    (state: RootReducer) => state
  );

  const saveUserLog = async (startTime: any) => {
    try {

      const endTime = new Date();
      const startTimeDate = new Date(startTime);
      const month = store.singleSelect.selectedFiscalMonths?.[0]?.label || 'DefaultMonth';
      const currencyType = store.singleSelect.selectedCurrencyTypes?.[0]?.label || 'CurrencyTypes';
      const value = store.customMultiSelect.selectedSAPCompanyCodes.length > 0 ? store.customMultiSelect.selectedSAPCompanyCodes.map((e: any) => e.label).join(",") : "";
      const excludeInsignificantBalance = store.checkboxes.excludeInsignificantBalance;
      const excludeZeroBalances = store.checkboxes.excludeZeroBalances;
      const sAPCompanyCode = value != null ? value.toString() : '';
      const roundingType = store.singleSelect.selectedDisplayOptions?.[0]?.label.trim();
      const reportType = todate;
      let refreshTime = (endTime.getTime() - startTimeDate.getTime()) / 1000;
      if (refreshTime < 1000) {
        refreshTime = 1000;
      }
      const mercuryRefreshTimeInSeconds = Math.ceil(refreshTime / 1000);

      const refreshTimeInSeconds = mercuryRefreshTimeInSeconds.toString();
    

      const UserLogparam = {
        selectedFiscalMonth: month,
        selectedCurrencyType: currencyType,
        SelectedCompanies: sAPCompanyCode,
        ExcludeDataWithInsignificantBalance: excludeInsignificantBalance,
        ExcludeZeroBalances: excludeZeroBalances,
        refreshTime: refreshTimeInSeconds,
        roundingType: roundingType,
        reportType: reportType,
      };


      const token = await getToken();
      const response = await axios.post(
        `${Config.apiBaseAddress}${Config.apiCreateLogFile}`,
        UserLogparam,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("saveUserLog on button click", response);

    } catch (error) {
      console.error('Error config:');
    }
  };

  const handlecallback = (val: any, status: any) => {
    //   if(status == 'Expand' && state.data.filter( (x:any) => x.id == val)[0].parentIds == null && state.data.filter((x:any) =>  (x.parentIds != null &&  x.parentIds.indexOf(val) > -1)).length == 0 )
    //    {GetChilderen(val);}

    //  else{ 
    console.log("handlecallback triggered with val: in GridComponent", val, "status:", status);
    state.data.map((item: any) => {
      if (item.id == val)
        item.exapnded = item.id == val && status == "Collapse" ? false : true;
      if (status == "Collapse") {
        if (
          // item.sortParentIds != null &&
          // item.sortParentIds.indexOf(val) > -1
          item.parentId != null &&
          item.parentId.indexOf(val) > -1
        ) {
          item.visiable = false;
          item.exapnded = status == "Collapse" ? false : true;
        }
      } else if (
        item.parentId == val ||
        (item.display == true && item.hirerachyId == val)
      ) {
        item.visiable = true;
      }
    });

    setstate({
      type: ACTION_TYPES.SET_CORGRID, payload: {
        Data: [...state.data],
        ColHeaders: Data.gridData.gridData.gridColumnHeaders,
        errorMSG: ""
      }
    })
    setgDatastate([...state.data.filter((e: any) => e.visiable == true)]);
    setErrorStatus(false)

    if (
      state.data.filter((x: any) => x.visiable == true).length ===
      state.data.length ||
      state.data.filter((x: any) => x.visiable == false).length ===
      state.data.filter((x: any) => x.parentId != "0").length
    ) {
      //alert((flatGriddata.filter(x => x.visiable == true).length === flatGriddata.length) ?true:false);
      const chkAllItemExpandCollapsedState = state.data.filter((x: any) => x.visiable == true).length ===
        state.data.length
        ? true
        : false;
      dispatch(
        setExpandAllAction(
          chkAllItemExpandCollapsedState
        )
      );
      setActiveButton(chkAllItemExpandCollapsedState);
    }
    //}
  };
  const handlecallbackActiveButtonr = (status: any) => {
    dispatch(setExpandCollapseAction(status));
  };
  const GetChilderen = async (ClassId: any) => {
    let selectedValues = SelectedFilterValues();
    selectedValues.selectedType = todate;
    const selectedValuescount = Object.entries(selectedValues).filter((x) => x[0] != "selectedSortOptions" && x[0] != "chkExcludeData" && x[0] != "chkExcludeZeroData" && x[0] != "isDefault" && (x[1] == "" || x[1] == undefined)).length;
    let payload = selectedValuescount == 0 ? selectedValues : Data.commonStore.DefaultPrefrenceStatus && Data.preferences.intialdefaultFilterPreference != null ?
      { ...Data.preferences.intialdefaultFilterPreference } : null;

    if (payload != null) {
      payload.selectedType = todate;
      payload.selectedClass = ClassId;
      setGlobalSpinner(true);
      apiClient.post(`${Config.apiBaseAddress}${Config.apiGetSegmentalData}`,
        payload, { headers: { "Authorization": `Bearer ${await getToken()}` } })
        .then((response) => {

          const lst = [];
          for (var i = 0; i < state.data.length; i++) {
            state.data[i].exapnded = response.data.filter((x: any) => x.id == state.data[i].id).length > 0 ? true : state.data[i].exapnded;
            lst.push(state.data[i]);
            let filtereddata: any[] = [];
            if (response.data.filter((x: any) => x.id == state.data[i].id).length > 0) {
              filtereddata = response.data.filter((x: any) => x.parentId != "").map((item: any) => {
                let Parentids = getparentids(item.parentId);
                item.visiable = item.groupType == "SubClass" ? true : false;
                item.isChilderen = response.data.filter((i: any) => i.parentId == item.id).length > 0 ? true : false;
                item.parentIds = Parentids;
                item.sortParentIds = Parentids;
                lst.push(item);
              });
              // filtereddata.length > 0 && lst.push(...filtereddata);
              // for(var j=0; j < filtereddata.length ; j++){
              //   let Parentids = getparentids(filtereddata[j].parentId);
              // filtereddata[j].visiable = filtereddata[j].groupType == "SubClass" ? true:false;
              // filtereddata[j].isChilderen = response.data.filter( (i:any) => i.parentId == filtereddata[j].id).length > 0 ? true:false;
              // filtereddata[j].parentIds=Parentids;
              // filtereddata[j].sortParentIds=Parentids;
              // lst.push(filtereddata[j]);
              // }
            }
          }
          debugger
          dispatch(setGridAction({ actualsClasses: lst, gridColumnHeaders: Data.gridData.gridData.gridColumnHeaders }));
          dispatch(setCanViewAction(Data.gridData.gridData.canView));
          dispatch(setCanEnterAction(Data.gridData.gridData.canEnter));
          setGlobalSpinner(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setGlobalSpinner(false);


        });
    }
    else {
      setGlobalSpinner(false);

    }

  }
  const setGlobalSpinner = async (val: boolean) => {
    dispatch(await setGlobalSpinnerAction(val));
  };
  const setDefaultPreferenceStatusStore = async (val: boolean) => {
    dispatch(await setDefaultPreferenceStatusAction(val));
  };

  const getparentids = (Parentid: string) => {

    let lstParentIds: string[] = [];
    let constParentid = ''
    Parentid.split('-').map((item: any) => {
      constParentid = constParentid == '' ? item : constParentid + "-" + item;
      lstParentIds.push(constParentid);
    });

    return lstParentIds;
  }

  const GridUI = (obj: any) => {

    return (

      <div>

        {obj && obj.data && obj.data.length > 0 && (
          <div className="data-grid-wrap" style={{ height: obj.height - 203 }}>
            <div className="data-grid">
              <RowColumn
                key={-1}
                data={obj.ColHeaders}
                handlecallback={handlecallback}
                iscolHeader={true}
                activeButton={activeButton}
                handlecallbackActiveButton={handlecallbackActiveButtonr}
                selectedColumns={selectedColumns}
              ></RowColumn>
              {obj.data.map((item: any, index: number) => {
                if (!item.visiable || !item.display) return null;
                return (
                  <RowColumn
                    key={item.id}
                    data={item}
                    handlecallback={handlecallback}
                    iscolHeader={false}
                    activeButton={activeButton}
                    handlecallbackActiveButton={handlecallbackActiveButtonr}
                    selectedColumns={selectedColumns}
                  ></RowColumn>
                );
              })}
            </div>
          </div>
        )}
        {obj && obj.data && obj.data.length == 0 && (
          <div className={errorStatus ? "no-grid-data-error" : "no-grid-data"}>{obj.errorMSG}</div>
        )}
      </div>
    )
  }

  const memoizedValue = useMemo(() => GridUI(state), [state, selectedColumns]);
  //  console.log("Data.gridData.gridData.gridColumnHeaders    ",Data.gridData.gridData.gridColumnHeaders)

  return (
    // <div>
    //   {memoizedValue}
    // </div>

    <div className="data-grid-wrap" style={{ height: gHeight - 203 }}>
      <div className="data-grid" >

        {gDatastate.length > 0 && Data.gridData.gridData.gridColumnHeaders != null &&

          (
            <>
              {/* <RowColumn
                key={-1}
                data={Data.gridData.gridData.gridColumnHeaders}
                handlecallback={handlecallback}
                iscolHeader={true}
                activeButton={activeButton}
                handlecallbackActiveButton={handlecallbackActiveButtonr}
                selectedColumns={selectedColumns}
              ></RowColumn> */}
              {/* // <button onClick={testclick}></button> */}

              <TableVirtuoso
                style={{
                  // height:  gHeight+202 ,
                  // overflowY:"hidden",
                  // overflowX:"hidden"
                  height: gHeight - 206
                }}
                //topItemCount={1}


                data={gDatastate}
                totalCount={gDatastate.length}
                //useWindowScroll
                fixedHeaderContent={() => (

                  <RowColumn
                    key={-1}
                    data={Data.gridData.gridData.gridColumnHeaders}
                    handlecallback={handlecallback}
                    iscolHeader={true}
                    activeButton={activeButton}
                    handlecallbackActiveButton={handlecallbackActiveButtonr}
                    selectedColumns={selectedColumns}
                  ></RowColumn>
                )}

                fixedFooterContent={() => (
                  <>
                    {gDatastate[gDatastate.length - 1].desc === "Grand Total" && (
                      <RowColumn
                        key={gDatastate[gDatastate.length - 1].id}
                        data={gDatastate[gDatastate.length - 1]}
                        handlecallback={handlecallback}
                        iscolHeader={false}
                        activeButton={activeButton}
                        handlecallbackActiveButton={handlecallbackActiveButtonr}
                        selectedColumns={selectedColumns}
                      />
                    )}
                    {gDatastate[gDatastate.length - 1].desc === "Grand Total" && (
                      <div className="hiddenrows">
                      </div>
                    )}
                  </>
                )}

                itemContent={(index: any) => {

                  return (
                    <>


                      {gDatastate[index].desc != "Grand Total" ?



                        <RowColumn
                          key={gDatastate[index].id}
                          data={gDatastate[index]}
                          handlecallback={handlecallback}
                          iscolHeader={false}
                          activeButton={activeButton}
                          handlecallbackActiveButton={handlecallbackActiveButtonr}
                          selectedColumns={selectedColumns}
                        ></RowColumn> : <></>}
                      {/* { 
                   
                   index == 0 && 
                   
                   <RowColumn
                key={-1}
                data={0}
                handlecallback={handlecallback}
                iscolHeader={false}
                activeButton={activeButton}
                handlecallbackActiveButton={handlecallbackActiveButtonr}
                selectedColumns={selectedColumns}
              ></RowColumn>
                   } */}

                      {/* { index != 0 &&
                   
                               <RowColumn
                                 key={gDatastate[index-1].id}
                                 data={gDatastate[index-1]}
                                 handlecallback={handlecallback}
                                 iscolHeader={false}
                                 activeButton={activeButton}
                                 handlecallbackActiveButton={handlecallbackActiveButtonr}
                                selectedColumns={selectedColumns}
                                 ></RowColumn>
                   } */}

                      {/* { (index+1) == gDatastate.length &&
                   
                  //  <RowColumn
                  //    key={gDatastate[gDatastate.length-1].id}
                  //    data={gDatastate[gDatastate.length-1]}
                  //    handlecallback={handlecallback}
                  //    iscolHeader={false}
                  //    activeButton={activeButton}
                  //    handlecallbackActiveButton={handlecallbackActiveButtonr}
                  //   selectedColumns={selectedColumns}
                  //    ></RowColumn>
       } */}
                    </>



                  )
                }

                }



              />
            </>)}
        {gDatastate && gDatastate.length == 0 && (
          <div className={errorStatus ? "no-grid-data-error" : "no-grid-data"}>{state.errorMSG}</div>
        )}
      </div>
    </div>
  );
};
export default GridComponent;   