// Action Types
export const TOGGLE_INSIGNIFICANT_BALANCE = 'TOGGLE_INSIGNIFICANT_BALANCE';
export const TOGGLE_ZERO_BALANCES = 'TOGGLE_ZERO_BALANCES';

// Action Interfaces
interface ToggleInsignificantBalanceAction {
  type: typeof TOGGLE_INSIGNIFICANT_BALANCE;
  payload:boolean;
}

interface ToggleZeroBalancesAction {
  type: typeof TOGGLE_ZERO_BALANCES;
  payload:boolean;
}

export type CheckboxActionTypes = ToggleInsignificantBalanceAction | ToggleZeroBalancesAction;

// Action Creators
export const toggleInsignificantBalance = (value:boolean): ToggleInsignificantBalanceAction => ({
  type: TOGGLE_INSIGNIFICANT_BALANCE,
  payload:value
});

export const toggleZeroBalances = (value:boolean): ToggleZeroBalancesAction => ({
  type: TOGGLE_ZERO_BALANCES,
  payload:value
});
