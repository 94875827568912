import React, { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

// Combined context for toggle and chart button 
interface CombinedContextProps {
  toggleView: boolean;
  setToggleView: Dispatch<SetStateAction<boolean>>;
  chartMode: boolean;
  setChartMode: Dispatch<SetStateAction<boolean>>;
  Applyfilter : boolean;
  setApplyfilter : Dispatch<SetStateAction<boolean>>;
  Gridloader : boolean;
  setGridloader : Dispatch<SetStateAction<boolean>>;
  PowerBiloader : boolean;
  setPowerBiloader : Dispatch<SetStateAction<boolean>>;
}

const CombinedContext = createContext<CombinedContextProps | undefined>(undefined);

interface CombinedProviderProps {
  children: ReactNode;
}

export const CombinedProvider: React.FC<CombinedProviderProps> = ({ children }) => {
  const [toggleView, setToggleView] = useState(true);
  const [chartMode, setChartMode] = useState(true);
  const [Applyfilter , setApplyfilter] = useState(true);
  const [Gridloader , setGridloader] = useState(true);
  const [PowerBiloader , setPowerBiloader] = useState(false);

  return (
    <CombinedContext.Provider value={{ toggleView, setToggleView, chartMode, setChartMode   , Applyfilter , setApplyfilter , Gridloader , setGridloader, PowerBiloader , setPowerBiloader}}>
      {children}
    </CombinedContext.Provider>
  );
};

export const useCombinedContext = (): CombinedContextProps => {
  const context = useContext(CombinedContext);

  if (!context) {
    throw new Error('useCombinedContext must be used within a CombinedProvider');
  }

  return context;
};