import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import SpinnerComponent from "./Common/SpinnerComponent";
import { CloseButton } from "react-bootstrap";
import { getClasses } from "./Common/GetDataFromStore";
import { RootReducer } from "../Redux/Reducers/types";

function ExportGrid({ ...props }) {
  const [selectedOption, setSelectedOption] = useState("Excel");
  const [selectedClasses, setselectedClasses] = useState("All");
  const [Classes, setClasses] = useState(getClasses());
  const [AllSelected, setAllSelected] = useState(true);
  const selectedClassesValue = useSelector(
    (state: RootReducer) => state.cascadingDropDown.selectedClassesValue
  );
  const { intialdefaultFilterPreference } = useSelector(
    (state: RootReducer) => state.preferences
  );

  const gridData = useSelector((state: RootReducer) => state.gridData.gridData);
  const { apiRes } = useSelector(
    (state: RootReducer) => state.gridData
  );

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const handleAllChange = (event: any) => {
    setAllSelected(event.target.checked);
    Classes.filter((e: any) => e.disable == false).map((item: any) => {
      item.checked = event.target.checked;
    });
    setClasses([...Classes]);
  }

  const handleClassChange = (event: any) => {

    Classes.map((item: any) => {
      if (item.classId == event.target.value)
        item.checked = event.target.checked;
    });
    setClasses([...Classes]);

    setAllSelected(Classes.filter((e) => e.disable == false).length == Classes.filter((e: any) => e.checked == true).length ? true : false);
  };

  //   useEffect(() => {

  // debugger;
  //     let calssids:any[]=[]
  //     if ( selectedClassesValue.length > 0) 
  //      {  calssids = selectedClassesValue.map((item:any) => 
  //      item.value
  //      );}
  //      updateClasses(calssids);
  //   } ,[selectedClassesValue]);

  //   useEffect(() => {
  //     debugger;
  //         let calssids:any[]=[]
  //         if (intialdefaultFilterPreference != null ) 
  //          {  calssids = intialdefaultFilterPreference.selectedClass.split(',').map((item:any) => 
  //          item
  //          );}

  //          calssids.length > 0 && updateClasses(calssids);

  //       } ,[intialdefaultFilterPreference]);

  const updateClasses = (calssids: any) => {
    Classes.map((item: any) => {
      item.disable = calssids.filter((e: any) => e === item.classId).length > 0 ? false : true;
      item.checked = calssids.filter((e: any) => e === item.classId).length > 0 ? true : false;
    });
    setAllSelected(true);
    setClasses([...Classes]);
  }

  useEffect(() => {
    let calssids: any[] = []
    if (apiRes != 500 && gridData.actualsClasses != null && gridData.actualsClasses.length > 0) {
      calssids = gridData.actualsClasses.filter((e: any) => e.parentId == '0' && e.id != '0').map((item: any) =>
        item.id
      );
    }

    updateClasses(calssids);


  }, [gridData]);

  // useEffect ( () => {

  //   alert('1');

  //   if(intialdefaultFilterPreference != null && intialdefaultFilterPreference.selectedClass != null)
  //   {
  //      let calssids = intialdefaultFilterPreference.selectedClass.split(',');
  //      Classes.map((item:any) => {
  //      item.disable= calssids.filter( (e:any) => e === item.classId).length >0 ?false:true;
  //      item.checked= calssids.filter( (e:any) => e === item.classId).length >0 ?true:false;
  //  });
  //     setAllSelected(true);
  //     setClasses([...Classes]);
  //   }
  //   else if (selectedClassesValue.length > 0){
  //     Classes.map((item:any) => {
  //        item.disable=selectedClassesValue.filter( (e:any) => e.value == item.classId).length >0 ? false:true;
  //        item.checked=selectedClassesValue.filter( (e:any) => e.value == item.classId).length >0 ?true:false;
  //     });
  //     setAllSelected(true);
  //     setClasses([...Classes]);
  //    }
  // // if(selectedClassesValue.length > 0){

  // //  Classes.map((item:any) => {
  // //   if(selectedClassesValue.filter( (e:any) => e.value == item.classId).length >0){
  // //     item.disable=false;
  // //     item.checked=true;
  // //   }
  // //  });
  // //  setAllSelected(true);
  // //  setClasses([...Classes]);

  // // }
  // },[intialdefaultFilterPreference]);

  // useEffect ( () => {
  //   if(selectedClassesValue.length > 0){
  //    Classes.map((item:any) => {
  //       item.disable=selectedClassesValue.filter( (e:any) => e.value == item.classId).length >0 ? false:true;
  //       item.checked=selectedClassesValue.filter( (e:any) => e.value == item.classId).length >0 ?true:false;
  //    });
  //    setAllSelected(true);
  //    setClasses([...Classes]);
  //   }
  //   },[selectedClassesValue])

  const handleExportToExcel = () => {
    // Implement your export logic here based on the selected option
    console.log("Selected Option:", selectedOption);
  };
  return (
    <Modal {...props} centered>
      <Modal.Header closeVariant="white">
        <Modal.Title>Export Grid</Modal.Title>
        <CloseButton className="btn-close btn-close-white"
          onClick={() => {
            props.handleModalHide();
            props.setLoading(false);
          }}

        />
      </Modal.Header>
      <Modal.Body>
        <Form>

          <div className="mb-12" style={{ "display": "inline-flex" }}>
            <div className="mb-6" style={{ "borderRight": "1px solid #ccc", "display": "inline-grid" }}>
              {/* <Form.Check
             inline
             type="radio"
             label="PPT"
             name="export"
             value="PPT"
             checked={selectedOption === "PPT"}
             onChange={handleOptionChange}
             aria-label="Export"
            /> */}
              <Form.Check
                inline
                type="radio"
                label="Excel"
                name="export"
                value="Excel"
                checked={selectedOption === "Excel"}
                onChange={handleOptionChange}
                aria-label="Export"
              />
            </div>
            <div className="mb-6" style={{ "padding": "10px", "paddingTop": "0px" }}>
              <Form.Check
                inline
                type="checkbox"
                label="All"
                aria-label="Include Filters"
                name="ALL"
                value="0"
                onClick={handleAllChange}
                checked={AllSelected}
                id="All"
              />

              <div style={{ "padding": "10px" }}>
                {
                  Classes.map((item: any) =>

                    <Form.Check
                      type="checkbox"
                      label={item.className}
                      aria-label={item.className}
                      name={item.className}
                      value={item.classId}
                      onClick={handleClassChange}
                      checked={item.checked}
                      disabled={item.disable}
                      id={item.className}
                    />
                  )
                }
              </div>
              <div>
                <Form.Check
                  inline
                  label="Include Filters"
                  name=""
                  type="checkbox"
                  onClick={() => {
                    props.setFilter(!props.filter);
                    props.handleCheckBox();
                  }}
                  aria-label="Include Filters"
                  id={"Include Filters"}
                />
              </div>
            </div>
          </div>

          {props.loading ? (
            <SpinnerComponent text="Loading..." />
          ) : (
            <div className="d-flex justify-content-end">
              <Button
                className="btn btn-dark btn-sm"
                onClick={() => props.exportToExcle({ "ReportType": selectedOption, "Classes": Classes.filter((item: any) => item.checked == true) }
                )}
                aria-label="Export Button"
              >
                Export
              </Button>
            </div>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default ExportGrid;
