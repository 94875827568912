import { ACTION_TYPES } from "./GridActionTypes";
export const INTIAL_STATE:any={
    data:[],
    ColHeaders:{},
    numberFormat:1000000,
    height:window.innerHeight,
    errorMSG:'Please select filters for the data.'
};

export const GridReducer = (state:any,action:any) =>{

switch(action.type){
    case ACTION_TYPES.SET_HEIGHT:
        return{
            ...state,
            height:action.payload
        }
    case ACTION_TYPES.SET_CORGRID:
            
            return{
                ...state,
                data:action.payload.Data,
                ColHeaders: action.payload.ColHeaders,
                errorMSG:action.payload.errorMSG
            }

    case ACTION_TYPES.SET_NUMBER:
                return{
                    ...state,
                    numberFormat:action.payload,
                }

    case ACTION_TYPES.SET_INTIAL_CORGRID:
                    return{
                        ...state,
                        data:[],
                        ColHeaders: {},
                        errorMSG:'Please select filters for the data.'
                    }
        
}
};