import { Option } from '../Actions/ActionType';

import { SET_DEFAULT_SAP_COMPANY_CODES, SET_SELECTED_SAP_COMPANY_CODES,  SET_SAP_COMPANY_CODES} from '../ActionContants/constants';

interface CustomMultiSelectState {
  sapCompanyCodes:Option[];
  selectedSAPCompanyCodes: Option[];
  defaultSAPCompanyCodes:Option[];
}

const initialState: CustomMultiSelectState = {
  sapCompanyCodes:[],
  selectedSAPCompanyCodes: [],
  defaultSAPCompanyCodes:[],
};

const customMultiSelectReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_SAP_COMPANY_CODES:
      return {
        ...state,
        sapCompanyCodes: action.payload,
      };

    case SET_SELECTED_SAP_COMPANY_CODES:
      return {
        ...state,
        selectedSAPCompanyCodes: action.payload,
        // defaultSAPCompanyCodes:action.payload,
      };
    
    case SET_DEFAULT_SAP_COMPANY_CODES:
      return {
          ...state,
          // defaultSAPCompanyCodes:action.payload,
          selectedSAPCompanyCodes: action.payload,
      };
    default:
      return state;
  }
};

export default customMultiSelectReducer;