import React from 'react';
import {Embed , models , Report} from "powerbi-client";
import {PowerBIEmbed} from "powerbi-client-react";
import {useState , useEffect} from "react";
import { SelectedFilterValues } from '../Components/Common/GetDataFromStore';
import Store from '../Redux/Store/Store';
import { getPowerbiAccessToken } from '../Services/PowerbiAccessToken';
import { useSelector } from 'react-redux';
import { RootReducer } from '../Redux/Reducers/types';
import { useCombinedContext } from './PowerBIContext'; 
import { getDefaultPrefenceValue } from '../Services/Service'; 
import Config from '../Config';
// import UsePowerBiAccessToken from '../Services/UsepowerBIAccessToken';

interface Props {
  handlePanalClose: () => void;
  todate?: any;
}

const PowerBI: React.FC<Props> = ({ handlePanalClose }) =>  {

  const [accessToken, setAccessToken] = useState("");

  
  const { Applyfilter , Gridloader , setGridloader , setPowerBiloader } = useCombinedContext();
  const [reloadKey, setReloadKey] = useState(0);
  const { apiRes } = useSelector(
    (state: RootReducer) => state.gridData
  );
const [defaultpreference , setdefaultpreference] = useState<defaultpreference | null>(null);
  useEffect(() => {
    const preferencedata = async () => {
      try {
        const response = await getDefaultPrefenceValue();
        setdefaultpreference(response);
        // console.log("checking>>>>",defaultpreference);
      } 
      catch(error){
        console.error("Failed to fetch preference data", error)
      }
    }
    preferencedata();
  } , [])
  interface defaultpreference {
    selectedClass : any;
    selectedSubClass: any;
    selectedChannelGroup: any;
    selectedSortOptions: any;
    selectedChannelLineItem: any;
    selectedFinancialAccount: any;
    selectedInternalOrder: any;
    selectedProfitCenter: any;
    selectExecOrg: any;
    selectedCostCenter: any;
    selectExecSummaryOrg: any;
    selectedFiscalMonth: any;
    selectedCurrencyType: any;
    selectedCompanies: any;
    formatNbr: any;
    chkExcludeData: boolean;
    chkExcludeZeroData: boolean;
  }
  useEffect(() => {
    if (Gridloader === false) {
      const fetchAccessToken = async () => {
        try {
          const response = await getPowerbiAccessToken();
          setAccessToken(response.data);
        } catch (error) {
          console.error("Error fetching Power BI access token:", error);
        }
      };
      fetchAccessToken();
    }
  }, [Gridloader]);


  useEffect(() => {
    if (apiRes) {
       handlePanalClose();
       setReloadKey(prevKey => prevKey + 1);
    }
  }, [apiRes]); 

    let selectedValues = SelectedFilterValues();
    const storeData = Store.getState();
    const totalGroupClassesCount = storeData.cascadingDropDown.groupclasses.length;
    const totalLineitemCount = storeData.cascadingDropDown.lineitem.length;
    const totalFinaccountCount = storeData.cascadingDropDown.finaccount.length;
    const totalOrderCount = storeData.cascadingDropDown.order.length;
    const totalProfitcenterCount = storeData.cascadingDropDown.profitcenter.length;
    const totalCostCenterCount = storeData.cascadingDropDown.costcenter.length;
    const totalOrgCount = storeData.cascadingDropDown.org.length;
    const totalOrgsummaryCount = storeData.cascadingDropDown.orgsummary.length;

    let ReportID: string = "";
    let PowerBIURL: string = "";
    if(selectedValues.selectedType === "MTD")
    {
      ReportID = `${Config.ReportIDMTD}`;
      PowerBIURL = `${Config.PowerBIMTDURL}`

    }
    else
    {
      ReportID = `${Config.ReportIDQTDYTD}`;
      PowerBIURL = `${Config.PowerBIQTDYTDURL}`;
    }

    const selectedSortOption = selectedValues.selectedSortOptions;
    const columnOrderMapping: { [key: string]: number } = {};
    const selectedSortOptionsArray = selectedSortOption.split(',');

    selectedSortOptionsArray.forEach((item: string | number, index: number) => {
      columnOrderMapping[item] = index;
    });
    // const Group = storeData.cascadingDropDown.groupclasses;
    
    const columns: { [key: string]: string } = {
      LineItem: "'Embded_PBI_MTD'[Line item]",
      Group: "'Embded_PBI_MTD'[Group]",
      AccountNo: "'Embded_PBI_MTD'[Account No Description]",
      IONo: "'Embded_PBI_MTD'[IO Number and Description]",
      PCNo: "'Embded_PBI_MTD'[PC No and Description]",
      ExecOrg: "'Embded_PBI_MTD'[Exec Org]",
      CostCenter: "'Embded_PBI_MTD'[Cost Center]",
      ExecSummary: "'Embded_PBI_MTD'[Exec Org Summary]",
    };
    const sortedColumns = Object.keys(columns).sort(
      (a, b) => columnOrderMapping[a] - columnOrderMapping[b]
    );
    const sortValues = sortedColumns.map(column => columns[column as keyof typeof columns]).join(',');

    
    const columnsParameter: { [key: string]: string } = {
      LineItem: "Line item",
      Group: "Group",
      AccountNo: "Account No Description",
      IONo: "IO Number and Description",
      PCNo: "PC No and Description",
      ExecOrg: "Exec Org",
      CostCenter: "Cost Center",
      ExecSummary: "Exec Org Summary",
    };
    const sortedColumnsParameter = Object.keys(columnsParameter).sort(
      (a, b) => columnOrderMapping[a] - columnOrderMapping[b]
    );
    const sortValuesParameter = sortedColumnsParameter.map(column => columnsParameter[column as keyof typeof columnsParameter]).join(',');


    // const selectedClassesArray = selectedClasses.split(',');
    // const subclass  = selectedValues.selectedSubClasstext;
    // const subclassArray = subclass.split(',');
    // let fliters = [];
    // if(selectedValues.selectedType === "MTD")
    // {}
    //  const classfilter = selectedValues.slectedClasstext;
    // console.log("checked@@@class>>",selectedValues.selectedClass.split(',').map(Number));
    // console.log("checkingsortoptionsvalue>>>",selectedValues.selectedSortOptions);
    //debugger;
    const selectedSortOptions = selectedValues.selectedSortOptions;
    let filters = [];
    if(defaultpreference != null && defaultpreference.selectedClass && Applyfilter && !selectedValues.selectedClass){
      filters.push({
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "ChannelClass",
          column: "ChannelClassId",
        },
        operator: "In",
        values: defaultpreference.selectedClass.split(',').map(Number),
        filterType: models.FilterType.Basic,
      }); 
    }
    if (selectedValues.selectedClass && Applyfilter) {
      filters.push({
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "ChannelClass",
          column: "ChannelClassId",
        },
        operator: "In",
        values: selectedValues.selectedClass.split(',').map(Number),
        filterType: models.FilterType.Basic,
      });
    } 
    if(defaultpreference != null && defaultpreference.selectedSubClass && Applyfilter && !selectedValues.selectedSubClass){
      filters.push( {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "ChannelSubClass",
          column: "ChannelSubClassId",
        },
        operator: "In",
        values: defaultpreference.selectedSubClass.split(',').map(Number),
        filterType: models.FilterType.Basic
      });
    }
    if(selectedValues.selectedSubClass && Applyfilter) {
         filters.push( {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "ChannelSubClass",
          column: "ChannelSubClassId",
        },
        operator: "In",
        values: selectedValues.selectedSubClass.split(',').map(Number),
        filterType: models.FilterType.Basic
      });
    }
    if(defaultpreference != null && defaultpreference.selectedChannelGroup && defaultpreference.selectedSortOptions.includes('Group') && Applyfilter) {
       
        filters.push( {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            table: "ChannelGroup",
            column: "ChannelGroupId",
          },
          operator: "In",
          values: defaultpreference.selectedChannelGroup.split(',').map(Number),
          filterType: models.FilterType.Basic
        });
      }
   if(selectedValues.selectedChannelGroup && selectedValues.selectedSortOptions.includes('Group') && Applyfilter) {
    if(storeData.cascadingDropDown.selectedGroupClassesValue.length != totalGroupClassesCount)
    {
      filters.push( {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "ChannelGroup",
          column: "ChannelGroupId",
        },
        operator: "In",
        values: selectedValues.selectedChannelGroup.split(',').map(Number),
        filterType: models.FilterType.Basic
      });
    }
   } 
  //  else {
  //   filters.push( {
  //     $schema: "http://powerbi.com/product/schema#basic",
  //     target: {
  //       table: "ChannelGroup",
  //       column: "ChannelGroupId",
  //     },
  //     operator: "None",
  //     values:[selectedValues.selectedChannelGroup],
  //     filterType: models.FilterType.Basic
  //   });
  //  }
  if(defaultpreference != null && defaultpreference.selectedChannelLineItem && defaultpreference.selectedSortOptions.includes('LineItem') && !selectedValues.selectedChannelLineItem && Applyfilter) {
    filters.push( {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "ChannelLineItem",
        column: "ChannelLineItemId",
      },
      operator: "In",
      values: defaultpreference.selectedChannelLineItem.split(',').map(Number ),
      filterType: models.FilterType.Basic
    });
  }
    if(selectedValues.selectedChannelLineItem && selectedValues.selectedSortOptions.includes('LineItem') && Applyfilter) {
if(storeData.cascadingDropDown.selectedLineItemValue.length != totalLineitemCount )
{
      filters.push( {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "ChannelLineItem",
          column: "ChannelLineItemId",
        },
        operator: "In",
        values: selectedValues.selectedChannelLineItem.split(',').map(Number ),
        filterType: models.FilterType.Basic
      });
    }
  }
    // else {
    //   filters.push( {
    //     $schema: "http://powerbi.com/product/schema#basic",
    //     target: {
    //       table: "ChannelLineItem",
    //       column: "ChannelLineItemId",
    //     },
    //     operator: "None",
    //     values: [selectedValues.selectedChannelLineItem],
    //     filterType: models.FilterType.Basic
    //   });
    // }

    if(defaultpreference != null && defaultpreference.selectedFinancialAccount && defaultpreference.selectedSortOptions.includes('AccountNo') && !selectedValues.selectedFinancialAccount && Applyfilter) {
      filters.push( {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "FinancialAccount",
          column: "FinancialAccountId",
        },
        operator: "In",
        values: defaultpreference.selectedFinancialAccount.split(',').map(Number),
        filterType: models.FilterType.Basic
      });
    }
    if(selectedValues.selectedFinancialAccount && selectedValues.selectedSortOptions.includes('AccountNo') && Applyfilter) {
if(storeData.cascadingDropDown.selectedFinAccountValue.length != totalFinaccountCount){

      filters.push( {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "FinancialAccount",
          column: "FinancialAccountId",
        },
        operator: "In",
        values: selectedValues.selectedFinancialAccount.split(',').map(Number),
        filterType: models.FilterType.Basic
      });
    }
    } 
    // else {
    //   filters.push( {
    //     $schema: "http://powerbi.com/product/schema#basic",
    //     target: {
    //       table: "FinancialAccount",
    //       column: "FinancialAccountId",
    //     },
    //     operator: "None",
    //     values: [selectedValues.selectedFinancialAccount],
    //     filterType: models.FilterType.Basic
    //   });
    // }
    if(defaultpreference != null && defaultpreference.selectedInternalOrder && defaultpreference.selectedSortOptions.includes('IONo') && !selectedValues.selectedInternalOrder && Applyfilter) {
      filters.push( {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "InternalOrder",
          column: "InternalOrderID",
        },
        operator: "In",
        values: defaultpreference.selectedInternalOrder.split(',').map(Number),
        filterType: models.FilterType.Basic
      });
    }
    if(selectedValues.selectedInternalOrder && selectedValues.selectedSortOptions.includes('IONo') && Applyfilter) {
      if(storeData.cascadingDropDown.selectedOrderValue.length != totalOrderCount){
      filters.push( {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "InternalOrder",
          column: "InternalOrderID",
        },
        operator: "In",
        values: selectedValues.selectedInternalOrder.split(',').map(Number),
        filterType: models.FilterType.Basic
      });
    }
    } 
    // else {
    //   filters.push( {
    //     $schema: "http://powerbi.com/product/schema#basic",
    //     target: {
    //       table: "InternalOrder",
    //       column: "InternalOrderID",
    //     },
    //     operator: "None",
    //     values: [selectedValues.selectedInternalOrder],
    //     filterType: models.FilterType.Basic
    //   });
    // }

    if(defaultpreference != null && defaultpreference.selectedProfitCenter && defaultpreference.selectedSortOptions.includes('PCNo') && !selectedValues.selectedProfitCenter && Applyfilter) {
      filters.push( {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "ProfitCenter",
          column: "ProfitCenterID",
        },
        operator: "In",
        values: defaultpreference.selectedProfitCenter.split(',').map(Number),
        filterType: models.FilterType.Basic
      });
    }
    if(selectedValues.selectedProfitCenter && selectedValues.selectedSortOptions.includes('PCNo') && Applyfilter) {
      if(storeData.cascadingDropDown.selectedProfitCenterValue.length != totalProfitcenterCount){
      filters.push( {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "ProfitCenter",
          column: "ProfitCenterID",
        },
        operator: "In",
        values: selectedValues.selectedProfitCenter.split(',').map(Number),
        filterType: models.FilterType.Basic
      });
    }
    } 
    // else {
    //   filters.push( {
    //     $schema: "http://powerbi.com/product/schema#basic",
    //     target: {
    //       table: "ProfitCenter",
    //       column: "ProfitCenterID",
    //     },
    //     operator: "None",
    //     values: [selectedValues.selectedProfitCenter],
    //     filterType: models.FilterType.Basic
    //   });
    // }

   
    if(defaultpreference != null && defaultpreference.selectExecOrg && defaultpreference.selectedSortOptions.includes('ExecOrg') && !selectedValues.selectExecOrg && Applyfilter) {
      filters.push( {
        $schema: "http://powerbi.com/product/schema#basic", 
        target: {
          table: "ExecOrg",
          column: "ExecOrgId",
        },
        operator: "In",
        values: defaultpreference.selectExecOrg.split(',').map(Number),
        filterType: models.FilterType.Basic
      });
    }
    if(selectedValues.selectExecOrg && selectedValues.selectedSortOptions.includes('ExecOrg') && Applyfilter) {
      if(storeData.cascadingDropDown.selectedOrgValue.length != totalOrgCount){
      filters.push( {
        $schema: "http://powerbi.com/product/schema#basic", 
        target: {
          table: "ExecOrg",
          column: "ExecOrgId",
        },
        operator: "In",
        values: selectedValues.selectExecOrg.split(',').map(Number),
        filterType: models.FilterType.Basic
      });
    }
    } 
    // else {
    //   filters.push( {
    //     $schema: "http://powerbi.com/product/schema#basic", 
    //     target: {
    //       table: "ExecOrg",
    //       column: "ExecOrgId",
    //     },
    //     operator: "None",
    //     values: [selectedValues.selectExecOrg],
    //     filterType: models.FilterType.Basic
    //   });
    // }
   
    if(defaultpreference != null && defaultpreference.selectedCostCenter && defaultpreference.selectedSortOptions.includes('CostCenter') && !selectedValues.selectedCostCenter && Applyfilter) {
      filters.push( {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "CostCenter",
          column: "CostCenterID",
        },
        operator: "In",
        values: defaultpreference.selectedCostCenter.split(',').map(Number),
        filterType: models.FilterType.Basic
      });
    }
    if(selectedValues.selectedCostCenter && selectedValues.selectedSortOptions.includes('CostCenter') && Applyfilter) {
      if(storeData.cascadingDropDown.selectedCostCenterValue.length != totalCostCenterCount){
      filters.push( {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "CostCenter",
          column: "CostCenterID",
        },
        operator: "In",
        values: selectedValues.selectedCostCenter.split(',').map(Number),
        filterType: models.FilterType.Basic
      });
    }
    } 
    // else {
    //   filters.push( {
    //     $schema: "http://powerbi.com/product/schema#basic",
    //     target: {
    //       table: "CostCenter",
    //       column: "CostCenterID",
    //     },
    //     operator: "None",
    //     values: [selectedValues.selectedCostCenter],
    //     filterType: models.FilterType.Basic
    //   });
    // }

    if(defaultpreference != null && defaultpreference.selectExecSummaryOrg && defaultpreference.selectedSortOptions.includes('ExecSummary') && !selectedValues.selectExecSummaryOrg && Applyfilter) {
      filters.push( {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "ExecOrgSummary",
          column: "ExecOrgSummaryId",
        },
        operator: "In",
        values: defaultpreference.selectExecSummaryOrg.split(',').map(Number),
        filterType: models.FilterType.Basic
      });
    }
    if(selectedValues.selectExecSummaryOrg && selectedValues.selectedSortOptions.includes('ExecSummary') && Applyfilter) {
      if(storeData.cascadingDropDown.selectedOrgSummaryValue.length != totalOrgsummaryCount){
      filters.push( {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "ExecOrgSummary",
          column: "ExecOrgSummaryId",
        },
        operator: "In",
        values: selectedValues.selectExecSummaryOrg.split(',').map(Number),
        filterType: models.FilterType.Basic
      });
    }
    } 
    // else {
    //   filters.push( {
    //     $schema: "http://powerbi.com/product/schema#basic",
    //     target: {
    //       table: "ExecOrgSummary",
    //       column: "ExecOrgSummaryId",
    //     },
    //     operator: "None",
    //     values: [selectedValues.selectExecSummaryOrg],
    //     filterType: models.FilterType.Basic
    //   });
    // }
    if(defaultpreference != null && defaultpreference.selectedFiscalMonth && !selectedValues.selectedFiscalMonth && Applyfilter) {
      filters.push( {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "FiscalMonth",
          column: "FiscalMonthId"
        },
        operator: "In",
        values: [Number(defaultpreference.selectedFiscalMonth)],
        filterType: models.FilterType.Basic
   });
    }
    if(selectedValues.selectedFiscalMonth && Applyfilter) {
      filters.push( {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "FiscalMonth",
          column: "FiscalMonthId"
        },
        operator: "In",
        values: selectedValues.selectedFiscalMonth.split(',').map(Number),
        filterType: models.FilterType.Basic
   });
 }

//  if(defaultpreference != null && defaultpreference.selectedCurrencyType && Applyfilter) {
//   let filterValues;

//   if(Array.isArray(defaultpreference.selectedCurrencyType)) {
//     filterValues = defaultpreference.selectedCurrencyType.map(Number);
//   } else {
//     filterValues = [Number(selectedValues.selectedCurrencyType)];
//   }

//   filters.push({
//     $schema: "http://powerbi.com/product/schema#basic",
//     target: {
//       table: "CurrencyTypeHierarchy",
//       column: "CurrencyTypeHierarchyID"
//     },
//     operator: "In",
//     values: filterValues,
//     filterType: models.FilterType.Basic
//   });
//  }
 if(selectedValues.selectedCurrencyType && Applyfilter) {
  let filterValues;

  if(Array.isArray(selectedValues.selectedCurrencyType)) {
    filterValues = selectedValues.selectedCurrencyType.map(Number);
  } else {
    filterValues = [Number(selectedValues.selectedCurrencyType)];
  }

  filters.push({
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "CurrencyTypeHierarchy",
      column: "CurrencyTypeHierarchyID"
    },
    operator: "In",
    values: filterValues,
    filterType: models.FilterType.Basic
  });
}
if(defaultpreference != null && defaultpreference.selectedCompanies && !selectedValues.selectedCompanies && Applyfilter) {
  filters.push( {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "SAPCompany",
      column: "SAPCompanyID"
    },
    operator: "In",
    values: defaultpreference.selectedCompanies.split(',').map(Number),
    filterType: models.FilterType.Basic
});
}

if(selectedValues.selectedCompanies && Applyfilter) {
  filters.push( {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "SAPCompany",
          column: "SAPCompanyID"
        },
        operator: "In",
        values: selectedValues.selectedCompanies.split(',').map(Number),
        filterType: models.FilterType.Basic
});
}

// if(defaultpreference != null && defaultpreference.formatNbr && Applyfilter) {
//   filters.push( {
//     $schema: "http://powerbi.com/product/schema#basic",
//     target: {
//       table: "RoundTo",
//       column: "ID"
//     },
//     operator: "In",
//     values: [selectedValues.formatNbr],
//     filterType: models.FilterType.Basic
// });
// }
if(selectedValues.formatNbr && Applyfilter) {
  filters.push( {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "RoundTo",
        column: "ID"
      },
      operator: "In",
      values: selectedValues.formatNbr.split(',').map(Number),
      filterType: models.FilterType.Basic
});
}

if(defaultpreference != null && defaultpreference.selectedSortOptions && !selectedValues.selectedSortOptions && Applyfilter) {
  
  filters.push( {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "Parameter",
      column: "Parameter"
    },
    operator: "In",
    values: sortValuesParameter.split(','),
    filterType: models.FilterType.Basic
});
} else if (defaultpreference != null && defaultpreference.selectedSortOptions === '' && !selectedValues.selectedSortOptions && Applyfilter){
  filters.push( {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "Parameter",
      column: "Parameter"
    },
    operator: "NotIn",
    values: sortValuesParameter.split(','),
    filterType: models.FilterType.Basic
});

}
if( selectedValues.selectedSortOptions && Applyfilter) {
  filters.push( {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "Parameter",
      column: "Parameter"
    },
    operator: "In",
    values: sortValuesParameter.split(','),
    filterType: models.FilterType.Basic
});
}
else if (selectedValues.selectedSortOptions === '' && defaultpreference === null  && Applyfilter) {
  filters.push( {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "Parameter",
      column: "Parameter"
    },
    operator: "NotIn",
    values: sortValuesParameter.split(','),
    filterType: models.FilterType.Basic
})
};
if(defaultpreference != null && defaultpreference.selectedSortOptions && !selectedValues.selectedSortOptions && Applyfilter) {

  filters.push( {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "Parameter",
      column: "Parameter Name"
    },
    operator: "In",
    values: defaultpreference.selectedSortOptions.split(','),
    filterType: models.FilterType.Basic

  });

}
else if (defaultpreference != null && defaultpreference.selectedSortOptions === '' && !selectedValues.selectedSortOptions && Applyfilter) {
  filters.push( {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "Parameter",
      column: "Parameter Name"
    },
    operator: "NotIn",
    values: defaultpreference.selectedSortOptions.split(','),
    filterType: models.FilterType.Basic
});
}

if(selectedValues.selectedSortOptions && Applyfilter) {
  filters.push( {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "Parameter",
      column: "Parameter Name"
    },
    operator: "In",
    values: selectedValues.selectedSortOptions.split(','),
    filterType: models.FilterType.Basic
});
}
else if (selectedValues.selectedSortOptions === '' && defaultpreference === null  && Applyfilter){
  filters.push( {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "Parameter",
      column: "Parameter Name"
    },
    operator: "NotIn",
    values: selectedValues.selectedSortOptions.split(','),
    filterType: models.FilterType.Basic
});
} 
//const sortValues = "'Embded_PBI_MTD'[Cost Center],'Embded_PBI_MTD'[Exec Org Summary],'Embded_PBI_MTD'[Exec Org],'Embded_PBI_MTD'[PC No and Description],'Embded_PBI_MTD'[IO Number and Description],'Embded_PBI_MTD'[Account No Description],'Embded_PBI_MTD'[Line item]";
if(defaultpreference != null && defaultpreference.selectedSortOptions && !selectedValues.selectedSortOptions && Applyfilter) {

  filters.push({
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "Parameter",
      column: "Parameter Fields"
    },
    operator: "In",
    values: sortValues.split(','),
    filterType: models.FilterType.Basic
  });
} else if (defaultpreference != null && defaultpreference.selectedSortOptions === '' && !selectedValues.selectedSortOptions && Applyfilter) {
  filters.push({
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "Parameter",
      column: "Parameter Fields"
    },
    operator: "NotIn",
    values: sortValues.split(','),
    filterType: models.FilterType.Basic
  });
  // console.log("filters", filters);
}
if (selectedValues.selectedSortOptions && Applyfilter) {
  filters.push({
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "Parameter",
      column: "Parameter Fields"
    },
    operator: "In",
    values: sortValues.split(','),
    filterType: models.FilterType.Basic
  });
}
else if(selectedValues.selectedSortOptions === '' && defaultpreference === null  && Applyfilter ) {
  filters.push({
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "Parameter",
      column: "Parameter Fields"
    },
    operator: "NotIn",
    values: sortValues.split(','),
    filterType: models.FilterType.Basic
  });
  // console.log("filters", filters);
} 

if(selectedValues.chkExcludeData && Applyfilter){
  filters.push({
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "Filters",
      column: "Insignificane balance"
    },
    operator: "IN",
    values: [1],
    filterType: models.FilterType.Basic
  });
} else {
  filters.push({
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "Filters",
      column: "Insignificane balance"
    },
    operator: "IN",
    values: [0],
    filterType: models.FilterType.Basic
  });
}

if(selectedValues.chkExcludeZeroData && Applyfilter){
  filters.push({
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "Filters",
      column: "Zero balance"
    },
    operator: "IN",
    values: [1],
    filterType: models.FilterType.Basic
  });
} else {
  filters.push({
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "Filters",
      column: "Zero balance"
    },
    operator: "IN",
    values: [0],
    filterType: models.FilterType.Basic
  });
}
console.log("filters",filters);
    return (
      <div key={reloadKey} className="embed-container">
          <PowerBIEmbed
              embedConfig={{
              type: 'report',   
              id: `${ReportID}`,
              embedUrl: `${PowerBIURL}`,
              accessToken: accessToken?.toString(),
              tokenType: models.TokenType.Aad,
              filters : filters,
              pageView: 'fitToWidth',
              settings: {
                panes: {
                  filters: {
                    expanded: false,
                    visible: true
                  }
                },
              }
            }}
  
            
            
            
            eventHandlers={
              new Map([
                ['loaded', function ()  { 
                  console.log('Report loaded'); 
                }],
                ['rendered', function () { 
                  console.log('Report rendered'); 
                  setGridloader(true);
                  setPowerBiloader(false); 
                }],
                ['error', function (event:any) { console.log(event.detail); }]
              ])
            }
  
            cssClassName={"Embed-container"}
  
            // getEmbeddedComponent={(embeddedReport) => {
            //   window.report = embeddedReport;
            // }}
          />
      </div>
    );
    
  }
  
 
  export default PowerBI;

