import React, { useEffect, useState } from 'react'
import { Modal, ModalBody } from 'react-bootstrap'
import { ButtonsAsColumns } from './buttonsAsColumns';
import ColumnsValidationError from "./Common/ColumnsValidationError";
import { SelectedFilterValues } from "./Common/GetDataFromStore";
import { getToken, getUsername } from './Services/MsalService';
import axios from 'axios';
import Config from '../Config';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducer } from '../Redux/Reducers/types';
import SpinnerComponent from './Common/SpinnerComponent';
type SelectedColumn = 'actuals' | 'pyActuals' | 'dollerVar' | 'percentageVar' | 'cpH' | 'pyCpH' | 'cpHVar' | 'cpHPercentage';
interface Props {
    celldata: any[];
    show: any;
    onHide: any;
    handlecallbackBtnClickr: (val: any) => void;
    handleCallBackAllBtnClick: (val: any) => void;
    btnFilterAll: any;
    handleCallBackApplyFilter: () => void;
    setSelectedColumns: (val: any) => void;
    handleCallBackAllYtdBtnClick: () => void;
    btnFilterAllYtd: any;
    celldataYtd: any[];
    handlecallbackBtnClickYtd: (val: any) => void;
}
export const Columns: React.FC<Props> = ({
    celldata,
    show,
    onHide,
    handlecallbackBtnClickr,
    handleCallBackAllBtnClick,
    btnFilterAll,
    handleCallBackApplyFilter,
    setSelectedColumns,
    handleCallBackAllYtdBtnClick,
    btnFilterAllYtd,
    celldataYtd,
    handlecallbackBtnClickYtd
}) => {
    const [isAnyColumnSelected, setIsAnyColumnSelected] = React.useState(true);
    const [showValidationError, setShowValidationError] = React.useState(false);


    const selected = btnFilterAll === true ? "selected" : "";
    const selectedQtdYtd = btnFilterAllYtd === true ? "selected" : ""
    const handlecallbackBtnClick = (val: any) => {
        handlecallbackBtnClickr(val);
    };
    const handleAllBtnClick = (e: any) => {
        handleCallBackAllBtnClick(e.currentTarget.value);
    };
    const handleAllQtdYtdBtnClick = () => {
        handleCallBackAllYtdBtnClick();
    };
    const dispatch = useDispatch();
    const fieldSort = useSelector(
        (state: RootReducer) => state
    );
    const setDarkMode = () => {
        document.querySelector("body")?.setAttribute("data-theme", "dark");
        localStorage.setItem("selectedTheme", "dark");
    };
 
    const setLightMode = () => {
        document.querySelector("body")?.setAttribute("data-theme", "light");
        localStorage.setItem("selectedTheme", "light");
    };
    const selectedTheme = localStorage.getItem("selectedTheme");
    const [Commentchk, setCommentchk] = useState(fieldSort.fieldSort.Commentarystatus);
    const [theme, setTheme] = useState(selectedTheme);
    const [loading,setloading] = useState(false);
    const [showfiltererroralert,setShowFilterErrorAlert] = useState(false);


    let selectedValues = SelectedFilterValues();


    const handleApplyFilter = async (e: any) => {
        setloading(true);
        let newSelectedColumns: any[] = [];
        if (selectedValues.selectedType === "MTD") {
            newSelectedColumns = ['desc', ...celldata.filter(x => {
                const propertyName = x[1][0].propertyName.toLowerCase().replace(/false$/, ''); // Remove "false" from the end
                return propertyName && x[1][0].isSelected;
            }).map(x => x[1][0].propertyName.toLowerCase().replace(/false$/, ''))]; // Remove "false" from the end
            if (newSelectedColumns.length <= 1) {
                setShowValidationError(true);
                setloading(false);
                return;
            }
        } else {
            const joinedQtdYtdData = celldata.concat(celldataYtd);
            newSelectedColumns = ['desc', ...joinedQtdYtdData.filter(x => {
                const propertyName = x[1][0].propertyName.toLowerCase().replace(/false$/, ''); // Remove "false" from the end
                return propertyName && x[1][0].isSelected;
            }).map(x => x[1][0].propertyName.toLowerCase().replace(/false$/, ''))]; // Remove "false" from the end
            if (newSelectedColumns.length <= 1) {
                setShowValidationError(true);
                setloading(false);
                return;
            }
        }
        try {
            const propertyName = newSelectedColumns.slice(1).map(value => value ? value.toLowerCase() : "");
            if (selectedValues.selectedType === "MTD") {


                const MTDparam = {
                    userAlias: await getUsername(),
                    IsCommentaryOpt: fieldSort.fieldSort.Commentarystatus,
                    theme: selectedTheme,
                    actuals: "",
                    pyActuals: "",
                    dollerVar: "",
                    percentageVar: "",
                    cpH: "",
                    pyCpH: "",
                    cpHVar: "",
                    cpHPercentage: "",
                };

                const propertyNamesToMatch: { [key: string]: keyof typeof MTDparam } = {
                    "actuals": "actuals",
                    "pyactuals": "pyActuals",
                    "dollervar": "dollerVar",
                    "percentagevar": "percentageVar",
                    "cph": "cpH",
                    "pycph": "pyCpH",
                    "cphvar": "cpHVar",
                    "cphpercentage": "cpHPercentage",
                };

                for (let i = 0; i < propertyName.length; i++) {
                    const propertyNameLower = propertyName[i];
                    const propertyNameToMatch = propertyNamesToMatch[propertyNameLower];

                    if (propertyNameToMatch !== undefined) {
                        MTDparam[propertyNameToMatch] = propertyNameLower;
                    }
                }
                console.log("MTDparam  :", MTDparam);


                const response = await axios.post(
                    `${Config.apiBaseAddress}${Config.apiSaveUserSettingsMTD}`,
                    JSON.stringify(MTDparam),
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${await getToken()}`,
                        },
                    }
                );

                console.log("API response:", response);
                if(response.status === 200){
                    setloading(false)
                } 
                // else {
                //     setloading(false);
                //     setShowFilterErrorAlert(true);
                // }
            }
            else {
                //QTDYTD

                const QTDnYTDparam = {
                    userAlias: await getUsername(),
                    IsCommentaryOpt: fieldSort.fieldSort.Commentarystatus,
                    theme: selectedTheme,
                    "CYQTDActual":"",
                    "PYQTDActual":"",
                    "QTDDollarVar":"",
                    "QTDPercentageVar":"",
                    "CYCpHQTD":"",
                    "PYCpHQTD":"",
                    "CpHDollarVarQTD":"",
                    "CpHQTDPercentage":"",
                    "CYYTDActual":"",
                    "PYYTDActual":"",
                    "YTDDollarVar":"",
                    "YTDPercentageVar":"",
                    "CYCpHYTD":"",
                    "PYCpHYTD":"",
                    "CpHDollarVarYTD":"",
                    "CpHYTDPercentage":"",
                };

                const propertyNamesToMatchQTDnYTD: { [key: string]: keyof typeof QTDnYTDparam } = {
                    "cyqtdactual": "CYQTDActual",
                    "pyqtdactual": "PYQTDActual",
                    "qtddollarvar": "QTDDollarVar",
                    "qtdpercentagevar": "QTDPercentageVar",
                    "cycphqtd": "CYCpHQTD",
                    "pycphqtd": "PYCpHQTD",
                    "cphdollarvarqtd": "CpHDollarVarQTD",
                    "cphqtdpercentage": "CpHQTDPercentage",
                    "cyytdactual": "CYYTDActual",
                    "pyytdactual": "PYYTDActual",
                    "ytddollarvar": "YTDDollarVar",
                    "ytdpercentagevar": "YTDPercentageVar",
                    "cycphytd": "CYCpHYTD",
                    "pycphytd": "PYCpHYTD",
                    "cphdollarvarytd": "CpHDollarVarYTD",
                    "cphytdpercentage": "CpHYTDPercentage",
                };

                for (let i = 0; i < propertyName.length; i++) {
                    const propertyNameLower = propertyName[i];
                    const propertyNameToMatch = propertyNamesToMatchQTDnYTD[propertyNameLower];

                    if (propertyNameToMatch !== undefined) {
                        QTDnYTDparam[propertyNameToMatch] = propertyNameLower;
                    }
                }
                console.log("QTDnYTDparam  :", QTDnYTDparam);


                const response = await axios.post(
                    `${Config.apiBaseAddress}${Config.apiSaveUserSettingsQTDnYTD}`,
                    JSON.stringify(QTDnYTDparam),
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${await getToken()}`,
                        },
                    }
                );

                console.log("API response:", response);
                if(response.status === 200){
                    setloading(false)
                } 
                // else if(response.status === 500){
                //     console.log("Api is not working")
                // }



            }
            //MTD code ends


            setSelectedColumns(newSelectedColumns);
            onHide();
        } catch (error) {
            setloading(false);
            setShowFilterErrorAlert(true);
            console.error("Failed to save user settings:", error);
        }

    };



    const handleModalHide = () => {
        setShowValidationError(false);
    };

    
    const selectedType = selectedValues.selectedType === "QTD/YTD" ? "QTD" : "MTD"
    return (
        <>
        <Modal
            show={show}
            onHide={onHide}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <div className="modal-content-filterpopup">
                <Modal.Header closeVariant="white" closeButton={!loading}>
                    <Modal.Title id="contained-modal-title-vcenter">Filter Columns</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mb-2'>{selectedType}</div>
                    <div className="column-buttons">

                        <button className={selected} type="button" onClick={handleAllBtnClick} disabled={loading}>All</button>
                        {celldata.map((x) => {
                            return (
                                <ButtonsAsColumns

                                    celldata={x[1][0].text}
                                    selected={x[1][0].isSelected}
                                    col={x[0]}
                                    handlecallbackBtnClick={handlecallbackBtnClick}
                                    disabled={loading}
                                ></ButtonsAsColumns>
                            )
                        })
                        }
                    </div>
                    {selectedValues.selectedType === "QTD/YTD" && (
                        <>
                            <div className='mb-2'>{"YTD"}</div>
                            <div className="column-buttons">
                                <button className={selectedQtdYtd} type="button" onClick={handleAllQtdYtdBtnClick} disabled={loading}>All</button>
                                {celldataYtd.map((x) => {
                                    return (
                                        <ButtonsAsColumns
                                            celldata={x[1][0].text}
                                            selected={x[1][0].isSelected}
                                            col={x[0]}
                                            handlecallbackBtnClick={handlecallbackBtnClickYtd}
                                            disabled={loading}
                                        ></ButtonsAsColumns>
                                    )
                                })
                                }
                            </div>
                        </>
                    )}
                    <div className='d-flex justify-content-end'>
                    {loading ? (
                     <SpinnerComponent text="Loading..." />
                     ) : (
                     <button className='btn btn-dark' type="button" title="Apply" onClick={handleApplyFilter}>
                      Apply</button>
                    )}
                    </div>
                    <ColumnsValidationError
                        show={showValidationError}
                        handleModalHide={handleModalHide}
                    />
                </Modal.Body>
            </div>
        </Modal>
        {showfiltererroralert && (
        <Modal show={true} size="sm">
          <ModalBody>
            <p>Error while applying filter, please try again later!</p>
            <div>
              <button
                className="btn-sm btn btn-dark"
                onClick={() => setShowFilterErrorAlert(false)}
              >
                Close
              </button>
            </div>
          </ModalBody>
        </Modal>
      )}
        </>
    )
}

export default Columns