import axios from "axios";
import Config from "../Config";
import { getToken, getUsername } from "../Components/Services/MsalService";

export const getPowerbiAccessToken = async () => {
  const response = await axios.get( 
    `${Config.apiBaseAddress}${Config.apiGetPowerbiAccessToken}?ReportID=1&UserAlias=${await getUsername()}`,
    { headers: {"Authorization" : `Bearer ${await getToken()}`}}
  ); 
  console.log(response)
       return response; 
}
