    import {
    TOGGLE_INSIGNIFICANT_BALANCE,
    TOGGLE_ZERO_BALANCES,
    CheckboxActionTypes
  } from '../Actions/checkboxActions';

  const initialState = {
    excludeInsignificantBalance: true,
    excludeZeroBalances: true
  };
  
  const checkboxReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case TOGGLE_INSIGNIFICANT_BALANCE:
        return {
          ...state,
          excludeInsignificantBalance:
            action.payload === undefined
              ? initialState.excludeInsignificantBalance
              : action.payload,
        };
      case TOGGLE_ZERO_BALANCES:
        return {
          ...state,
          excludeZeroBalances:
            action.payload === undefined
              ? initialState.excludeZeroBalances
              : action.payload,
        };
      default:
        return state;
    }
  };
    
  export default checkboxReducer;
