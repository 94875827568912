
import { SET_DEFAULT_PREFERENCEC_STATUS, SET_FILTER_SPINNER, SET_GLOBAL_SPINNER,SET_PREFRENCE_LOAD }  from "../ActionContants/constants";

export const setGlobalSpinnerAction = (data: any) => (
  {
  type: SET_GLOBAL_SPINNER,
  payload: data,
});

export const setPreferenceLoadedAction= (data: any) => ({
    type: SET_PREFRENCE_LOAD,
    payload: data,
  });

  export const setFilterSpinnerAction= (data: any) => ({
    type: SET_FILTER_SPINNER,
    payload: data,
  });

  export const setDefaultPreferenceStatusAction= (data: any) => ({
    type: SET_DEFAULT_PREFERENCEC_STATUS,
    payload: data,
  });



