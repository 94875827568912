import {UPDATE_SELECTED_OPTIONS} from '../ActionContants/constants';
 
const initialState = {
    selectedOptions:[]
}

const UpdatedSelectionReducer = (state=initialState,action:any)=>{
    switch(action.type){
        case UPDATE_SELECTED_OPTIONS:
            return{
                ...state,
                selectedOptions:action.payload,
            };
            default:
                return state;
    }
}
export default UpdatedSelectionReducer