import { NavLink } from "react-router-dom";
import HeaderLogo from "../../Assests/Images/logo.svg";
import { GetToken } from "../TokenService/TokenService";
import { ProviderState, Providers, SimpleProvider } from "@microsoft/mgt";
import { useEffect, useState } from "react";
import Config from "../../Config";
import { FiHome } from 'react-icons/fi';

const Header = ({ ...props }) => {

  //Show Avtar
  const showAvtarImg = props.showAvtar;

  useEffect(() => {
    getGraphAPIToken();
  }, []);

  const getGraphAPIToken = async () => {
    var abc = await GetToken(Config.graphApi);
    let provider = new SimpleProvider(async () => {
      return abc;
    });
    Providers.globalProvider = provider;
    Providers.globalProvider.setState(ProviderState.SignedIn);
  };

  return (
    <header className="header">
      <div className="logo-app-wrap">
        <NavLink className="logo-link" to="/">
          <img src={HeaderLogo} alt="Logo" />
        </NavLink>
        <span className="app-name">Financial Reporting, AI and ML Experience</span>
        <span className="app-shortname">FRAME</span>
      </div>
      <div className="right-buttons">
        <NavLink className="home-link" title="Home" to="/"><FiHome /></NavLink>
        <div className="divider">&nbsp;</div>
        {showAvtarImg && <mgt-person person-query="me"></mgt-person>}
      </div>
    </header>
  );
};

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "mgt-login": any;
      "mgt-person": any;
      "mgt-msal-provider": any;
    }
  }
}

export default Header;
