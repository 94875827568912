import Header from './Header/Header';
import Footer from './Footer/Footer';
import AccessError from '../Pages/AccessError';

function MercuryAccessLayout() {
  return (
    <div className="App layout access-error">
      <Header showAvtar={true} />
      <AccessError />
      <Footer showMercuryRefreshTime={false} />
    </div>
  )
}

export default MercuryAccessLayout