import {
  SET_FILTER_PREFERENCES,
  SET_SELECTED_PREFERENCES_DATA,
  ERROR_PREFERENCES_DATA,
  SET_SELECTED_FILTER_PREFERENCE,
  SET_DEFAULT_FILTER_PREFERENCE,
  SET_IS_PREFERENCE_CHANGED,
  INIT_PREFERENCES,
} from "../ActionContants/constants";
// import {v4 as uuid4} from "uuid";

interface preferenceDataState {
  preferenceData?: preferenceData;
  preferenceError: any;
  selectedFilterPreference: any;
}
interface preferenceData {
  preferenceDataValue?: any;
}
const initialState = {
  filterPreferncesList: [],
  preferenceData: {},
  defaultFilterPreference: [],
  selectedFilterPreference: [],
  intialdefaultFilterPreference: null,
  preferenceError: { preferenceError: "" },
  isPreferenceChanged : {}
};
const preferenceDataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_FILTER_PREFERENCES:
      let prefData = {
        ...action.payload,
        preference: action.payload.preference?.map(
          (e: any) => ({ ...e, value: e.preferenceID } || [])
        ),
      };
      const filterPreferncesList =
        prefData.preference?.map((pref: any) => ({
          value: pref.value,
          label: pref.preferenceName.toString(),
        })) || [];
      let selectedFilterPreference =
        prefData.preference?.filter((e: any) => e.isDefault === true) || [];
      selectedFilterPreference =
        selectedFilterPreference.map((pref: any) => ({
          value: pref.value,
          label: pref.preferenceName.toString(),
        })) || [];
      return {
        ...state,
        filterPreferncesList: filterPreferncesList,
        preferenceData: prefData,
        selectedFilterPreference: selectedFilterPreference,
        defaultFilterPreference: selectedFilterPreference,
      };
    case SET_SELECTED_PREFERENCES_DATA:
      return {
        ...state,
        //selectedFilterPreference: action.payload
      };
    case SET_SELECTED_FILTER_PREFERENCE:
      console.log("action.payload in SET_SELECTED_FILTER_PREFERENCE:-",action);
      //alert('pdata');
      return {
        ...state,
        selectedFilterPreference: action.payload,
        defaultFilterPreference: action.payload,
      };

    case SET_DEFAULT_FILTER_PREFERENCE:
      // console.log("action.payload in SET_SELECTED_FILTER_PREFERENCE:-",action)
      return {
        ...state,
        intialdefaultFilterPreference: action.payload,
      };

      case SET_IS_PREFERENCE_CHANGED:
      console.log("action.payload in SET_IS_PREFERENCE_CHANGED:-",action.payload);
      return {
        ...state,
        isPreferenceChanged: action.payload,
      };
    case ERROR_PREFERENCES_DATA:
      return {
        ...state,
        errors: {
          ...state.preferenceError,
          preferenceError:
            "Error in getting the preference, please try again later!",
        },
      };

    default:
      return state;
  }
};

export default preferenceDataReducer;
