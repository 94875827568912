import { SET_SELECTED_SORT_ITEMS, SET_SORT_DATA, SORT_TOGGLE_CHECKED,SET_SHOWFIELDSFLAG, SORT_SORT_CHECKED_NULL, SET_COMMENTARY_STATUS, SET_USER_ADMINSTRATION_ICON_STATUS,SET_ROLEID_STATUS,SET_CAN_VIEW_STATUS,SET_CAN_ENTER_STATUS } from "../ActionContants/constants";

interface SortItem {
  columnName: string;
  columnHeader: string;
  checked: boolean;
}

interface FieldSortState {
  sortData: SortItem[];
  selectedSortItems:SortItem[];
  showFieldsFlag: boolean;
  chkOpt?:boolean;
  Commentarystatus:boolean;
  RoleID:boolean;
  UserAdministrationIcon:boolean;
  CanView:boolean;
  CanEnter:boolean;
}

const initialState: FieldSortState = {
  sortData: [],
  selectedSortItems:[],
  showFieldsFlag: false,
  Commentarystatus:false,
  RoleID: false,
  UserAdministrationIcon: false,
  CanView: false,
  CanEnter: false
};

const fieldSortReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case SET_SORT_DATA:
      return {
        ...state,
        sortData: action.payload,
      };
      case SET_SELECTED_SORT_ITEMS:
      return {
        ...state,
        selectedSortItems: action.payload,
      };
    case SORT_TOGGLE_CHECKED:
      return {
        ...state,
        chkOpt:!state.chkOpt,
        sortData: state.sortData.map((item: SortItem) =>
          item.columnName === action.payload
            ? { ...item, checked: !item.checked }
            : item,
            
        ),
      };
    case SET_SHOWFIELDSFLAG:
      return {
        ...state,
        showFieldsFlag:action.payload
      };

      case SORT_SORT_CHECKED_NULL:
        return {
          ...state,
          chkOpt:undefined
        };

        case SET_COMMENTARY_STATUS:
          return {
            ...state,
            Commentarystatus: action.payload,
          };

          case SET_ROLEID_STATUS:
          return {
            ...state,
            RoleID: action.payload,
          };
          case SET_USER_ADMINSTRATION_ICON_STATUS:
          return {
            ...state,
            UserAdministrationIcon: action.payload,
          };
          case SET_CAN_VIEW_STATUS:
          return {
            ...state,
            CanView: action.payload,
          };
          case SET_CAN_ENTER_STATUS:
          return {
            ...state,
            CanEnter: action.payload,
          };
          
    default:
      return state;
  }
};

export default fieldSortReducer;
