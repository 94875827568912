import {
  SET_EXPORT_DATA,
  ERROR_EXPORT_DATA,
} from "../ActionContants/constants";
import Config from "../../Config";
import axios from "axios";
import { isNonNullExpression } from "typescript";
import { getToken } from "../../Components/Services/MsalService";

const TIMEOUT_DURATION = 660000;
const apiClient = axios.create({
  baseURL:Config.apiBaseAddress,
  timeout:TIMEOUT_DURATION
})

export const exportToExcelAction = async (payload: any) => {
  try {
    // 
   // const response = await axios.post(
    const response = await apiClient.post(
      `${Config.apiBaseAddress}${Config.apiExportToExcel}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getToken()}`,
        },
        responseType: "arraybuffer",
      }
    );
    // console.log(response);
    // payload({ type: "FETCH_SUCCESS", payload: response.data });
    if (response.data) {
      return {
        type: SET_EXPORT_DATA,
        payload: response,
      };
    } else {
      return {
        type: SET_EXPORT_DATA,
        payload: null,
      };
    }
    return {
      type: ERROR_EXPORT_DATA,
    };
  } catch (error) {
    console.error("Failed to fetch exported data:", error);
    // payload({ type: "FETCH_ERROR", payload: error });
    return {
      type: ERROR_EXPORT_DATA,
    };
  }
};
