import { SET_PARENT_ID_AND_COMMENTS, CLEAR_COMMENT_DATA } from "../ActionContants/constants";


interface CommentPayload {
  desc: string;
  commentList: any[]; 
  parentID: string;
  selectedFiscalMonthID: string;
  selectedSAPCompanyID: string;
}

export const setCommentByParent = (payload: CommentPayload) => ({
  type: SET_PARENT_ID_AND_COMMENTS,
  payload,
});

export const clearCommentData = () => ({
  type: CLEAR_COMMENT_DATA,
});
