import React, { useEffect, useState } from "react";
import { FiPlusSquare, FiMinusSquare } from "react-icons/fi";
import { BiCommentAdd, BiCommentDetail } from "react-icons/bi";
import Commentary from "../Commentary/Commentary";
import { useDispatch, useSelector } from "react-redux";
import { setExpandAllAction } from "../../Redux/Actions/qtdDataActions";
import { RootReducer } from "../../Redux/Reducers/types";
import { setCommentByParent } from "../../Redux/Actions/commentActions";



interface Props {
  GroupName: any;
  ischild: any;
  Expanded: any;
  id: any;
  celldata: any;
  width: any;
  iscolHeader: any;
  handlecallback: (val: any, state: any) => void;
  checkNm?: boolean;
  GroupType?: any;
  colIndex: any;
  activeButton: any;
  handlecallbackActiveButton: (val: any) => void;
  commentslst: any;
  length: any;
}

export const Cell: React.FC<Props> = ({
  GroupName,
  ischild,
  Expanded,
  id,
  celldata,
  width,
  iscolHeader,
  handlecallback,
  checkNm,
  GroupType,
  colIndex,
  activeButton,
  handlecallbackActiveButton,
  commentslst,
  length
}) => {
  // 
  //console.log("commmmmmm => ",commentslst);
  const dispatch = useDispatch();
  const Column = useSelector((state: RootReducer) => state.gridData.gridData.gridColumnHeaders);
  const store = useSelector((state: RootReducer) => state);
  const testNegativeZero = (num: any) => {
    return (num === 0 && 1 / num === -Infinity);
  };
  const fieldSort = useSelector(
    (state: RootReducer) => state.fieldSort
  );

  let commentclassName = `${"commentary-row-icon "}${fieldSort.Commentarystatus ? "showC" : "hideC"}`;
  let AddcommentclassName = `${"commentary-row-icon2 "}${fieldSort.Commentarystatus ? "showC" : "hideC"}`;
  let AddnewcommentclassName = `${"commentary-row-icon3 "}${fieldSort.Commentarystatus ? "showC" : "hideC"}`;

  const FormatData = (celldata: any) => {


    //if (celldata == "" || celldata == null || isNaN(celldata)) return celldata;

    // Check for negative zero using Object.is
    if (testNegativeZero(celldata)) return "(0)";
    if (celldata == "" || celldata == null || isNaN(celldata)) return celldata;

    if (GroupName == 'percentageVar' || GroupName == 'cpHPercentage' || GroupName == 'qtdPercentageVar' || GroupName == 'cpHQTDPercentage' || GroupName == 'ytdPercentageVar' || GroupName == 'cpHYTDPercentage')
      return (Math.sign(celldata) == -1) ? `(${Math.abs(celldata).toLocaleString(navigator.language, { minimumFractionDigits: 1 })})` : celldata.toLocaleString(navigator.language, { minimumFractionDigits: 0 });

    return (Math.sign(celldata) == -1) ? `(${Math.abs(parseFloat(celldata)).toLocaleString(navigator.language, { minimumFractionDigits: 0 })})` : celldata.toLocaleString(navigator.language, { minimumFractionDigits: 0 });
  }
  const Modifieddata = checkNm ? (parseFloat(celldata) > 200 || parseFloat(celldata) < -200) ? 'nm' : FormatData(celldata) : FormatData(celldata);
  const expandclick = (e: any) => {
    handlecallback(
      e.currentTarget.value.split("_")[0],
      e.currentTarget.value.split("_")[1]
    );
  };
  const handleExpandCollapse = (e: any) => {
    handlecallbackActiveButton(e.currentTarget.value);
    dispatch(setExpandAllAction(!activeButton));
  };

  // Commentary Modal
  const [commentryModalShow, setCommentryModalShow] = React.useState(false);
  const [showBlankModal, setShowBlankModal] = useState(false);
  const [newcomment, setNewcomment] = useState(false);

  const handlenewcommentClick = () => {
    setNewcomment(true);
  }

  const handleOpenBlankModal = () => {
    setShowBlankModal(true);
  };
  const handleCommentryModalShow = () => {
    setCommentryModalShow(true);
  }

  let showFirstColItem = colIndex === "col1" ? true : false;
  const removeFrstColLength = length - 1;
  const firstHeaderColumnRowWidth = 24 + "%";
  const dynamicColumnsWidth = 76 / removeFrstColLength + "%";
  let colWidth = colIndex === "col1" ? firstHeaderColumnRowWidth : colIndex === "desc" ? firstHeaderColumnRowWidth : dynamicColumnsWidth;
  const hasFiscalMonthComment = (comments: any[]) => {
    return comments && comments.some(comment => comment.isFiscalmonth);
  };
  const hasFiscalMonthCommentDetails = (comments: any[]) => {
    return (
      comments && comments.some(comment =>
        comment.isFiscalmonth && comment.isSystemGenerated === false
      )
    );
  };


  const getLastValueAsInt = (idString: string): number => {
    const parts = idString.split('-');
    const lastPart = parts[parts.length - 1];
    return parseInt(lastPart, 10);
  }
  const [localCommentsList, setLocalCommentsList] = useState(commentslst);

 
  const updateCommentsList = (newComment: any) => {
    setLocalCommentsList(newComment);
  };

  return (
    <>

      <div className="tree-column" style={{ width: "100%" }}>
        {
          showFirstColItem &&
          <div className="expand-collapse-wrap">
            {!activeButton ? (
              <button
                type="button"
                className="c-btn-icon p-0 ms-1 me-2"
                value={"Expand"}
                onClick={handleExpandCollapse}
              >
                <FiPlusSquare className="icon-plus" /> Expand All
              </button>
            ) : (
              <button
                type="button"
                className="c-btn-icon p-0 ms-1 me-2"
                value={"Collapse"}
                onClick={handleExpandCollapse}
              >
                <FiMinusSquare className="icon-minus" />          Collapse All

              </button>
            )}
          </div>
        }
        {
          (GroupName == 'desc' && !iscolHeader) && <span style={{ "paddingLeft": width }}></span>
        }
        {
          (ischild && GroupName == 'desc' && Expanded && !iscolHeader) ?
            <button type="button" className="c-btn-icon-only ms-1 me-2" onClick={expandclick} value={id + "_Collapse"} title="Collapse" aria-label="Collapse"><FiMinusSquare className="icon-minus" /></button> :
            (ischild && GroupName == 'desc' && !iscolHeader) ?
              <button type="button" className="c-btn-icon-only ms-1 me-2" onClick={expandclick} value={id + "_Expand"} title="Expand" aria-label="Expand"><FiPlusSquare className="icon-plus" /></button>
              : (GroupName == 'desc' && !iscolHeader) && <span style={{ "paddingLeft": !iscolHeader ? "30px" : "0px" }}></span>
        }
        {/* //style={{width:'300px'}} */}
        {
          (GroupName == 'desc' && iscolHeader) ? "" :
            <label title={GroupName == 'desc' ? GroupType : Column[GroupName]}  >{Modifieddata}</label>
        }
        {/* GroupType == 'Class' && GroupName == 'desc' */}


        {
          (localCommentsList != null && localCommentsList.length > 0 && GroupName == 'desc') ? (
            <>
              {
                store.fieldSort.CanEnter ? (
                  <div className={AddcommentclassName + " child-div"}>
                    <BiCommentAdd onClick={handleOpenBlankModal} />
                  </div>
                ) : (
                  <div className={AddcommentclassName + " child-div"}>
                    {/* <BiCommentAdd onClick={handleOpenBlankModal} /> */}
                  </div>
                )
              }
              {
                hasFiscalMonthCommentDetails(localCommentsList) && store.fieldSort.CanView && (
                  <div className={commentclassName}><BiCommentDetail onClick={handleCommentryModalShow} /></div>
                )
              }
            </>
          ) : (
            (GroupName == 'desc' && (GroupType == "Class" || GroupType == "Sub Class" || GroupType == "Account No Description")) && (
              <>
                {
                  store.fieldSort.CanEnter ? (
                    <div className={AddnewcommentclassName + " child-div"}>
                      <BiCommentAdd onClick={handlenewcommentClick} />
                    </div>
                  ) : (
                    <div className={AddnewcommentclassName + " child-div"}>
                      {/* <BiCommentAdd onClick={handlenewcommentClick} /> */}
                    </div>
                  )
                }
              </>
            )
          )
        }

      </div>


      {
        GroupName === 'desc' && (
          <>
            {localCommentsList?.length > 0 && (
              <>
                <Commentary
                  show={commentryModalShow}
                  onHide={() => setCommentryModalShow(false)}
                  commentslst={localCommentsList}
                  showAddCommentsSection={false}
                  shownewaddcomment={false}
                  showviewhistory={true}
                  title={celldata}
                  parentID={getLastValueAsInt(id)}
                  entity={GroupType}
                  onUpdateCommentsList={updateCommentsList}
                  setmodalshow={true}
                />
                <Commentary
                  show={showBlankModal}
                  onHide={() => setShowBlankModal(false)}
                  commentslst={localCommentsList}
                  showAddCommentsSection={true}
                  shownewaddcomment={false}
                  showviewhistory={false}
                  title={celldata}
                  parentID={getLastValueAsInt(id)}
                  entity={GroupType}
                  onUpdateCommentsList={updateCommentsList}
                  setmodalshow={true}
                />
              </>
            )}
            {(GroupType === "Class" || GroupType === "Sub Class" || GroupType === "Account No Description") && (
              <Commentary
                show={newcomment}
                onHide={() => setNewcomment(false)}
                commentslst={[]}
                showAddCommentsSection={false}
                shownewaddcomment={true}
                showviewhistory={false}
                title={celldata}
                parentID={getLastValueAsInt(id)}
                entity={GroupType}
                onUpdateCommentsList={updateCommentsList}
                setmodalshow={true}
              />
            )}
          </>
        )
      }


      {/* Commentary Modal */}
    </>
  );
};
