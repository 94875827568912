import React, {useState} from 'react'
import { Outlet } from 'react-router-dom'
import DataGridControl from "../Components/GridController/DataGridControl";

function Content() {

    // Showing Export Modal Popup
    const [modalShow, setModalShow] = useState(false);
    const handleModalHide = () => setModalShow(false);
    const handleModalShow = () => setModalShow(true);

    // Showing Filter Slide Panel
    const [show, setShow] = useState(false);
    const handleSlidePanalClose = () => setShow(false);
    const handleSlidePanalShow = () => setShow(true);

    // Toggle Filter Slide Panel
    const [toggleView, setToggleView] = useState(true);
    const handleGridChartToggle = () => setToggleView(!toggleView);

  return (
    <>
      {/* <DataGridControl handleShow={handleSlidePanalShow} handleModalShow={handleModalShow} handleGridChartToggle={handleGridChartToggle} /> */}
      <main className="content">
        <Outlet />
      </main>
    </>
  )
}

export default Content