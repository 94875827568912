import React from 'react'
import Spinner from 'react-bootstrap/esm/Spinner';

interface SpinnerProps {
    text: string;
}

const GlobalSpinner: React.FC<SpinnerProps> = ({ text }) => {
    return (
        <div className='global-spinner'>
            <Spinner animation="border" role="status">
                <span className="visually-hidden">{text == "" ? "Loading...":text}</span>
            </Spinner>
            <div className="global-spinner-overlay"></div>
        </div>
    )

}

 

export default GlobalSpinner