import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/esm/Form";
// import  Checkbox  from 'react-bootstrap';
import { MultiSelect } from "react-multi-select-component";

interface Option {
  value: string;
  label: string;
}
interface MultiSelectProps {
  options: Option[];
  selectedOptions: Option[];
  initialSelectedOptions: Option[];
  onSelectAllChange: (
    event: any,
    selectedOptions: Option[],
    group: string
  ) => void;
  onChange: (selectedOptions: Option[]) => void;
  label: string;
  group: string;
  onMenuToggle?: (
    isOpen: boolean,
    selectedOptions: Option[],
    group: string,
    isFieldDisabled: boolean
  ) => void;
  isFieldDisabled?: boolean;
  isLoading?: boolean;
}

const MultiSelectAll: React.FC<MultiSelectProps> = ({
  options,
  selectedOptions,
  initialSelectedOptions,
  onSelectAllChange,
  onChange,
  label,
  group,
  onMenuToggle,
  isFieldDisabled = false,
  isLoading,
}) => {
  //  const dispatch = useDispatch();
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(options.length > 0 && initialSelectedOptions.length === options.length);
  const [updatedSelectedOptions, setUpdatedSelectedOptions] = useState<
    Option[]
  >(selectedOptions);
  const [validationError, setValidationError] = useState<boolean>(false);


  useEffect(() => {
    //if(initialSelectedOptions.length>0)
    setUpdatedSelectedOptions(initialSelectedOptions);
    setSelectAllChecked(
      options.length > 0 && initialSelectedOptions.length === options.length
    );
  }, [initialSelectedOptions]);

  useEffect(() => {
    setUpdatedSelectedOptions(selectedOptions);
    setValidationError(false);
  }, [selectedOptions]);

  const customValueRenderer = () => {
    if (
      updatedSelectedOptions.length !== 0 &&
      updatedSelectedOptions.length === options.length &&
      !isFieldDisabled
    )
      setSelectAllChecked(true);
    else setSelectAllChecked(false);
    return updatedSelectedOptions.length === options.length &&
      updatedSelectedOptions.length !== 0 &&
      !isFieldDisabled
      ? "All items are selected"
      : options.length === 0 && !isFieldDisabled
        ? "Loading..."
        : isFieldDisabled
          ? ""
          : `${updatedSelectedOptions.length} item(s) selected`;
  };
  const handleSelectAllChange = (e: any) => {
    const { checked } = e.target;
    if (checked) {
      setUpdatedSelectedOptions(options);
      setValidationError(false);
      onChange(options)
    } else {
      setUpdatedSelectedOptions([]);
      setValidationError(true);
      onChange([])
    }
    setSelectAllChecked(checked);
  };
  const handleSelectChange = (selectedItems: Option[]) => {
    setUpdatedSelectedOptions(selectedItems);
    onChange(selectedItems);
    if (options.length === selectedItems.length) {
      setSelectAllChecked(true);
      setValidationError(false);
    } else {
      setSelectAllChecked(false);
      setValidationError(selectedItems.length === 0);
    }
  };
  return (
    <div className="mb-3">
      <div
        className={`multiselect-label-with-selectall ${isFieldDisabled ? "disabled" : ""
          }`}
      >
        <label className="field-label" htmlFor={label}>
          {label}
          <span> *</span>
        </label>
        <div className="d-flex">
          {
            label === "Company Code" ?
              <span>Max 10</span>
              :
              <Form.Check
                label="Select All"
                type="checkbox"
                disabled={isFieldDisabled}
                onChange={(e) => handleSelectAllChange(e)}
                checked={selectAllChecked}
                aria-label={label}
                id={label + 1}
              />}
        </div>
      </div>

      <MultiSelect
        options={options}
        value={updatedSelectedOptions}
        valueRenderer={customValueRenderer}
        onChange={handleSelectChange}
        onMenuToggle={(isOpen: any) =>
          onMenuToggle != null &&
          onMenuToggle(isOpen, updatedSelectedOptions, group, isFieldDisabled)
        }
        labelledBy={label}
        disabled={isFieldDisabled}
        hasSelectAll={false}
        isLoading={isLoading}
        className={label === "Company Code" ? "company-code-dropdown" : ""}
      />
      {validationError && !isFieldDisabled && (
        <div className="form-validation-error">Please select option(s).</div>
      )}
    </div>
  );
};

export default MultiSelectAll;
