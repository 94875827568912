import Offcanvas from 'react-bootstrap/Offcanvas';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import FieldSort from './FieldOptions/FieldSort';
import FieldFilter from './FieldOptions/FieldFilter';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Config from '../Config';
import axios from 'axios';
import { RootReducer } from '../Redux/Reducers/types';
import { getToken, getUsername } from './Services/MsalService';
import { setSortData } from '../Redux/Actions/fieldSortAction';
import { setFilterPreferencesDataAction } from '../Redux/Actions/preferencesDataActions';
import SpinnerComponent from './Common/SpinnerComponent';
import FilterSpinner from './Common/FilterSpinner';
import { setFilterSpinnerAction } from '../Redux/Actions/CommonActionTypes';

import { useCombinedContext } from '../Pages/PowerBIContext';

const SlidePanel = ({ ...props }) => {
    const dispatch = useDispatch();
    const [tabContent,setTabContent] = useState("sort");
    const handleTabContent = (tab: string) => setTabContent(tab);
    const handlefiltercallback = () =>setTabContent("filter");

      const { sortData } = useSelector((state: RootReducer) => state.fieldSort)
      const commonStoreData = useSelector(
        (state: RootReducer) => state.commonStore
      );
    const [loading, setLoading] = useState(true);
    const handleSlidePanelshow = () => {
        setTabContent("sort");
        props.handleSlidePanalClose();
    }

    const { setApplyfilter } = useCombinedContext();

    useEffect(()=>{
      setFilterSpinnerRedux(true)
      if(sortData.filter((e:any)=>e.checked).length===0)
      fetchSortData();
      setFilterSpinnerRedux(false);
    },[])

    const fetchSortData = async () => {
        await axios
          .get(`${Config.apiBaseAddress}${Config.apiGetSortOptions}`, { headers: {"Authorization" : `Bearer ${await getToken()}`}})
          .then((response) => {
            const dataWithCheckedfalse = response.data.map((item: any) => ({
              ...item,
              checked: false,
            }));
            dispatch(setSortData(dataWithCheckedfalse));
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
            setFilterSpinnerAction(false);
          });
      }

      const setFilterSpinnerRedux = async (val:boolean) => {
        dispatch(await setFilterSpinnerAction(val));
       }
       const handleclose = () => {
        setApplyfilter(false);
       }
   useEffect(() => {
    
      setLoading(commonStoreData.EnableFilterSpinner);
        }, [commonStoreData.EnableFilterSpinner]);
    return (
      <Offcanvas show={props.show} onHide={props.handleSlidePanalClose} backdrop="static" placement={"end"}>
      <Offcanvas.Header closeButton closeVariant="white" onClick = {handleclose}>
          <Offcanvas.Title>Field Options</Offcanvas.Title>
      </Offcanvas.Header>
      {loading && <FilterSpinner text="Loading..." />}
      <Offcanvas.Body>
     <Tab.Container id="#" defaultActiveKey="sort" activeKey={tabContent}>
              <Nav variant="pills" className="mb-3">
                  <Nav.Item>
                      <Nav.Link eventKey="sort" onClick={()=> handleTabContent("sort")} className="mr-2">Sort</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                      <Nav.Link eventKey="filter" onClick={()=> handleTabContent("filter")}>Filter</Nav.Link>
                  </Nav.Item>
              </Nav>
               <Tab.Content>
                <Tab.Pane eventKey="sort" >
                      <FieldSort displayFilterTab={handlefiltercallback}  />
                  </Tab.Pane>
                  <Tab.Pane eventKey="filter" >
                      <FieldFilter handleSlidePanelshow={handleSlidePanelshow}  />
                  </Tab.Pane>
              </Tab.Content> 
          </Tab.Container>
      </Offcanvas.Body>
      </Offcanvas>
    );
}

export default SlidePanel;


