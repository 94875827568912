
import { SET_DEFAULT_PREFERENCEC_STATUS, SET_FILTER_SPINNER, SET_GLOBAL_SPINNER,SET_PREFRENCE_LOAD }  from "../ActionContants/constants";

interface Spinner {
  EnableSpinner: boolean;
  LoadPreference: boolean;
  EnableFilterSpinner: boolean;
  DefaultPrefrenceStatus: boolean;


}
const initialState: Spinner = {
    EnableSpinner: false,
    LoadPreference:false,
    EnableFilterSpinner:false,
    DefaultPrefrenceStatus:false
};

const CommonReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case SET_GLOBAL_SPINNER:
      return {
        ...state,
        EnableSpinner: action.payload,
      };
      case SET_PREFRENCE_LOAD:
        return {
          ...state,
          LoadPreference: action.payload,
        };
        case SET_FILTER_SPINNER:
          return {
            ...state,
            EnableFilterSpinner: action.payload,
          };
          case SET_DEFAULT_PREFERENCEC_STATUS:
            return {
              ...state,
              DefaultPrefrenceStatus: action.payload,
            };
    default:
      return state;
  }
};

export default CommonReducer;
