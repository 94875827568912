import { SET_FILTER_GRID_COL_DATA } from "../ActionContants/constants";

interface FilterGridHeaderSelect {
  ColHeaders: string[],
}

const initialState: FilterGridHeaderSelect = {
  ColHeaders: []
}

const filterGridColHeaderReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_FILTER_GRID_COL_DATA:
      return {
        ...state,
        ColHeaders: action.payload,
      };
    default:
      return state;
  }
}

export default filterGridColHeaderReducer;
