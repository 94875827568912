import {
  SET_QTD_DATA,
  SET_SELECTED_QTD_DATA,
  ERROR_QTD_DATA,
  SET_TD_TYPE,
  SET_EXPAND_COLLAPSE_TYPE,
  INIT_GRID_DATA,
  SET_EXPANDALL,
  SET_GET_SEGMENTAL_DATA_QTD_API,
  SET_FILTER_GRID_COL_DATA,
  SET_HEADER_GRID_COL_DATA
} from "../ActionContants/constants";
import Config from "../../Config";
import axios from "axios";
import { isNonNullExpression } from "typescript";
import { getToken } from "../../Components/Services/MsalService";

const TIMEOUT_DURATION = 500000;

const apiClient = axios.create({
  baseURL: Config.apiBaseAddress,
  timeout: TIMEOUT_DURATION,
});

export const setGridDataAction = async (payload: any) => {

  payload.browserTabUniqueId = sessionStorage.getItem('BrowserTabUniqueId');
  try {

    const response = await apiClient.post(
      `${Config.apiBaseAddress}${Config.apiGetActualsClasses}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getToken()}`,
        },
      }
    );

    const data = response.data;
    const apiRes = response.status;
    if (response.status === 200) {
      
      return {
        type: SET_QTD_DATA,
        payload: data,
        apiResp: apiRes,
      };
    } else if (response.status === 500) {
      return {
        type: SET_QTD_DATA,
        apiResp: apiRes,
      };
    } else {
      return {
        type: SET_QTD_DATA,
        payload: null,
      };
    }
  }
   catch (error) {

    console.error("Failed to fetch classes:", error);
    const apiRes = 500;
    if (apiRes == 500) {
      return {
        type: SET_QTD_DATA,
        payload: null,
        apiResp: 500,
      };
    } else {
      return {
        type: ERROR_QTD_DATA,
      };
    }
  }
};

export const setGridDataActionQtdYtd = async (payload: any) => {

  payload.browserTabUniqueId = sessionStorage.getItem('BrowserTabUniqueId');
  try {

    const response = await apiClient.post(
      `${Config.apiBaseAddress}${Config.apiGetActualsClassesQtdYtd}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getToken()}`,
        },
      }
    );

    const data = response.data;
    const apiRes = response.status;
    if (response.status === 200) {
      
      return {
        type: SET_QTD_DATA,
        payload: data,
        apiResp: apiRes,
      };
    } else if (response.status === 500) {
      return {
        type: SET_QTD_DATA,
        apiResp: apiRes,
      };
    } else {
      return {
        type: SET_QTD_DATA,
        payload: null,
      };
    }
  }
   catch (error) {

    console.error("Failed to fetch classes:", error);
    const apiRes = 500;
    if (apiRes == 500) {
      return {
        type: SET_QTD_DATA,
        payload: null,
        apiResp: 500,
      };
    } else {
      return {
        type: ERROR_QTD_DATA,
      };
    }
  }
};

export const setTDTypeAction = (Type: string) => ({
  type: SET_TD_TYPE,
  payload: Type,
});

export const setExpandCollapseAction = (Status: string) => ({
  type: SET_EXPAND_COLLAPSE_TYPE,
  payload: Status,
});

export const setEmptyGridDataAction = () => ({
  type: INIT_GRID_DATA,
  payload: {
    gridData: [],
    gridColumnHeaders: {},
  },
});

export const setExpandAllAction = (Status: any) => ({
  type: SET_EXPANDALL,
  payload: Status,
});

export const setGridAction = (payload: any) => ({
  type: SET_QTD_DATA,
  payload: payload
});

export const setFilterGridColumnAction = (payload: string[]) => ({
  type: SET_FILTER_GRID_COL_DATA,
  payload: payload
});

export const setFilterGridHeaderAction = (payload: string[]) => ({
  type: SET_HEADER_GRID_COL_DATA,
  payload: payload
});
