import { isTemplateExpression } from "typescript";
import store from "../../Redux/Store/Store";

export const exportToExcelValues = (storeData = store.getState()) => {
  const totalGroupClassesCount = storeData.cascadingDropDown.groupclasses.length;
  const totalLineitemCount = storeData.cascadingDropDown.lineitem.length;
  const totalFinaccountCount = storeData.cascadingDropDown.finaccount.length;
  const totalOrderCount = storeData.cascadingDropDown.order.length;
  const totalProfitcenterCount = storeData.cascadingDropDown.profitcenter.length;
  const totalCostCenterCount = storeData.cascadingDropDown.costcenter.length;
  const totalOrgCount = storeData.cascadingDropDown.org.length;
  const totalOrgsummaryCount = storeData.cascadingDropDown.orgsummary.length;
  
  console.log("store data -->",storeData)
  return {
    selectedFiscalMonth: storeData.singleSelect.selectedFiscalMonths.length > 0 ? [
      {
        fiscalMonth:  storeData.singleSelect.selectedFiscalMonths[0].label,
        fiscalMonthID: storeData.singleSelect.selectedFiscalMonths[0].value,
        currentFiscalMonthFlagID: 0,
      },
    ]:[],
    selectedCurrencyType: storeData.singleSelect.selectedCurrencyTypes.length > 0? storeData.singleSelect.selectedCurrencyTypes.map(
      (item: any) => {
        return {
          currencyTypeHierarchyID: item.value,
          currencyTypeHierarchyName: item.label,
        };
      }
    ):[],

    selectedCompanies: storeData.customMultiSelect.selectedSAPCompanyCodes.length>0 ?storeData.customMultiSelect.selectedSAPCompanyCodes.map(
      (item: any) => {
        return {
          companyCode: item.value,
          sapCompanyCodeName: item.label,
        };
      }
    ):[],
 

    selectedClass: storeData.cascadingDropDown.selectedClassesValue.length > 0 ? storeData.cascadingDropDown.selectedClassesValue.map(
      (item: any) => {
        return {
          channelClassId: item.value,
          channelClassDesc: item.label,
        };
      }
    ):[],

    selectedSubClass: storeData.cascadingDropDown.selectedSubClassesValue.length > 0 ? storeData.cascadingDropDown.selectedSubClassesValue.map(
      (item: any) => {
        return {
          channelSubClassId: item.value,
          channelSubClassDesc: item.label,
        };
      }
    ):[],
    selectedChannelGroup: 
    storeData.cascadingDropDown.selectedGroupClassesValue.length === 0 || 
    storeData.cascadingDropDown.selectedGroupClassesValue.length === totalGroupClassesCount ? 
    [{
      channelGroupClassId: 0,
      channelGroupClassDesc: 'ALL',
    }] :
    storeData.cascadingDropDown.selectedGroupClassesValue.map((item: any) => {
      return {
        channelGroupClassId: item.value,
        channelGroupClassDesc: item.label,
      };
    }),

    selectedChannelLineItem:
storeData.cascadingDropDown.selectedLineItemValue.length === 0 || 
storeData.cascadingDropDown.selectedLineItemValue.length === totalLineitemCount ? 
[{
  channelLineItemId: 0,
  channelLineItemDesc: 'ALL',
}] :
storeData.cascadingDropDown.selectedLineItemValue.map((item: any) => {
  return {
    channelLineItemId: item.value,
    channelLineItemDesc: item.label,
  };
}),

selectedFinancialAccount: 
storeData.cascadingDropDown.selectedFinAccountValue.length === 0 || 
storeData.cascadingDropDown.selectedFinAccountValue.length === totalFinaccountCount ? 
[{
  financialAccountId: 0,
  financialAccountCodeDesc: 'ALL',
}] :
storeData.cascadingDropDown.selectedFinAccountValue.map((item: any) => {
  return {
    financialAccountId: item.value,
    financialAccountCodeDesc: item.label,
  };
}),

selectedInternalOrder: 
storeData.cascadingDropDown.selectedOrderValue.length === 0 || 
storeData.cascadingDropDown.selectedOrderValue.length === totalOrderCount ? 
[{
  internalOrderID: 0,
  internalOrderDesc: 'ALL',
}] :
storeData.cascadingDropDown.selectedOrderValue.map((item: any) => {
  return {
    internalOrderID: item.value,
    internalOrderDesc: item.label,
  };
}),

selectedProfitCenter: 
storeData.cascadingDropDown.selectedProfitCenterValue.length === 0 || 
storeData.cascadingDropDown.selectedProfitCenterValue.length === totalProfitcenterCount ? 
[{
  profitCenterID: 0,
  profitCenterCodeDesc: 'ALL',
}] :
storeData.cascadingDropDown.selectedProfitCenterValue.map((item: any) => {
  return {
    profitCenterID: item.value,
    profitCenterCodeDesc: item.label,
  };
}),

selectedCostCenter:
storeData.cascadingDropDown.selectedCostCenterValue.length === 0 || 
storeData.cascadingDropDown.selectedCostCenterValue.length === totalCostCenterCount ? 
[{
  costCenterId: 0,
  costCenterDesc: 'ALL',
}] :
storeData.cascadingDropDown.selectedCostCenterValue.map((item: any) => {
  return {
    costCenterId: item.value,
    costCenterDesc: item.label,
  };
}),

selectExecOrg: 
storeData.cascadingDropDown.selectedOrgValue.length === 0 || 
storeData.cascadingDropDown.selectedOrgValue.length === totalOrgCount ? 
[{
  channelOrgId: 0,
  channelOrgDesc: 'ALL',
}] :
storeData.cascadingDropDown.selectedOrgValue.map((item: any) => {
  return {
    channelOrgId: item.value,
    channelOrgDesc: item.label,
  };
}),

selectExecOrgSummary: 
storeData.cascadingDropDown.selectedOrgSummaryValue.length === 0 || 
storeData.cascadingDropDown.selectedOrgSummaryValue.length === totalOrgsummaryCount ? 
[{
  channelOrgSummaryId: 0,
  channelOrgSummaryDesc: 'ALL',
}] :
storeData.cascadingDropDown.selectedOrgSummaryValue.map((item: any) => {
  return {
    channelOrgSummaryId: item.value,
    channelOrgSummaryDesc: item.label,
  };
}),
    selectedDisplayIn: storeData.singleSelect.selectedDisplayOptions.length > 0 ? storeData.singleSelect.selectedDisplayOptions.map(
      (item: any) => {
        return {
          id: item.value,
          rounddesc: item.label,
        };
      }
    ):"",
    excludeDataWithInsignificantBalance: storeData.checkboxes.excludeInsignificantBalance,
    excludeZeroBalances: storeData.checkboxes.excludeZeroBalances,
    mercuryRefreshTime: storeData.mercuryRefreshTime.mercuryRefreshTimeValue,
    roundingType: {
      text: storeData.singleSelect.selectedDisplayOptions.length > 0 ?  storeData.singleSelect.selectedDisplayOptions[0].label.trim():"",
      value:  storeData.singleSelect.selectedDisplayOptions.length > 0 ? storeData.singleSelect.selectedDisplayOptions[0].label.trim():"",
    },
    isCommentsSelected: storeData.sortOption.Commentarystatus,
    selectedSheet: ["Subsidiary P&L"],
    selectedColumns: storeData.filteredGridColHeader.ColHeaders,
    comments: {
      additionalProp1: "string",
      additionalProp2: "string",
      additionalProp3: "string",
    },
    currencyType: storeData.singleSelect.selectedCurrencyTypes.length > 0 ? storeData.singleSelect.selectedCurrencyTypes[0].label:"",
    hiddenFilterDimensionColumns: [0],
    hiddenFilterMeasuresColumns: [0],
    selectedFilterForSegmentDataModel: {
      selectedFiscalMonth: storeData.singleSelect.selectedFiscalMonths.length > 0 ? storeData.singleSelect.selectedFiscalMonths[0].value:0,
      selectedCompanies:
        storeData.customMultiSelect.selectedSAPCompanyCodes.length > 1
          ? storeData.customMultiSelect.selectedSAPCompanyCodes
              .map((e: any) => e.value)
              .join(",")
          :  storeData.customMultiSelect.selectedSAPCompanyCodes.length > 0 ? storeData.customMultiSelect.selectedSAPCompanyCodes[0].value.toString():"",
          selectedCurrencyType: storeData.singleSelect.selectedCurrencyTypes.length > 0 ?
        storeData.singleSelect.selectedCurrencyTypes[0].value:0,
      selectedDisplayIn: storeData.singleSelect.selectedDisplayOptions.length > 0 ? storeData.singleSelect.selectedDisplayOptions[0].value:0,
      selectedClass: storeData.cascadingDropDown.selectedClassesValue.length > 0 ? storeData.cascadingDropDown.selectedClassesValue
        .map((e: any) => e.value)
        .join(","):"",
      selectedSubClass: storeData.cascadingDropDown.selectedSubClassesValue.length > 0 ? storeData.cascadingDropDown.selectedSubClassesValue
        .map((e: any) => e.value)
        .join(","):"",
      selectedChannelGroup: storeData.cascadingDropDown.selectedGroupClassesValue.length > 0 ?
        storeData.cascadingDropDown.selectedGroupClassesValue
          .map((e: any) => e.value)
          .join(","):"",
      selectedChannelLineItem: storeData.cascadingDropDown.selectedLineItemValue.length > 0 ? storeData.cascadingDropDown.selectedLineItemValue
        .map((e: any) => e.value)
        .join(","):"",
      selectedFinancialAccount:  storeData.cascadingDropDown.selectedFinAccountValue.length > 0 ?
        storeData.cascadingDropDown.selectedFinAccountValue
          .map((e: any) => e.value)
          .join(","):"",
      selectedInternalOrder: storeData.cascadingDropDown.selectedOrderValue.length > 0 ? storeData.cascadingDropDown.selectedOrderValue
        .map((e: any) => e.value)
        .join(","):"",
      selectedProfitCenter: storeData.cascadingDropDown.selectedProfitCenterValue.length > 0 ?
        storeData.cascadingDropDown.selectedProfitCenterValue
          .map((e: any) => e.value)
          .join(","):"",
      selectedCostCenter: storeData.cascadingDropDown.selectedCostCenterValue.length > 0 ? storeData.cascadingDropDown.selectedCostCenterValue
        .map((e: any) => e.value)
        .join(","):"",
      selectExecOrg: storeData.cascadingDropDown.selectedOrgValue.length > 0 ? storeData.cascadingDropDown.selectedOrgValue
        .map((e: any) => e.value)
        .join(","):"",
      selectExecSummaryOrg: storeData.cascadingDropDown.selectedOrgSummaryValue.length > 0 ? storeData.cascadingDropDown.selectedOrgSummaryValue
        .map((e: any) => e.value)
        .join(","):"",
      selectedSortOptions: storeData.fieldSort.sortData.filter((x:any) => x.checked === true).map( (y:any) => y.columnName).join(","),
      selectedType: storeData.gridData.gridType.payload,
      userAlias: "string",
      formatNbr: storeData.singleSelect.selectedDisplayOptions.length > 0 ? storeData.singleSelect.selectedDisplayOptions[0].label:"",
    },
    includeFilters: storeData.exportCheckBox.checkBoxValue,
  };
};
