// import { boolean } from "yargs";
import { SET_CHECKBOX_VALUE } from "../ActionContants/constants";

const initialState = {
  checkBoxValue: false,
};

const includeFilterReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_CHECKBOX_VALUE:
      return {
        ...state,
        checkBoxValue: action.payload,
      };
    default:
      return state;
  }
};

export default includeFilterReducer;
