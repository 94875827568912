import {
  INIT_PREFERENCES,
  SET_PREFERENCES_DATA,
  SET_SELECTED_FILTER_PREFERENCE,
  SET_SELECTED_FILTER_PREFERENCES_DATA,
  SET_DEFAULT_FILTER_PREFERENCE,
  ERROR_PREFERENCES_DATA,
  SET_FILTER_PREFERENCES,
  SET_IS_PREFERENCE_CHANGED
} from "../ActionContants/constants";
import Config from "../../Config";
import axios from "axios";
import { getToken, getUsername } from "../../Components/Services/MsalService";
// import {v4 as uuid4} from "uuid";
import Store from "../Store/Store";

export const initPreferencesAction = () => {
  return {
    type: INIT_PREFERENCES
  }
}
export const setSavedPreferencesDataAction = async (savePreference: any) => {
  try {
    savePreference.userAlias = await getUsername();
    const response = await axios.post(
      `${Config.apiBaseAddress}${Config.apiSavePreference}`,
      JSON.stringify(savePreference),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getToken()}`,
        },
      }
    );

    //console.log("Save preference Response:",response)
    if (response.data) {
      return {
        type: SET_FILTER_PREFERENCES,
        payload:  response.data,
      };
    } else {
      return {
        type: SET_FILTER_PREFERENCES,
        payload: null,
      };
    }
  } catch (error) {
    console.error("Failed to fetch classes:", error);
    return {
      type: ERROR_PREFERENCES_DATA,
    };
  }
};

export const setFilterPreferencesDataAction = (payload: any) => {
  return {
    type: SET_FILTER_PREFERENCES,
    payload: payload,
  };
};

export const setSelectedFilterpreferncesAction = (payload: any) => {
  return {
    type: SET_SELECTED_FILTER_PREFERENCE,
    payload: payload,
  };
};

export const setSelectedFilterpreferncesDataAction = (
  selectedPreference: any
) => {
  const store = Store.getState();
  console.log("store:-", store);
  //selectedPreference
  return {
    type: SET_SELECTED_FILTER_PREFERENCES_DATA,
    // payload: payload
  };
};

export const setDefaultFilterpreferncesAction = (payload: any) => {
  return {
    type: SET_DEFAULT_FILTER_PREFERENCE,
    payload: payload,
  };
};

export const setIsPreferenceChangedAction = (payload: any) => {
  return {
    type: SET_IS_PREFERENCE_CHANGED,
    payload: payload,
  };
};
