import { combineReducers } from "redux";
import customMultiSelectReducer from "../Reducers/customMultiSelectReducer";
import multiSelectAllReducer from "../Reducers/multiSelectAllReducer";
import singleSelectReducer from "./singleSelectReducer";
import { RootReducer as RootReducerType } from "./types";
import UpdatedSelectionReducer from "./updatedSelectionReducer";
import cascadingDropDownReducer from "./cascadingDropDownReducer";
import companyRegionReducer from "./companyRegionReducer";
import gridReducer from "./gridReducer";
import fieldSortReducer from "./fieldSortReducer";
import inputSavePreferenceReducer from "./inputSavePreferenceReducer";
import commentReducer from "./commentReducer";
import mercuryRefreshTimeReducer from "./mercuryRefreshTimeReducer";
import preferenceDataReducer from "./preferencesDataReducer";
import includeFilterReducer from "./includeFilterReducer";
import CommonReducer from "./CommonReducer";
import checkboxReducer from "./checkboxReducer";
import filterGridColHeaderReducer from "./filterGridColHeaderReducer";
import { INIT_PREFERENCES } from "../ActionContants/constants";


//: RootReducerType
const appReducer = combineReducers({
  customMultiSelect: customMultiSelectReducer,
  multiSelectAll: multiSelectAllReducer,
  singleSelect: singleSelectReducer,
  updatedSelection: UpdatedSelectionReducer,
  cascadingDropDown: cascadingDropDownReducer,
  companyRegion: companyRegionReducer,
  gridData: gridReducer,
  fieldSort: fieldSortReducer,
  preferences: preferenceDataReducer,
  textBox: inputSavePreferenceReducer,
  sortOption: fieldSortReducer,
  mercuryRefreshTime: mercuryRefreshTimeReducer,
  exportCheckBox: includeFilterReducer,
  commonStore:CommonReducer,
  checkboxes: checkboxReducer,
  filteredGridColHeader:filterGridColHeaderReducer,
  commentDATA:commentReducer
});

export const RootReducer = (state:any, action:any) => {
  if (action.type === INIT_PREFERENCES) {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}