import { useState, useEffect } from "react";
import MultiSelectAll from "../Filter/MultiSelectAll";
import { useSelector, useDispatch } from "react-redux";
import { RootReducer } from "../../Redux/Reducers/types";
import {
  initiateGroupClassesAction,
  initiateLineItemAction,
  initiateSubClassesAction,
  setClassesAction,
  setGroupClassesAction,
  setSelectedClassesAction,
  setSelectedGroupClassesAction,
  setSelectedSubClassesAction,
  setSubClassesAction,
  setLineItemAction,
  setSelectedLineItemAction,
  initiateFinAccountAction,
  setFinAccountAction,
  setSelectedFinAccountAction,
  initiateOrderAction,
  setOrderAction,
  setSelectedOrderAction,
  initiateProfitCenterAction,
  setProfitCenterAction,
  setSelectedProfitCenterAction,
  initiateCostCenterAction,
  setCostCenterAction,
  setSelectedCostCenterAction,
  setOrgAction,
  setSelectedOrgAction,
  initiateOrgAction,
  setOrgSummaryAction,
  initiateOrgSummaryAction,
  setSelectedOrgSummaryAction,
  initCascadingDropDowns,
} from "../../Redux/Actions/cascadingDropDownActions";
import { Option } from "../Common/Interfaces";
import { getChildSort } from "../Common/GetDataFromStore";

import {sortSortChecked} from "../../Redux/Actions/fieldSortAction";

const CascadingDropdown = (props: {
  showFieldsFlag: boolean | undefined;
  handleShowFieldsFlag: (flag: any) => void;
  clearCascadingFlag: boolean;
}) => {
  const dispatch = useDispatch();
  const customMultiSelectState = useSelector(
    (state: RootReducer) => state.customMultiSelect
  );
  const singleSelectState = useSelector(
    (state: RootReducer) => state.singleSelect
  );
  const cascadingDropDownState = useSelector(
    (state: RootReducer) => state.cascadingDropDown
  );
  const {
    selectedClassesValue,
    selectedSubClassesValue,
    selectedGroupClassesValue,
    selectedLineItemValue,
    selectedFinAccountValue,
    selectedOrderValue,
    selectedProfitCenterValue,
    selectedCostCenterValue,
    selectedOrgValue,
    selectedOrgSummaryValue,
  } = useSelector((state: RootReducer) => state.cascadingDropDown);
  const preferences = useSelector((state: RootReducer) => state.preferences);

  const [showClasses, setShowClasses] = useState(
    cascadingDropDownState.classes.length > 0
  );

  // const [showSubclasses, setShowSubclasses] = useState(
  //   selectedClassesValue.length > 0 && cascadingDropDownState.classes.length > 0
  // );
  const [showSubclasses, setShowSubclasses] = useState(false);
  const [showGroupClass, setShowGroupClass] = useState(false);
  const [showLineItem, setShowLineItem] = useState(false);
  const [showFinAccount, setShowFinAccount] = useState(false);
  const [showOrder, setShowOrder] = useState(false);
  const [showProfitCenter, setShowProftCenter] = useState(false);
  const [showCostCenter, setShowCostCenter] = useState(false);
  const [showOrg, setShowOrg] = useState(false);
  const [showOrgSummary, setShowOrgSummary] = useState(false);

  // const [showGroupClass, setShowGroupClass] = useState(
  //   selectedSubClassesValue.length > 0 &&
  //     cascadingDropDownState.subclasses.length > 0
  // );

  // const [showLineItem, setShowLineItem] = useState(
  //   selectedGroupClassesValue.length > 0 &&
  //     cascadingDropDownState.groupclasses.length > 0
  // );

  // const [showFinAccount, setShowFinAccount] = useState(
  //   selectedLineItemValue.length > 0 &&
  //     cascadingDropDownState.lineitem.length > 0
  // );

  // const [showOrder, setShowOrder] = useState(
  //   selectedFinAccountValue.length > 0 &&
  //     cascadingDropDownState.finaccount.length > 0
  // );

  // const [showProfitCenter, setShowProftCenter] = useState(
  //   selectedOrderValue.length > 0 && cascadingDropDownState.order.length > 0
  // );

  // const [showCostCenter, setShowCostCenter] = useState(
  //   selectedProfitCenterValue.length > 0 &&
  //     selectedCostCenterValue.length > 0 &&
  //     cascadingDropDownState.profitcenter.length > 0
  // );

  // const [showOrg, setShowOrg] = useState(
  //   selectedCostCenterValue.length > 0 &&
  //     cascadingDropDownState.costcenter.length > 0
  // );

  // const [showOrgSummary, setShowOrgSummary] = useState(
  //   selectedOrgValue.length > 0 && cascadingDropDownState.org.length > 0
  // );
  // Loading state
  const [loading, setLoading] = useState(false);
  const [loadingSubClasses, setLoadingSubClasses] = useState(false);
  const [loadingGroupClasses, setLoadingGroupClasses] = useState(false);
  const [loadingLineItem, setLoadingLineItem] = useState(false);
  const [loadingFinAccount, setLoadingFinAccount] = useState(false);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [loadingProfitCenter, setLoadingProfitCenter] = useState(false);
  const [loadingCostCenter, setLoadingCostCenter] = useState(false);
  const [loadingOrg, setLoadingOrg] = useState(false);
  const [loadingOrgSummary, setLoadingOrgSummary] = useState(false);
  const StoreSortOption = useSelector((state: RootReducer) => state.fieldSort);

  const Data = useSelector((state: RootReducer) => state);
  // const selectedSortOption =Data.fieldSort.selectedSortItems;
    const selectedSortOption =Data.fieldSort.sortData.filter((a:any)=>a.checked===true);
  useEffect( ()=> {

    if(StoreSortOption.chkOpt != undefined){
    dispatch(setSelectedClassesAction([]));
    dispatch(initiateSubClassesAction());
    dispatch(initiateGroupClassesAction());
    dispatch(initiateLineItemAction());
    dispatch(initiateFinAccountAction());
    dispatch(initiateOrderAction());
    dispatch(initiateCostCenterAction());
    dispatch(initiateProfitCenterAction());
    dispatch(initiateOrgSummaryAction());
    dispatch(initiateOrgAction());

    setShowSubclasses(false);
    setShowGroupClass(false);
    setShowLineItem(false);
    setShowFinAccount(false)
    setShowOrder(false);
    setShowProftCenter(false);
    setShowCostCenter(false)
    setShowOrg(false);
    setShowOrgSummary(false);
    }
    
    return function() {
    dispatch(sortSortChecked());

    }

  },[StoreSortOption.chkOpt])

  const showCascadingDropDowns = () => {


 
    if (selectedClassesValue.length > 0) setShowSubclasses(true);
    const selectedSortOption =Data.fieldSort.sortData.filter((a:any)=>a.checked===true);

      if(selectedSubClassesValue.length > 0)
         EnableSortOption(getChildSort("subclass",selectedSortOption));
      if(selectedGroupClassesValue.length > 0)
         EnableSortOption(getChildSort("Group",selectedSortOption));
      if(selectedLineItemValue.length > 0)
         EnableSortOption(getChildSort("LineItem",selectedSortOption));
      if(selectedFinAccountValue.length > 0)
         EnableSortOption(getChildSort("AccountNo",selectedSortOption));
      if(selectedOrderValue.length > 0)
         EnableSortOption(getChildSort("IONo",selectedSortOption));
      if(selectedProfitCenterValue.length > 0)
         EnableSortOption(getChildSort("PCNo",selectedSortOption));
      if(selectedCostCenterValue.length > 0)
         EnableSortOption(getChildSort("CostCenter",selectedSortOption));
      if(selectedOrgValue.length > 0)
         EnableSortOption(getChildSort("ExecOrg",selectedSortOption));
  
      //  if (selectedSubClassesValue.length > 0 || preferences.selectedFilterPreference.length > 0 || getChildSort("subclass",selectedSortOption) == "Group") setShowGroupClass(JSON.stringify(selectedSortOption).includes("Group")?true:false)
      //  if (selectedGroupClassesValue.length > 0 || preferences.selectedFilterPreference.length > 0 || getChildSort("Group",selectedSortOption) == "LineItem") setShowLineItem(JSON.stringify(selectedSortOption).includes("LineItem")?true:false)
      //  if (selectedLineItemValue.length > 0 || preferences.selectedFilterPreference.length > 0 || getChildSort("LineItem",selectedSortOption) == "AccountNo") setShowFinAccount(JSON.stringify(selectedSortOption).includes("AccountNo")?true:false)
      //  if (selectedFinAccountValue.length > 0 || preferences.selectedFilterPreference.length > 0 || getChildSort("AccountNo",selectedSortOption) == "IONo") setShowOrder(JSON.stringify(selectedSortOption).includes("IONo")?true:false)
      //  if (selectedOrderValue.length > 0 || preferences.selectedFilterPreference.length > 0 || getChildSort("IONo",selectedSortOption) == "PCNo") setShowProftCenter(JSON.stringify(selectedSortOption).includes("PCNo")?true:false)
      //  if (selectedProfitCenterValue.length > 0 || preferences.selectedFilterPreference.length > 0 || getChildSort("PCNo",selectedSortOption) == "CostCenter") setShowCostCenter(JSON.stringify(selectedSortOption).includes("CostCenter")?true:false)
      //  if (selectedCostCenterValue.length > 0 || preferences.selectedFilterPreference.length > 0 || getChildSort("CostCenter",selectedSortOption) == "ExecOrg") setShowOrg(JSON.stringify(selectedSortOption).includes("ExecOrg")?true:false)
      //  if (selectedOrgValue.length > 0 || preferences.selectedFilterPreference.length > 0 || getChildSort("ExecOrg",selectedSortOption) == "ExecSummary") setShowOrgSummary(JSON.stringify(selectedSortOption).includes("ExecSummary")?true:false)
    // if (selectedSubClassesValue.length > 0 && getChildSort("subclass",selectedSortOption) == "Group") setShowGroupClass(true);
    // if (selectedGroupClassesValue.length > 0 && getChildSort("Group",selectedSortOption) == "LineItem") setShowLineItem(true);
    // if (selectedLineItemValue.length > 0 && getChildSort("LineItem",selectedSortOption) == "AccountNo") setShowFinAccount(true);
    // if (selectedFinAccountValue.length > 0 && getChildSort("AccountNo",selectedSortOption) == "IONo") setShowOrder(true);
    // if (selectedOrderValue.length > 0 && getChildSort("IONo",selectedSortOption) == "PCNo") setShowProftCenter(true);
    // if (selectedProfitCenterValue.length > 0 && getChildSort("PCNo",selectedSortOption) == "CostCenter") setShowCostCenter(true);
    // if (selectedCostCenterValue.length > 0 && getChildSort("CostCenter",selectedSortOption) == "ExecOrg") setShowOrg(true);
    // if (selectedOrgValue.length > 0 && getChildSort("ExecOrg",selectedSortOption) == "ExecSummary") setShowOrgSummary(true);
  };
  useEffect(() => {
    //alert('1');

    showCascadingDropDowns();
  }, [
    selectedClassesValue,
    selectedSubClassesValue,
    selectedGroupClassesValue,
    selectedLineItemValue,
    selectedFinAccountValue,
    selectedOrderValue,
    selectedProfitCenterValue,
    selectedCostCenterValue,
    selectedOrgValue,
  ]);


  const EnableSortOption = (sort:any) => {
    switch (sort) {
      case "Group":
        setShowGroupClass(true);
        break;
      case "LineItem":
        setShowLineItem(true);
        break;
      case "AccountNo":
        setShowFinAccount(true);
        break;
      case "IONo":
        setShowOrder(true);
        break;
      case "PCNo":
        setShowProftCenter(true);
        break;
      case "CostCenter":
        setShowCostCenter(true);
        break;
      case "ExecOrg":
        setShowOrg(true);
        break;
      case 'ExecSummary':
        setShowOrgSummary(true);
          break;
      default:
        break;
  }
  }


  // useEffect(() => {
  //  setShowSubclasses(selectedClassesValue.length > 0?true:false);
  // }, [
  //    selectedClassesValue
  // ]);

  // useEffect(() => {
  //   if(selectedSubClassesValue.length > 0)
  //    EnableSortOption(getChildSort("subclass",selectedSortOption));
  //  }, [
  //   selectedSubClassesValue
  //  ]);
  //  useEffect(() => {
  //   if(selectedGroupClassesValue.length > 0)
  //   EnableSortOption(getChildSort("Group",selectedSortOption));
  //  }, [
  //   selectedGroupClassesValue
  //  ]);  
  //  useEffect(() => {
  //   if(selectedLineItemValue.length > 0)
  //   EnableSortOption(getChildSort("LineItem",selectedSortOption));
  //  }, [
  //   selectedLineItemValue
  //  ]); 
  //   useEffect(() => {
  //     if(selectedFinAccountValue.length > 0)
  //     EnableSortOption(getChildSort("AccountNo",selectedSortOption));
  //  }, [
  //   selectedFinAccountValue
  //  ]);  
  //  useEffect(() => {
  //   if(selectedOrderValue.length > 0)
  //   EnableSortOption(getChildSort("IONo",selectedSortOption));
  //  }, [
  //   selectedOrderValue
  //  ]);  
  //  useEffect(() => {
  //   if(selectedProfitCenterValue.length > 0)
  //   EnableSortOption(getChildSort("PCNo",selectedSortOption));
  //  }, [
  //   selectedProfitCenterValue
  //  ]); 
  //   useEffect(() => {
  //     if(selectedCostCenterValue.length > 0)
  //     EnableSortOption(getChildSort("CostCenter",selectedSortOption));
  //  }, [
  //   selectedCostCenterValue
  //  ]);  
  //  useEffect(() => {
  //   if(selectedOrgValue.length > 0)
  //   EnableSortOption(getChildSort("ExecOrg",selectedSortOption));
  //  }, [
  //   selectedOrgValue
  //  ]); 
  useEffect(() => {
    let initiateStatesFlag =
      cascadingDropDownState.selectedClassesValue.length === 0 &&
      cascadingDropDownState.selectedSubClassesValue.length === 0 &&
      cascadingDropDownState.selectedGroupClassesValue.length === 0 &&
      cascadingDropDownState.selectedLineItemValue.length === 0 &&
      cascadingDropDownState.selectedFinAccountValue.length === 0 &&
      cascadingDropDownState.selectedOrderValue.length === 0 &&
      cascadingDropDownState.selectedProfitCenterValue.length === 0 &&
      cascadingDropDownState.selectedCostCenterValue.length === 0 &&
      cascadingDropDownState.selectedOrgValue.length === 0 &&
      cascadingDropDownState.selectedOrgSummaryValue.length === 0;
    if (
      initiateStatesFlag &&
      customMultiSelectState.selectedSAPCompanyCodes.length === 0 &&
      singleSelectState.selectedFiscalMonths.length === 0 &&
      singleSelectState.selectedCurrencyTypes.length === 0 &&
      singleSelectState.selectedDisplayOptions.length === 0 &&
      preferences.selectedFilterPreference.length === 0
    ) {
      dispatch(initCascadingDropDowns());
    } else if (!props.showFieldsFlag) {
      setLoading(false);
    }

    if (
      !initiateStatesFlag &&
      cascadingDropDownState.classes.length > 0 &&
      !props.showFieldsFlag
    ) {
      props.handleShowFieldsFlag(true);
    }
  }, []);

  const dispatchFilterData = async (
    data: any,
    isPreferenceSelected: boolean
  ) => {
    if (data) {
      let selectedPreferenceData = [];
      if (isPreferenceSelected) {
        selectedPreferenceData = data.preference.filter(
          (e: any) => e.value === preferences.selectedFilterPreference[0].value
        );
      }
    }
  };

  useEffect(() => {
    const updateFIltersData = async () => {
      if (preferences.selectedFilterPreference.length > 0) {
        props.handleShowFieldsFlag(true);
        await dispatchFilterData(preferences.preferenceData, true);
      }
    };
    updateFIltersData();
    if (props.clearCascadingFlag) {
      showCascadingDropDowns();
    }
  }, [preferences.selectedFilterPreference]);

  useEffect(() => {
    if (props.showFieldsFlag && cascadingDropDownState.classes.length === 0) {
      dispatch(initCascadingDropDowns());
      fetchClasses();
    }
  }, [props.showFieldsFlag]);

  const fetchClasses = async () => {
    setLoading(true);
    dispatch(await setClassesAction(getSelectedData()));
    setLoading(false);
    setShowClasses(true);
    setShowSubclasses(false);
    setShowGroupClass(false);
    setShowLineItem(false);
    setShowFinAccount(false);
    setShowOrder(false);
    setShowProftCenter(false);
    setShowCostCenter(false);
    setShowOrg(false);
    setShowOrgSummary(false);
  };
  const getSelectedData = (
    selectedFiscalMonth: any = singleSelectState.selectedFiscalMonths[0],
    selectedSapCompanyCodeOptions: any = customMultiSelectState.selectedSAPCompanyCodes,
    selectedCurrencyType: any = singleSelectState.selectedCurrencyTypes,
    displayOptions: any = singleSelectState.selectedDisplayOptions
  ) => {
    return {
      selectedFiscalMonth:
        selectedFiscalMonth != undefined ? selectedFiscalMonth.value : "",
      selectedCompanies: selectedSapCompanyCodeOptions
        .map((e: any) => e.value)
        .join(","),
      selectedCurrencyType:
        selectedCurrencyType != undefined &&
        selectedCurrencyType.value &&
        selectedCurrencyType.value.length !== 0
          ? selectedCurrencyType.value
          : singleSelectState.defaultCurrencyType.length !== 0
          ? singleSelectState.defaultCurrencyType[0].value
          : 0,
      selectedDisplayIn:
        displayOptions != undefined &&
        displayOptions.value &&
        displayOptions.value.length !== 0
          ? displayOptions.value
          : singleSelectState.defaultDisplayOption.length !== 0
          ? singleSelectState.defaultDisplayOption[0].value.toString()
          : 0,
      selectedClass: selectedClassesValue.map((e: any) => e.value).join(","),
      selectedSubClass: selectedSubClassesValue
        .map((e: any) => e.value)
        .join(","),
      selectedChannelGroup: selectedGroupClassesValue
        .map((e: any) => e.value)
        .join(","),
      selectedChannelLineItem: selectedLineItemValue
        .map((e: any) => e.value)
        .join(","),
      selectedFinancialAccount: selectedFinAccountValue
        .map((e: any) => e.value)
        .join(","),
      selectedInternalOrder: selectedOrderValue
        .map((e: any) => e.value)
        .join(","),
      selectedProfitCenter: selectedProfitCenterValue
        .map((e: any) => e.value)
        .join(","),
      selectedCostCenter: selectedCostCenterValue
        .map((e: any) => e.value)
        .join(","),
      selectExecOrg: selectedOrgValue.map((e: any) => e.value).join(","),
      selectedOrgSummary: selectedOrgSummaryValue
        .map((e: any) => e.value)
        .join(","),
      userAlias: "",
    };
  };

  const onSelectAllChange = async (
    event: any,
    selectedOptions: Option[],
    multiselectName: string
  ) => {
    //    StoreData(selectedOptions, multiselectName);
    //  await fetchanddispatchData(selectedOptions,multiselectName)
  };

  const onMenuToggle = async (
    isOpen: boolean,
    selectedOptions: Option[],
    multiselectName: string,
    isFieldDisabled: boolean
  ) => {
    !isOpen && !isFieldDisabled && StoreData(selectedOptions, multiselectName);
    // await fetchanddispatchData(selectedOptions,multiselectName)
  };

  const fetchanddispatchData = async (
    selectedOptions: Option[],
    multiselectName: string
  ) => {
    var payload = getSelectedData();
    switch (multiselectName) {
      case "classes":
        await OnClassSelChange(payload, selectedOptions);
        break;
      case "subclasses":
        await OnSubClassSelChange(payload, selectedOptions);
        break;
      case "groupClass":
        await OnGroupSelChange(payload, selectedOptions);
        break;
      case "lineItem":
        await OnLineItemSelChange(payload, selectedOptions);
        break;
      case "finAccount":
        await OnAccountNoSelChange(payload, selectedOptions);
        break;
      case "order":
        await OnInternalOrderSelChange(payload, selectedOptions);
        break;
      case "profitCenter":
        // console.log("selectedOptions in profitCenter case==>", selectedOptions);
        await OnProfitCenterSelChange(payload, selectedOptions);
        break;
      case "costCenter":
        await OnCostCenterSelChange(payload, selectedOptions);
        break;
      case "org":
        await OnExecOrgSelChange(payload, selectedOptions);
        break;
      // case 'orgSummary':
      //     payload.selectedOrgSummary = selectedOptions.map((e: any) => e.value).join(',');
      //     break;
      default:
        break;
    }
  };

  const StoreData = (selectedOptions: Option[], multiselectName: string) => {
    switch (multiselectName) {
      case "classes":
        //setselectedClasses(selectedOptions);
        dispatch(setSelectedClassesAction(selectedOptions));
        break;
      case "subclasses":
        //setselectedSubClasses(selectedOptions);
        dispatch(setSelectedSubClassesAction(selectedOptions));
        break;
      case "groupClass":
        //setselectedGroupClasses(selectedOptions);
        dispatch(setSelectedGroupClassesAction(selectedOptions));
        break;
      case "lineItem":
        //setselectedLineItem(selectedOptions);
        dispatch(setSelectedLineItemAction(selectedOptions));
        break;
      case "finAccount":
        //setselectedFinAccount(selectedOptions);
        dispatch(setSelectedFinAccountAction(selectedOptions));
        break;
      case "order":
        //setselectedOrder(selectedOptions);
        dispatch(setSelectedOrderAction(selectedOptions));
        break;
      case "profitCenter":
        //setselectedProfitCenter(selectedOptions);
        dispatch(setSelectedProfitCenterAction(selectedOptions));
        break;
      case "costCenter":
        //setselectedCostCenter(selectedOptions);
        dispatch(setSelectedCostCenterAction(selectedOptions));

        break;
      case "org":
        //setselectedOrg(selectedOptions);
        dispatch(setSelectedOrgAction(selectedOptions));
        break;
      case "orgSummary":
        //setselectedOrgSummary(selectedOptions);
        dispatch(setSelectedOrgSummaryAction(selectedOptions));
        break;
      default:
        break;
    }
  };

  const handleMultislecteChange = async (
    selectedOptions: Option[],
    multiselectName: string
  ) => {
    StoreData(selectedOptions, multiselectName);
    await fetchanddispatchData(selectedOptions, multiselectName);
  };

  const OnClassSelChange = async (
    payload?: any,
    selectedOptions: any[] = [],
    dispatchFlag: boolean = true
  ) => {
    var isOptSelected = selectedOptions.length > 0 ? true : false;
    setShowSubclasses(isOptSelected);
    setShowGroupClass(false);
    setShowLineItem(false);
    setShowFinAccount(false);
    setShowOrder(false);
    setShowProftCenter(false);
    setShowCostCenter(false);
    setShowOrg(false);
    setShowOrgSummary(false);
    setLoadingSubClasses(true);
    dispatch(initiateSubClassesAction());
    dispatch(initiateGroupClassesAction());
    dispatch(initiateLineItemAction());
    dispatch(initiateFinAccountAction());
    dispatch(initiateOrderAction());
    dispatch(initiateCostCenterAction());
    dispatch(initiateProfitCenterAction());
    dispatch(initiateOrgSummaryAction());
    dispatch(initiateOrgAction());
    if (dispatchFlag) {
      let selections = {
        ...payload,
        selectedClass: selectedOptions.map((e: any) => e.value).join(","),
      };
      if (isOptSelected) {
        setLoadingSubClasses(true);
        dispatch(await setSubClassesAction(selections));
      }
      setLoadingSubClasses(false);
    }
  };

  const OnSubClassSelChange = async (
    payload?: any,
    selectedOptions: any[] = [],
    dispatchFlag: boolean = true
  ) => {

    var isOptSelected = selectedOptions.length > 0 ? true : false;
        setShowLineItem(false);
        setShowFinAccount(false);
        setShowOrder(false);
        setShowProftCenter(false);
        setShowCostCenter(false);
        setShowOrg(false);
        setShowOrgSummary(false);
        dispatch(initiateGroupClassesAction());
        dispatch(initiateLineItemAction());
        dispatch(initiateFinAccountAction());
        dispatch(initiateOrderAction());
        dispatch(initiateCostCenterAction());
        dispatch(initiateProfitCenterAction());
        dispatch(initiateOrgSummaryAction());
        dispatch(initiateOrgAction());
    if (dispatchFlag) {

      let selections = {
        ...payload,
        selectedSubClass: selectedOptions.map((e: any) => e.value).join(","),
      };
      if (isOptSelected && JSON.stringify(selectedSortOption).includes("Group")) {
          setShowGroupClass(isOptSelected);
          setLoadingGroupClasses(true);
          dispatch(await setGroupClassesAction(selections));
          setLoadingGroupClasses(false);
      }
      else{
       setShowGroupClass(false);
        onsortchange(getChildSort("subclass",selectedSortOption),isOptSelected,payload)
      }
    }
  };

  const onsortchange = async (sort:string, isOptSelected:boolean,payload?: any)  =>{
    switch(sort){
      case "LineItem":
    setLoadingLineItem(true);
    setShowLineItem(isOptSelected);
    if(isOptSelected){
      let selections = {
        ...payload,
        selectedChannelGroup: "All"
      };
      dispatch(await setLineItemAction(selections));
    }
    else{
         dispatch(initiateLineItemAction());
    }
    setLoadingLineItem(false);
      break;

      case "AccountNo":
        setLoadingFinAccount(true);
        setShowFinAccount(isOptSelected);
        if(isOptSelected){
          let selections = {
            ...payload,
            selectedChannelLineItem: "All"
          };
          dispatch(await setFinAccountAction(selections));
        }
        else{
             dispatch(initiateFinAccountAction());
        }
        setLoadingFinAccount(false);
          break;

        case "IONo":
            setLoadingOrder(true);
            setShowOrder(isOptSelected);
            if(isOptSelected){
              let selections = {
                ...payload,
                selectedFinancialAccount: "All"
              };
              dispatch(await setOrderAction(selections));
            }
            else{
                 dispatch(initiateOrderAction());
            }
            setLoadingOrder(false);
              break;

        case "PCNo":
          setShowProftCenter(isOptSelected);
          setLoadingProfitCenter(true);
            if(isOptSelected){
              let selections = {
                ...payload,
                selectedInternalOrder: "All"
              };
              dispatch(await setProfitCenterAction(selections));
            }
            else{
                 dispatch(initiateProfitCenterAction());
            }
            setLoadingProfitCenter(false);
              break;
     
        case "CostCenter":
                setShowCostCenter(isOptSelected);
                setLoadingCostCenter(true);
                  if(isOptSelected){
                    let selections = {
                      ...payload,
                      selectedProfitCenter: "All"
                    };
                    dispatch(await setCostCenterAction(selections));
                  }
                  else{
                       dispatch(initiateCostCenterAction());
                  }
                  setLoadingCostCenter(false);
                    break;
                  
        case "ExecOrg":
            setShowOrg(isOptSelected);
            setLoadingOrg(true);
                    if(isOptSelected){
                      let selections = {
                        ...payload,
                        selectedCostCenter: "All"
                      };
                      dispatch(await setOrgAction(selections));
                    }
                    else{
                         dispatch(initiateOrgAction());
                    }
                    setLoadingOrg(false);
                      break;
              
         case "ExecSummary":
          setShowOrgSummary(isOptSelected);
          setLoadingOrgSummary(true);
                   if(isOptSelected){
                     let selections = {
                       ...payload,
                       selectExecOrg: "All"
                     };
                     dispatch(await setOrgSummaryAction(selections));
                   }
                   else{
                        dispatch(initiateOrgSummaryAction());
                   }
                   setLoadingOrgSummary(false);
                     break;
        
          case "":
        break;
    }

  }

  const OnGroupSelChange = async (
    payload?: any,
    selectedOptions: any[] = [],
    dispatchFlag: boolean = true
  ) => {
    var isOptSelected = selectedOptions.length > 0 ? true : false;
    setShowFinAccount(false);
    setShowOrder(false);
    setShowProftCenter(false);
    setShowCostCenter(false);
    setShowOrg(false);
    setShowOrgSummary(false);
  
    dispatch(initiateLineItemAction());
    dispatch(initiateFinAccountAction());
    dispatch(initiateOrderAction());
    dispatch(initiateCostCenterAction());
    dispatch(initiateProfitCenterAction());
    dispatch(initiateOrgSummaryAction());
    dispatch(initiateOrgAction());
    if (dispatchFlag) {
      let selections = {
        ...payload,
        selectedChannelGroup: selectedOptions
          .map((e: any) => e.value)
          .join(","),
      };

      if(isOptSelected && JSON.stringify(selectedSortOption).includes("LineItem")){
    setShowLineItem(isOptSelected);
    setLoadingLineItem(true);
        dispatch(await setLineItemAction(selections));
        setLoadingLineItem(false);
      }
      else{
        setShowLineItem(false);
         onsortchange(getChildSort("Group",selectedSortOption),isOptSelected,payload)
       }
      
    }
   // setLoadingLineItem(false);
  };

  const OnLineItemSelChange = async (
    payload?: any,
    selectedOptions: any[] = [],
    dispatchFlag: boolean = true
  ) => {
    var isOptSelected = selectedOptions.length > 0 ? true : false;
    setShowOrder(false);
    setShowProftCenter(false);
    setShowCostCenter(false);
    setShowOrg(false);
    setShowOrgSummary(false);
    dispatch(initiateFinAccountAction());
    dispatch(initiateOrderAction());
    dispatch(initiateCostCenterAction());
    dispatch(initiateProfitCenterAction());
    dispatch(initiateOrgSummaryAction());
    dispatch(initiateOrgAction());
    if (dispatchFlag) {
      let selections = {
        ...payload,
        selectedChannelLineItem: selectedOptions
          .map((e: any) => e.value)
          .join(","),
      };

      if (isOptSelected && JSON.stringify(selectedSortOption).includes("AccountNo")) {
            setShowFinAccount(isOptSelected);
            setLoadingFinAccount(true);
            dispatch(await setFinAccountAction(selections));
             setLoadingFinAccount(false);
      }
      else{
        setShowFinAccount(false);
        onsortchange(getChildSort("LineItem",selectedSortOption),isOptSelected,payload)
      }
    }
    //setLoadingFinAccount(false);
  };

  const OnAccountNoSelChange = async (
    payload?: any,
    selectedOptions: any[] = [],
    dispatchFlag: boolean = true
  ) => {
    var isOptSelected = selectedOptions.length > 0 ? true : false;
    setShowProftCenter(false);
    setShowCostCenter(false);
    setShowOrg(false);
    setShowOrgSummary(false);
    dispatch(initiateOrderAction());
    dispatch(initiateCostCenterAction());
    dispatch(initiateProfitCenterAction());
    dispatch(initiateOrgSummaryAction());
    dispatch(initiateOrgAction());
    if (dispatchFlag) {
      let selections = {
        ...payload,
        selectedFinancialAccount: selectedOptions
          .map((e: any) => e.value)
          .join(","),
      };
      if (isOptSelected && JSON.stringify(selectedSortOption).includes("IONo")) {
        setShowOrder(isOptSelected);
        setLoadingOrder(true);

             dispatch(await setOrderAction(selections));
             setLoadingOrder(false);

      }
      else{
        setShowOrder(false);
        onsortchange(getChildSort("AccountNo",selectedSortOption),isOptSelected,payload)
      }
    }
   // setLoadingOrder(false);
  };

  const OnInternalOrderSelChange = async (
    payload?: any,
    selectedOptions: any[] = [],
    dispatchFlag: boolean = true
  ) => {
    var isOptSelected = selectedOptions.length > 0 ? true : false;
    setShowCostCenter(false);
    setShowOrg(false);
    setShowOrgSummary(false);
    dispatch(initiateProfitCenterAction());
    dispatch(initiateOrgSummaryAction());
    dispatch(initiateOrgAction());
    if (dispatchFlag) {
      let selections = {
        ...payload,
        selectedInternalOrder: selectedOptions
          .map((e: any) => e.value)
          .join(","),
      };
      if (isOptSelected && JSON.stringify(selectedSortOption).includes("PCNo")) {
          setShowProftCenter(isOptSelected);
          setLoadingProfitCenter(true);
          dispatch(await setProfitCenterAction(selections));
          setLoadingProfitCenter(false);

      }
      else{
        setShowProftCenter(false);
        onsortchange(getChildSort("IONo",selectedSortOption),isOptSelected,payload)
      }
    }
  };

  const OnProfitCenterSelChange = async (
    payload?: any,
    selectedOptions: any[] = [],
    dispatchFlag: boolean = true
  ) => {
    var isOptSelected = selectedOptions.length > 0 ? true : false;
    setShowOrg(false);
    setShowOrgSummary(false);
    dispatch(initiateCostCenterAction());
    dispatch(initiateOrgAction());
    dispatch(initiateOrgSummaryAction());
    if (dispatchFlag) {
      let selections = {
        ...payload,
        selectedProfitCenter: selectedOptions
          .map((e: any) => e.value)
          .join(","),
      };
      if (isOptSelected && JSON.stringify(selectedSortOption).includes("CostCenter")) {
    setShowCostCenter(isOptSelected);
    setLoadingCostCenter(true);

      dispatch(await setCostCenterAction(selections));
    setLoadingCostCenter(false);


      }
      else{
    setShowCostCenter(false);

        onsortchange(getChildSort("PCNo",selectedSortOption),isOptSelected,payload)
      }
    }
  };

  const OnCostCenterSelChange = async (
    payload?: any,
    selectedOptions: any[] = [],
    dispatchFlag: boolean = true
  ) => {
    var isOptSelected = selectedOptions.length > 0 ? true : false;
    setShowOrgSummary(false);
    dispatch(initiateOrgAction());
    dispatch(initiateOrgSummaryAction());
    if (dispatchFlag) {
      let selections = {
        ...payload,
        selectedCostCenter: selectedOptions.map((e: any) => e.value).join(","),
      };
      if (isOptSelected && JSON.stringify(selectedSortOption).includes("ExecOrg")) {
          setShowOrg(isOptSelected);
          setLoadingOrg(true);
           dispatch(await setOrgAction(selections));
          setLoadingOrg(false);
      }
      else{
        setShowOrg(false);
        onsortchange(getChildSort("CostCenter",selectedSortOption),isOptSelected,payload)
      }
    }
  };

  const OnExecOrgSelChange = async (
    payload?: any,
    selectedOptions: any[] = [],
    dispatchFlag: boolean = true
  ) => {
    var isOptSelected = selectedOptions.length > 0 ? true : false;

    dispatch(initiateOrgSummaryAction());
    if (dispatchFlag) {
      let selections = {
        ...payload,
        selectExecOrg: selectedOptions.map((e: any) => e.value).join(","),
      };
      if (isOptSelected && JSON.stringify(selectedSortOption).includes("ExecSummary")) {
        setShowOrgSummary(isOptSelected);
        setLoadingOrgSummary(true);
        dispatch(await setOrgSummaryAction(selections));
        setLoadingOrgSummary(false);
      }else{
        setShowOrgSummary(false);
        onsortchange(getChildSort("ExecOrg",selectedSortOption),isOptSelected,payload)
      }
    }
  };

  return (
    <>
      {!props.showFieldsFlag ? (
        <></>
      ) : (
        <>
          <hr />
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <MultiSelectAll
                label="Class"
                options={cascadingDropDownState.classes}
                selectedOptions={selectedClassesValue}
                onChange={(selectedOptions) =>
                  handleMultislecteChange(selectedOptions, "classes")
                }
                onSelectAllChange={(
                  event: any,
                  selectedOptions: Option[],
                  group: string
                ) => onSelectAllChange(event, selectedOptions, group)}
                group="classes"
                initialSelectedOptions={selectedClassesValue}
                isLoading={loading}
                onMenuToggle={(
                  isOpen: boolean,
                  selectedOptions: Option[],
                  group: string,
                  isFieldDisabled: boolean
                ) =>
                  onMenuToggle(isOpen, selectedOptions, group, isFieldDisabled)
                }
              />
            </div>
            <div className="col-md-6 col-lg-4">
              <MultiSelectAll
                label="Subclass"
                isFieldDisabled={!showSubclasses}
                options={cascadingDropDownState.subclasses}
                selectedOptions={selectedSubClassesValue}
                onChange={(selectedOptions) =>
                  handleMultislecteChange(selectedOptions, "subclasses")
                }
                onSelectAllChange={onSelectAllChange}
                group="subclasses"
                initialSelectedOptions={selectedSubClassesValue}
                onMenuToggle={(
                  isOpen: boolean,
                  selectedOptions: Option[],
                  group: string,
                  isFieldDisabled: boolean
                ) =>
                  onMenuToggle(isOpen, selectedOptions, group, isFieldDisabled)
                }
                isLoading={loadingSubClasses}
              />
            </div>
            <div className="col-md-6 col-lg-4">
              <MultiSelectAll
                label="Group"
                isFieldDisabled={!showGroupClass}
                options={cascadingDropDownState.groupclasses}
                selectedOptions={selectedGroupClassesValue}
                onChange={(selectedOptions) =>
                  handleMultislecteChange(selectedOptions, "groupClass")
                }
                onSelectAllChange={onSelectAllChange}
                group="groupClass"
                initialSelectedOptions={selectedGroupClassesValue}
                onMenuToggle={(
                  isOpen: boolean,
                  selectedOptions: Option[],
                  group: string,
                  isFieldDisabled: boolean
                ) =>
                  onMenuToggle(isOpen, selectedOptions, group, isFieldDisabled)
                }
                isLoading={loadingGroupClasses}
              />
            </div>
            <div className="col-md-6 col-lg-4">
              <MultiSelectAll
                isFieldDisabled={!showLineItem}
                label="Line Item"
                options={cascadingDropDownState.lineitem}
                selectedOptions={selectedLineItemValue}
                onChange={(selectedOptions) =>
                  handleMultislecteChange(selectedOptions, "lineItem")
                }
                onSelectAllChange={onSelectAllChange}
                group="lineItem"
                initialSelectedOptions={selectedLineItemValue}
                isLoading={loadingLineItem}
                onMenuToggle={(
                  isOpen: boolean,
                  selectedOptions: Option[],
                  group: string,
                  isFieldDisabled: boolean
                ) =>
                  onMenuToggle(isOpen, selectedOptions, group, isFieldDisabled)
                }
              />
            </div>

            <div className="col-md-6 col-lg-4">
              <MultiSelectAll
                isFieldDisabled={!showFinAccount}
                label="Account No"
                options={cascadingDropDownState.finaccount}
                selectedOptions={selectedFinAccountValue}
                onChange={(selectedOptions) =>
                  handleMultislecteChange(selectedOptions, "finAccount")
                }
                onSelectAllChange={onSelectAllChange}
                group="finAccount"
                initialSelectedOptions={selectedFinAccountValue}
                isLoading={loadingFinAccount}
                onMenuToggle={(
                  isOpen: boolean,
                  selectedOptions: Option[],
                  group: string,
                  isFieldDisabled: boolean
                ) =>
                  onMenuToggle(isOpen, selectedOptions, group, isFieldDisabled)
                }
              />
            </div>

            <div className="col-md-6 col-lg-4">
              <MultiSelectAll
                isFieldDisabled={!showOrder}
                label="Internal Order"
                options={cascadingDropDownState.order}
                selectedOptions={selectedOrderValue}
                onChange={(selectedOptions) =>
                  handleMultislecteChange(selectedOptions, "order")
                }
                onSelectAllChange={onSelectAllChange}
                group="order"
                initialSelectedOptions={selectedOrderValue}
                isLoading={loadingOrder}
                onMenuToggle={(
                  isOpen: boolean,
                  selectedOptions: Option[],
                  group: string,
                  isFieldDisabled: boolean
                ) =>
                  onMenuToggle(isOpen, selectedOptions, group, isFieldDisabled)
                }
              />
            </div>

            <div className="col-md-6 col-lg-4">
              <MultiSelectAll
                isFieldDisabled={!showProfitCenter}
                label="Profit Center"
                options={cascadingDropDownState.profitcenter}
                selectedOptions={selectedProfitCenterValue}
                onChange={(selectedOptions) =>
                  handleMultislecteChange(selectedOptions, "profitCenter")
                }
                onSelectAllChange={onSelectAllChange}
                group="profitCenter"
                initialSelectedOptions={selectedProfitCenterValue}
                isLoading={loadingProfitCenter}
                onMenuToggle={(
                  isOpen: boolean,
                  selectedOptions: Option[],
                  group: string,
                  isFieldDisabled: boolean
                ) =>
                  onMenuToggle(isOpen, selectedOptions, group, isFieldDisabled)
                }
              />
            </div>

            <div className="col-md-6 col-lg-4">
              <MultiSelectAll
                isFieldDisabled={!showCostCenter}
                label="Cost Center"
                options={cascadingDropDownState.costcenter}
                selectedOptions={selectedCostCenterValue}
                onChange={(selectedOptions) =>
                  handleMultislecteChange(selectedOptions, "costCenter")
                }
                onSelectAllChange={onSelectAllChange}
                group="costCenter"
                initialSelectedOptions={selectedCostCenterValue}
                isLoading={loadingCostCenter}
                onMenuToggle={(
                  isOpen: boolean,
                  selectedOptions: Option[],
                  group: string,
                  isFieldDisabled: boolean
                ) =>
                  onMenuToggle(isOpen, selectedOptions, group, isFieldDisabled)
                }
              />
            </div>

            <div className="col-md-6 col-lg-4">
              <MultiSelectAll
                isFieldDisabled={!showOrg}
                label="Exec Org"
                options={cascadingDropDownState.org}
                selectedOptions={selectedOrgValue}
                onChange={(selectedOptions) =>
                  handleMultislecteChange(selectedOptions, "org")
                }
                onSelectAllChange={onSelectAllChange}
                group="org"
                initialSelectedOptions={selectedOrgValue}
                isLoading={loadingOrg}
                onMenuToggle={(
                  isOpen: boolean,
                  selectedOptions: Option[],
                  group: string,
                  isFieldDisabled: boolean
                ) =>
                  onMenuToggle(isOpen, selectedOptions, group, isFieldDisabled)
                }
              />
            </div>
            <div className="col-md-6 col-lg-4">
              <MultiSelectAll
                isFieldDisabled={!showOrgSummary}
                label="Exec Org Summary"
                options={cascadingDropDownState.orgsummary}
                selectedOptions={selectedOrgSummaryValue}
                onChange={(selectedOptions) =>
                  handleMultislecteChange(selectedOptions, "orgSummary")
                }
                onSelectAllChange={onSelectAllChange}
                group="orgSummary"
                initialSelectedOptions={selectedOrgSummaryValue}
                isLoading={loadingOrgSummary}
                onMenuToggle={(
                  isOpen: boolean,
                  selectedOptions: Option[],
                  group: string,
                  isFieldDisabled: boolean
                ) =>
                  onMenuToggle(isOpen, selectedOptions, group, isFieldDisabled)
                }
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default CascadingDropdown;
