import { AuthenticationParameters } from "msal"; 
import msalApp from "../../authConfig"; 
import Config from "../../Config"; 


const acquireToken = async () => { 
  let request: AuthenticationParameters = {
    authority: `https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47/`,
    scopes: [
      Config.ClientScope,
    ],
  };
  try {
    const login = await msalApp.acquireTokenSilent(request); 
    return login.accessToken;
  } catch (error) {
    
    await msalApp.loginPopup(request);
    const login = await msalApp.acquireTokenSilent(request); 
    return login.accessToken;
  }
};
  
export async function getToken() {
  try {
    // 
    let token = await acquireToken();
    return token;
  } catch {
    return "";
  }
}

export async function getUsername() { 
  try { 
    return msalApp.getAccount().userName.split('@')[0];
  } catch {
    return "";
  }
}



//msal.account.userName.split('@')[0]

// export async function getExportServiceToken() {
//   var exportConfig = {
//     ...adalConfig,
//     endpoints: { api: Config.exportEndPoint },
//   };
//   const exportContext = new AuthenticationContext(exportConfig);
//   var token = await getExportToken(exportContext, Config.exportEndPoint);
//   // exportContext.getCachedToken(adalConfig.clientId);
//   return token;
// }

// function getExportToken(
//   exportContext: AuthenticationContext,
//   endpoint: string
// ) {
//   return new Promise((resolve: (t: string) => void, reject) => {
//     exportContext.acquireToken(
//       endpoint,
//       (errorDesc: string | null, token: string | null, error: any) => {
//         if (error) {
//           exportContext.acquireTokenPopup(endpoint, null, null, (ed, t, e) => {
//             console.log(e);
//             resolve(t);
//           });
//           // exportContext.acquireTokenRedirect(Config.exportEndPoint, null, null);
//           // reject(errorDesc);
//         } else {
//           resolve(token);
//         }
//       }
//     );
//   });
//}
