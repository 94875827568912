import { Option } from './ActionType'; // separate file for defining types
import { SET_DEFAULT_SAP_COMPANY_CODES,SET_SELECTED_SAP_COMPANY_CODES, SET_SAP_COMPANY_CODES} from '../ActionContants/constants';

export const setSAPCompanyCodes = (sapCompanyCodes: Option[]) => ({
  type: SET_SAP_COMPANY_CODES,
  payload: sapCompanyCodes,
});

export const setSelectedSAPCompanyCodes = (selectedOptions: Option[]) => ({
  type: SET_SELECTED_SAP_COMPANY_CODES,
  payload: selectedOptions,
});

export const setDefaultSAPCompanyCodes = (selectedOptions: Option[]) => ({
  type: SET_DEFAULT_SAP_COMPANY_CODES,
  payload: selectedOptions,
});


export const setSelectedOptions = (options: Option[], group: string) => {
  return {
    type: 'SET_SELECTED_OPTIONS',
    payload: {
      options,
      group,
    },
  };
};
