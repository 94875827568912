import React, { useEffect, useState } from 'react';
import Select from 'react-select';

interface Option {
  value: string;
  label: string;
}

interface SingleSelectProps {
  label: string;
  options: Option[];
  selectedValue: Option[];
  onChange: (selectedValue: Option[]) => void;
  defaultValue: Option[];
  required?: boolean;
}

const SingleSelect: React.FC<SingleSelectProps> = ({
  label,
  options,
  selectedValue,
  onChange,
  defaultValue,
  required,
}) => {
  const [singleSelectedOption, setSingleSelectedOption] = useState<Option[]>([]);

  useEffect(() => {
    if (singleSelectedOption && singleSelectedOption.length === 0) {
      setSingleSelectedOption(defaultValue);
    } else {
      setSingleSelectedOption(selectedValue);
    }
  }, [selectedValue, defaultValue]);


  const handleSelectChange = (selectedValue:any) => {
    setSingleSelectedOption(selectedValue)
    onChange([selectedValue]);
  };

  return (
    <div className="mb-3">
      <div className="d-flex justify-content-between form-label">
        <label htmlFor={label}>{label}{required && <span> *</span>}</label>         
        <span>Select One</span>
      </div>
      <Select
        options={options}
        components={{
          IndicatorSeparator: () => null,
        }}
        value={singleSelectedOption}
        onChange={handleSelectChange}
        defaultValue={defaultValue}
        required={required}
        aria-label={label}
      />
    </div>
  );
};

export default SingleSelect;
