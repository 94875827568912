import store from "../../Redux/Store/Store";
export const SelectedFilterValues = () => {

  const { customMultiSelect, singleSelect, cascadingDropDown, gridData, fieldSort, checkboxes } = store.getState();

  return {
    selectedFiscalMonth:
      singleSelect.selectedFiscalMonths.length > 0 ?
        singleSelect.selectedFiscalMonths[0].value : "",
    selectedCompanies:
      customMultiSelect.selectedSAPCompanyCodes.length > 1
        ? customMultiSelect.selectedSAPCompanyCodes
          .map((e: any) => e.value).join(",")
        : customMultiSelect.selectedSAPCompanyCodes.length > 0 ?
          customMultiSelect.selectedSAPCompanyCodes[0].value.toString() : "",
          SelectedCompaniesCode:
      customMultiSelect.selectedSAPCompanyCodes.length > 1
        ? customMultiSelect.selectedSAPCompanyCodes
          .map((e: any) => e.label).join(",")
        : customMultiSelect.selectedSAPCompanyCodes.length > 0 ?
          customMultiSelect.selectedSAPCompanyCodes[0].label.toString() : "",
    selectedCurrencyType:
      singleSelect.selectedCurrencyTypes.length > 0 ?
        singleSelect.selectedCurrencyTypes[0].value : "",
    selectedDisplayIn:
      singleSelect.selectedDisplayOptions.length > 0 ?
        singleSelect.selectedDisplayOptions[0].value : "",
    selectedClass:
      cascadingDropDown.selectedClassesValue.length > 0 ?
        cascadingDropDown.selectedClassesValue.map((e: any) => e.value).join(",") : "",
    slectedClasstext:
      cascadingDropDown.selectedClassesValue.length > 0 ?
        cascadingDropDown.selectedClassesValue.map((e: any) => e.label).join(",") : "",
    selectedSubClass:
      cascadingDropDown.selectedSubClassesValue.length > 0 ?
        cascadingDropDown.selectedSubClassesValue.map((e: any) => e.value).join(",") : "",
    selectedSubClasstext:
      cascadingDropDown.selectedSubClassesValue.length > 0 ?
        cascadingDropDown.selectedSubClassesValue.map((e: any) => e.label).join(",") : "",
    selectedChannelGroup:
      cascadingDropDown.selectedGroupClassesValue.length > 0 ?
        cascadingDropDown.selectedGroupClassesValue.map((e: any) => e.value).join(",") : "",
    selectedChannelGrouptext:
      cascadingDropDown.selectedGroupClassesValue.length > 0 ?
        cascadingDropDown.selectedGroupClassesValue.map((e: any) => e.label).join(",") : "",
    selectedChannelLineItem:
      cascadingDropDown.selectedLineItemValue.length > 0 ?
        cascadingDropDown.selectedLineItemValue.map((e: any) => e.value).join(",") : "",
    selectedChannelLineItemtext:
      cascadingDropDown.selectedLineItemValue.length > 0 ?
        cascadingDropDown.selectedLineItemValue.map((e: any) => e.label).join(",") : "",
    selectedFinancialAccount:
      cascadingDropDown.selectedFinAccountValue.length > 0 ?
        cascadingDropDown.selectedFinAccountValue.map((e: any) => e.value).join(",") : "",
    selectedFinancialAccounttext:
      cascadingDropDown.selectedFinAccountValue.length > 0 ?
        cascadingDropDown.selectedFinAccountValue.map((e: any) => e.label).join(",") : "",
    selectedInternalOrder:
      cascadingDropDown.selectedOrderValue.length > 0 ?
        cascadingDropDown.selectedOrderValue.map((e: any) => e.value).join(",") : "",
    selectedInternalOrdertext:
      cascadingDropDown.selectedOrderValue.length > 0 ?
        cascadingDropDown.selectedOrderValue.map((e: any) => e.label).join(",") : "",
    selectedProfitCenter:
      cascadingDropDown.selectedProfitCenterValue.length > 0 ?
        cascadingDropDown.selectedProfitCenterValue.map((e: any) => e.value).join(",") : "",
    selectedProfitCentertext:
      cascadingDropDown.selectedProfitCenterValue.length > 0 ?
        cascadingDropDown.selectedProfitCenterValue.map((e: any) => e.label).join(",") : "",
    selectedCostCenter:
      cascadingDropDown.selectedCostCenterValue.length > 0 ?
        cascadingDropDown.selectedCostCenterValue.map((e: any) => e.value).join(",") : "",
    selectedCostCentertext:
      cascadingDropDown.selectedCostCenterValue.length > 0 ?
        cascadingDropDown.selectedCostCenterValue.map((e: any) => e.label).join(",") : "",
    selectExecOrg:
      cascadingDropDown.selectedOrgValue.length > 0 ?
        cascadingDropDown.selectedOrgValue.map((e: any) => e.value).join(",") : "",
    selectExecOrgtext:
      cascadingDropDown.selectedOrgValue.length > 0 ?
        cascadingDropDown.selectedOrgValue.map((e: any) => e.label).join(",") : "",
    selectExecSummaryOrg:
      cascadingDropDown.selectedOrgSummaryValue.length > 0 ?
        cascadingDropDown.selectedOrgSummaryValue.map((e: any) => e.value).join(",") : "",
    selectExecSummaryOrgtext:
      cascadingDropDown.selectedOrgSummaryValue.length > 0 ?
        cascadingDropDown.selectedOrgSummaryValue.map((e: any) => e.label).join(",") : "",
    selectedSortOptions:
      fieldSort.sortData.filter((x: any) => x.checked === true).map((y: any) => y.columnName).join(","),
    userAlias: "string",
    formatNbr:
      singleSelect.selectedDisplayOptions.length > 0 ?
        singleSelect.selectedDisplayOptions[0].value.toString() : "",
    selectedType: gridData.gridType.payload,
    chkExcludeData: checkboxes.excludeInsignificantBalance,
    chkExcludeZeroData: checkboxes.excludeZeroBalances,
    isDefault: false

  };
};

export const selectsortopt = (storeData = store.getState()) => {
  return storeData.fieldSort.selectedSortItems
    .map((e: any) => e.columnName)
    .join(",");
};

export const exportExcelParameters = (storeData = store.getState()) => {
  return storeData.gridData;
};

export const getClasses = () => {

  return [
    {
      "className": "Net Revenue",
      "classId": "16",
      "checked": false,
      "disable": true,
      "exportName": "NetRevenue"

    },
    {
      "className": "Cost of Revenue (ROC View)",
      "classId": "50",
      "checked": false,
      "disable": true,
      "exportName": "CostofRevenue"
    },
    {
      "className": "Operating Expenses",
      "classId": "51",
      "checked": false,
      "disable": true,
      "exportName": "OperatingExpenses"


    }, {
      "className": "Other (Income) Expense",
      "classId": "15",
      "checked": false,
      "disable": true,
      "exportName": "OtherIncomeExpense"


    }, {
      "className": "Other Income Tax Expense",
      "classId": "12",
      "checked": false,
      "disable": true,
      "exportName": "OtherIncomeTaxExpense"
    }
  ]
};

export const getChildSort = (sort: any, selectedlst: any) => {

  var lst = [{
    "sort": "subclass",
    "id": "1",
    "parentids": "0",
  },
  {
    "sort": "Group",
    "id": "2",
    "parentids": "1",

  }, {
    "sort": "LineItem",
    "id": "3",
    "parentids": "1,2",

  }, {
    "sort": "AccountNo",
    "id": "4",
    "parentids": "1,2,3",

  }, {
    "sort": "IONo",
    "id": "5",
    "parentids": "1,2,3,4",

  }, {
    "sort": "PCNo",
    "id": "6",
    "parentids": "1,2,3,4,5",

  }, {
    "sort": "CostCenter",
    "id": "7",
    "parentids": "1,2,3,4,5,6",

  }, {
    "sort": "ExecOrg",
    "id": "8",
    "parentids": "1,2,3,4,5,6,7",

  }, {
    "sort": "ExecSummary",
    "id": "9",
    "parentids": "1,2,3,4,5,6,7,8",

  }
  ]

  var selectedsort = lst.filter((x: any) => x.sort == sort);
  var childeren = lst.filter((y: any) => y.parentids.split(',').includes(selectedsort[0].id))
  var result: any[] = [];
  childeren.map((item: any) => {
    if ((JSON.stringify(selectedlst).includes(item.sort))) {
      result.push(item);
    }
  });

  return result.length > 0 ? result[0].sort : "";
};

export const FilterValuesValidate = () => {

  const result: any = [];
  const { fieldSort } = store.getState();

  var _SelectedFilterValues = SelectedFilterValues();

  _SelectedFilterValues.selectedCompanies == "" && result.push("Companies");
  _SelectedFilterValues.selectedClass == "" && result.push("Class");
  _SelectedFilterValues.selectedSubClass == "" && result.push("Subclass");

  result.length == 0 &&
    fieldSort.sortData.filter((x: any) => x.checked === true).map((y: any) => {
      switch (y.columnName) {
        case "Group":
          _SelectedFilterValues.selectedChannelGroup == "" && result.push("Group");
          break;
        case "LineItem":
          _SelectedFilterValues.selectedChannelLineItem == "" && result.push("LineItem");
          break;
        case "AccountNo":
          _SelectedFilterValues.selectedFinancialAccount == "" && result.push("AccountNo");
          break;
        case "IONo":
          _SelectedFilterValues.selectedInternalOrder == "" && result.push("IONo");
          break;
        case "PCNo":
          _SelectedFilterValues.selectedProfitCenter == "" && result.push("PCNo");
          break;
        case "CostCenter":
          _SelectedFilterValues.selectedCostCenter == "" && result.push("CostCenter");
          break;
        case "ExecOrg":
          _SelectedFilterValues.selectExecOrg == "" && result.push("ExecOrg");
          break;
        case "ExecSummary":
          _SelectedFilterValues.selectExecSummaryOrg == "" && result.push("ExecSummary");
          break;
        case "":
          break;
      }
    }

    )
  return result.length == 0 ? true : false;
};
