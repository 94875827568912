import { useState, useEffect, useRef } from "react";
import PreferenceSelect from "./PreferenceSelect";
import SingleSelectControl from "./SingleSelect";
import axios from "axios";
import { BsBookmark } from "react-icons/bs";
import { TfiSave, TfiClose } from "react-icons/tfi";
import Config from "../../Config";
import Form from "react-bootstrap/esm/Form";
import { useDispatch, useSelector } from "react-redux";
import { savePreferenceData } from "../Common/GetSavePreferenceData";
import {
  setIsPreferenceChangedAction,
  setFilterPreferencesDataAction,
  setSavedPreferencesDataAction,
  setSelectedFilterpreferncesAction,
  setSelectedFilterpreferncesDataAction,
} from "../../Redux/Actions/preferencesDataActions";
import { setTextboxValue } from "../../Redux/Actions/inputSavePreferenceAction";
import { FALSE } from "sass";
import SpinnerComponent from "../Common/SpinnerComponent";
import { getToken, getUsername } from "../Services/MsalService";
import msalApp from "../../authConfig";
import { Modal, ModalBody } from "react-bootstrap";
import { RootReducer } from "../../Redux/Reducers/types";
import ValidationError from "../Common/ValidationError";
import { initCascadingDropDowns } from "../../Redux/Actions/cascadingDropDownActions";
import { setShowFieldsFlagAction } from "../../Redux/Actions/includeFilterAction";
import {
  setPreferenceLoadedAction,
  setFilterSpinnerAction,
} from "../../Redux/Actions/CommonActionTypes";
import { FilterValuesValidate } from "../Common/GetDataFromStore";

interface Option {
  value: string;
  label: string;
}

function Preferences({ ...props }) {
  // Show Save Prefrence Alert
  const [showSavePrefAlert, setShowSavePrefAlert] = useState(false);
  const handleShowAlert = () => setShowSavePrefAlert(true);

  const [text, setText] = useState("");
  const [toggle, setToggle] = useState(false);
  const handleToggleSave = () => setToggle(!toggle);
  const {
    filterPreferncesList,
    selectedFilterPreference,
    defaultFilterPreference,
  } = useSelector((state: RootReducer) => state.preferences);
  const {
    selectedFiscalMonths,
    selectedCurrencyTypes,
    selectedDisplayOptions,
  } = useSelector((state: RootReducer) => state.singleSelect);

  const { selectedSAPCompanyCodes } = useSelector(
    (state: RootReducer) => state.customMultiSelect
  );

  const cascadingDropDownState = useSelector(
    (state: RootReducer) => state.cascadingDropDown
  );

  const commonStoreData = useSelector(
    (state: RootReducer) => state.commonStore
  );

  const preferenceData = useSelector(
    (state: RootReducer) => state.preferences
  );

  const checkboxState = useSelector((state: RootReducer) => state.checkboxes);
  const dispatch = useDispatch();

  const [showValidationError, setShowValidationError] = useState(false);
  const [matchTextBoxValue, setMatchTextBoxValue] = useState(false);
  const handleShowMatchTextAlert = () => setMatchTextBoxValue(true);

  //filterPreferncesList
  const handleShowValidationError = () => {
    setShowValidationError(true);
  };

  const handleModalHide = () => {
    setShowValidationError(false);
  };

  const handleValidateFilters = () => {
    if (
      selectedFiscalMonths.length === 0 ||
      selectedCurrencyTypes.length === 0 ||
      selectedSAPCompanyCodes.length === 0 ||
      selectedDisplayOptions.length === 0 ||
      cascadingDropDownState.selectedClassesValue.length === 0 ||
      cascadingDropDownState.selectedSubClassesValue.length === 0 ||
      cascadingDropDownState.selectedGroupClassesValue.length === 0 ||
      cascadingDropDownState.selectedLineItemValue.length === 0 ||
      cascadingDropDownState.selectedFinAccountValue.length === 0 ||
      cascadingDropDownState.selectedOrderValue.length === 0 ||
      cascadingDropDownState.selectedProfitCenterValue.length === 0 ||
      cascadingDropDownState.selectedCostCenterValue.length === 0 ||
      cascadingDropDownState.selectedOrgValue.length === 0 ||
      cascadingDropDownState.selectedOrgSummaryValue.length === 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleSavePreference = async () => {
   // const allFilterSelected = handleValidateFilters();
   const allFilterSelected = FilterValuesValidate();
    if (!allFilterSelected) {
      handleShowValidationError();
      return;
    }

    const isMatchFound = filterPreferncesList.some(
      (element: any) => element.label.toLowerCase() === text.toLowerCase()  );
  

    if (isMatchFound) {
      console.log("String match");
      handleShowMatchTextAlert();
    } else {
      setToggle(!toggle);
      setFilterSpinnerRedux(true);

      try {
        setText("");
        await getSavedPreferencesData(savePreferenceData());
        handleShowAlert();
      } catch (error) {
        console.log("Error if saving preferences fails", error);
      }
    }
  };

  const getSavedPreferencesData = async (savedparameters: any) => {
   // debugger;
    dispatch(await setSavedPreferencesDataAction(savedparameters));
    dispatch(setIsPreferenceChangedAction( [{
      value:0,
      label:savedparameters.preferenceName
    }] ));
    setFilterSpinnerRedux(false);
   // fetchPreferencesData();
  };

  const [show, setShow] = useState(true);
  const [prefernces, Setprefernces] = useState<Option[]>([]);
  const [loading, setLoading] = useState(false); // Loading state
  const [selectedDefaultPrefernce, setSelectedDefaultPrefernce] = useState<
    Option[]
  >([]);
  const [selectedPrefernce, setSelectedPrefernce] = useState<Option[]>([]);

  useEffect(() => {
    if (!commonStoreData.LoadPreference) {
      setPrefereceReduxStore();
      fetchPreferencesData();
    }
  }, []);

  // useEffect(() => {
  //   fetchPreferencesData();
  // }, [commonStoreData.LoadPreference]);

  const setPrefereceReduxStore = async () => {
    dispatch(await setPreferenceLoadedAction(true));
  };

  const fetchPreferencesData = async () => {
    try {
      setFilterSpinnerRedux(true);
      const response = await axios.get(
        `${Config.apiBaseAddress}${
          Config.apiGetPreference
        }?ReportID=1&UserAlias=${await getUsername()}`,
        { headers: { Authorization: `Bearer ${await getToken()}` } }
      );
      const data = response.data;
      if (data) {
        dispatch(setFilterPreferencesDataAction(data));
        if (data.preference == null) {
          dispatch(initCascadingDropDowns());
          // fetchData();
          dispatch(setShowFieldsFlagAction(false));
        }
       // setFilterSpinnerRedux(false);
      }

      //setFilterSpinnerRedux(false);
    } catch (error) {
      console.log("Error fetching dropdown data: ", error);
      setFilterSpinnerRedux(false);
    }
  };

  // const fetchData = async () => {
    // try {
    //   const response: any = await axios.get(
    //     `${Config.apiBaseAddress}${Config.apiGetFilter}`,
    //     { headers: { Authorization: `Bearer ${await getToken()}` } }
    //   );

    //   const dropdownData = response.data;
    //   // await dispatchFilterData(dropdownData, false);
    // } catch (error) {
    //   //// console.log("Error fetching dropdown data: ", error);
    //   //setLoading(false);
    // }
  // };

  const handleChangePrefrence = (e: any) => {


    (e.length > 0 && preferenceData.selectedFilterPreference[0].value == e[0].value) &&  dispatch((setIsPreferenceChangedAction("")))
    dispatch(setSelectedFilterpreferncesAction(e));
    dispatch(setSelectedFilterpreferncesDataAction(e));
    props.handleShowFieldsFlag(true);
  };

  const handleTextboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.trim().length > 0) {
      dispatch(setTextboxValue(value));
      setText(value);
      setShow(true);
    } else setText("");
  };

  const handleCallback = async (selectedValue: any,isreload:any) => {

    
    setFilterSpinnerRedux(true);

    const response = await axios.get(
      `${Config.apiBaseAddress}${Config.apiDeletepreferences}?preferceId=` +
        selectedValue +
        `&useralias=${await getUsername()}`,
      { headers: { Authorization: `Bearer ${await getToken()}` } }
    );


    if(isreload){
    setFilterSpinnerRedux(false);
    window.location.reload();
  }
  else{
    const deleteResponse = response.data;
    await fetchPreferencesData();
    !(selectedFilterPreference[0].value == selectedValue) &&
    setFilterSpinnerRedux(false);
  }
  };

  const setFilterSpinnerRedux = async (val: boolean) => {
    dispatch(await setFilterSpinnerAction(val));
  };
  return (
    <>
      <div className="preferences">
        <div className="row">
          <div className="col-md-4">
            <PreferenceSelect
              label="Preferences"
              options={filterPreferncesList}
              selectedValue={selectedFilterPreference}
              onChange={handleChangePrefrence}
              defaultValue={defaultFilterPreference}
              parentCallback={handleCallback}
            />
          </div>
          <div className="col-md-8">
            <div className="pref-control-wrap">
              <div className="row g-3 pref-text-btn">
                {toggle ? (
                  <>
                    <div className="col-auto mt-0">
                      <Form.Control
                        type="text"
                        onChange={handleTextboxChange}
                        placeholder="Enter Preference"
                      />
                    </div>
                    <div className="col-auto mt-0">
                      <button
                        type="button"
                        className="btn btn-dark"
                        onClick={handleSavePreference}
                        disabled={!text}
                        title="Save Preference"
                      >
                        <TfiSave />
                      </button>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div className="col-auto mt-0">
                  <button
                    type="button"
                    className="btn btn-dark"
                    onClick={handleToggleSave}
                    title="Open Close Preference"
                  >
                    {toggle ? <TfiClose /> : <BsBookmark />}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {matchTextBoxValue && (
        <Modal show={true} size="sm">
          <ModalBody>
            <p>
              Preference name already exists, please select a different name.
            </p>
            <div className="d-flex justify-content-end">
              <button
                className="btn-sm btn btn-dark px-3"
                onClick={() => setMatchTextBoxValue(false)}
              >
                Ok
              </button>
            </div>
          </ModalBody>
        </Modal>
      )}

      {showSavePrefAlert && (
        <Modal show={true} size="sm">
          <ModalBody>
            <p>Preference Added Successfully!</p>
            <div className="d-flex justify-content-end">
              <button
                className="btn-sm btn btn-dark px-3"
                onClick={() => setShowSavePrefAlert(false)}
              >
                Ok
              </button>
            </div>
          </ModalBody>
        </Modal>
      )}
      <ValidationError
        show={showValidationError}
        handleModalHide={handleModalHide}
      />
    </>
  );
}

export default Preferences;
