import React, { useCallback, useState, useEffect, useReducer } from "react";
import { Cell } from "../Cell/Cell";
import { SelectedFilterValues } from "../Common/GetDataFromStore";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "../../Redux/Reducers/types";
import { clearCommentData } from "../../Redux/Actions/commentActions";
interface Props {
  data: any;
  handlecallback: (val: any, state: any) => void;
  iscolHeader: boolean;
  activeButton: boolean;
  handlecallbackActiveButton: (val: any) => void;
  selectedColumns: string[];
}
export const RowColumn: React.FC<Props> = (Props) => {
  const Data = useSelector((state: RootReducer) => state);

  const { data, handlecallback, iscolHeader, activeButton, handlecallbackActiveButton, selectedColumns } = Props;
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const monthId = Data.singleSelect.selectedFiscalMonths?.[0]?.value || 'DefaultMonth';
  const sAPCompanyID = (Data.customMultiSelect.selectedSAPCompanyCodes.length > 0 ? Data.customMultiSelect.selectedSAPCompanyCodes.map((e: any) => e.value).join(",") : "").toString();
  const dispatch = useDispatch();

  useEffect(() => {
    //debugger
    if(monthId =='DefaultMonth')
    {
      if (data.desc === Data.commentDATA.desc) {
      
        data.comments = Data.commentDATA.commentList;
        dispatch(clearCommentData());
      }
    }      
    else if (data.desc === Data.commentDATA.desc && monthId == Data.commentDATA.selectedFiscalMonthID && sAPCompanyID == Data.commentDATA.selectedSAPCompanyID) {
      
      data.comments = Data.commentDATA.commentList;
      dispatch(clearCommentData());
    }
  }, [Data.commentDATA, data]);

  //console.log("Selected Headers in RowColumn:", selectedHeaders);
  //item.isChilderen = item.desc == "Grand Total" ? false : Data.gridData.gridData.actualsClasses.filter((i: any) => i.parentId == item.id).length > 0 ? true : false;
  //
  let selectedValues = SelectedFilterValues();
 // let ischild = !iscolHeader ? data.isChilderen : false;
 let ischild = data.desc == "Grand Total" ? false : Data.gridData.gridData.actualsClasses.filter((i: any) => i.parentId == data.id).length > 0 ? true : false;
  let id = !iscolHeader ? data.id : 1111;
  let Expanded = !iscolHeader ? data.exapnded : false;
  let width = !iscolHeader ? (16 * parseInt(data.level)).toString() + "px" : "0px";
  let values = Object.entries(data);
  let GroupType = data.groupType;
  let Groupcalss = parseInt(data.level) == 0 ? "group-row" : selectedValues.selectedType === "QTD/YTD" && iscolHeader?"qtdytdheadcss":"";
  let Grand_Totalcss = data.desc == "Grand Total" ? "grandTotalRow" : "";

  let className = iscolHeader?`c-row`:`${Groupcalss} ${Grand_Totalcss}`;
  className= className.trim() == ""?'c-data':className;

  const handlecallbackr = (val: any, state: any) => {
    handlecallback(val, state);
  };
  const handlecallbackActiveButtonr = (val: any) => {
    handlecallbackActiveButton(val);
  };
  const [forceRender, setForceRender] = useState(false);

  useEffect(() => {
    setForceRender(!forceRender);
  }, [selectedColumns]);
  const qtdColumns = ['cyqtdactual', 'pyqtdactual', 'qtddollarvar', 'qtdpercentagevar', 'cycphqtd', 'pycphqtd', 'cphdollarvarqtd', 'cphqtdpercentage'];
  const ytdColumns = ['cyytdactual', 'pyytdactual', 'ytddollarvar', 'ytdpercentagevar', 'cycphytd', 'pycphytd', 'cphdollarvarytd', 'cphytdpercentage'];
  let selectedColumnsQtd = qtdColumns.filter((x) =>
    selectedColumns.includes(x)
  );
  let selectedColumnsYtd = ytdColumns.filter((x) =>
    selectedColumns.includes(x)
  );
  // const minWidthQtd = selectedColumnsQtd.length * 175;
  // const minWidthYtd = selectedColumnsYtd.length * 175;
const minWidthQtd = selectedColumnsQtd.length * 100 +"%";
const minWidthYtd =selectedColumnsYtd.length *  100 +"%";
  return (
    <>
      {iscolHeader && selectedValues.selectedType === "QTD/YTD" && <div className="c-row qtd-ytd-row">
        <div className="tree-column" style={{width: 100+"%"}}></div>
        {/* {selectedColumnsQtd.length !== 0 && <div className="tree-column" style={{ minWidth: minWidthQtd }} >QTD</div>}
        {selectedColumnsYtd.length !== 0 && <div className="tree-column" style={{ minWidth: minWidthYtd }}>YTD</div>} */}
        {selectedColumnsQtd.length !== 0 && <div className="tree-column" style={{ width: minWidthQtd }} >QTD</div>}
        {selectedColumnsYtd.length !== 0 && <div className="tree-column" style={{ width: minWidthYtd }}>YTD</div>}
      </div>
      }
      <div className={className}>
        {iscolHeader &&
          values.map((x) => {
             
            if (selectedColumns.includes(x[0].toLowerCase()) ||
              x[0] == "desc"
            ) {
              return (
                <Cell
                  celldata={x[1]}
                  ischild={ischild}
                  GroupName={x[1]}
                  id={id}
                  handlecallback={handlecallbackr}
                  width={width}
                  Expanded={Expanded}
                  iscolHeader={iscolHeader}
                  colIndex={x[0] == "desc" ? "col1" : x[0]}
                  activeButton={activeButton}
                  handlecallbackActiveButton={handlecallbackActiveButtonr}
                  commentslst={null}
                  length={selectedColumns.length}
                ></Cell>
              );
            }
          })}

        {!iscolHeader &&

          values.map((x) => {
            //console.log("Processing column:", x[0]);
            if (selectedColumns.includes(x[0].toLowerCase()) ||
              x[0] == "desc"
            ) {
              
              return (
                <Cell
                  celldata={x[1]}
                  ischild={ischild}
                  GroupName={x[0]}
                  id={id}
                  handlecallback={handlecallbackr}
                  width={width}
                  Expanded={Expanded}
                  iscolHeader={iscolHeader}
                  checkNm={x[0] == "percentageVar" || x[0] == "qtdPercentageVar" || x[0] == "ytdPercentageVar" ? true : false}
                  GroupType={GroupType}
                  colIndex={x[0]}
                  activeButton={activeButton}
                  handlecallbackActiveButton={handlecallbackActiveButtonr}
                  commentslst={data.comments}
                  length={selectedColumns.length}
                ></Cell>
              );
            }
            else return null;
          })}

      </div>
    </>
  );
};
