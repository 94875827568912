import React, { useEffect, useState } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";
import arrayMove from "array-move";

import { GrDrag } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Config from "../../Config";
import SpinnerComponent from "../Common/SpinnerComponent";
import {
  setSelectedSortItems,
  setSortData,
  sortToggleChecked,
} from "../../Redux/Actions/fieldSortAction";
import { RootReducer } from "../../Redux/Reducers/types";
import { SortItem } from "../Common/InterfaceSortIem";
import { SelectedFilterValues } from "../Common/GetDataFromStore";
import { getToken } from "../Services/MsalService";

interface Props {
  displayFilterTab: () => void;
}
const FieldSort: React.FC<Props> = (Props) => {
  const { displayFilterTab } = Props;
  const dispatch = useDispatch();
  const { sortData, selectedSortItems } = useSelector(
    (state: RootReducer) => state.fieldSort
  );
  const { selectedFilterPreference, preferenceData } = useSelector(
    (state: RootReducer) => state.preferences
  );
  const [loading, setLoading] = useState(false); // Loading state
  const [items, setItems] = React.useState<SortItem[]>(sortData);
  const [selecteditems, setselectedItems] = React.useState<SortItem[]>(selectedSortItems);

  useEffect(() => {
    // console.log("sortData:",sortData)
    if (selectedFilterPreference.length > 0) setItems(sortData);
  }, [selectedFilterPreference]);

  useEffect(() => {
    const updatedSelectedSortItems = items.filter(
      (item: SortItem) => item.checked
    );
    dispatch(setSelectedSortItems(updatedSelectedSortItems));
  }, [items]);
  useEffect(() => {
    dispatch(setSelectedSortItems(selecteditems));
  }, [selecteditems]);

  const chkonChange = (e: any) => {


    const columnName = e.target.value;
    dispatch(sortToggleChecked(columnName));

    const updatedSortData = items.map((item: SortItem) =>
      item.columnName === columnName
        ? { ...item, checked: !item.checked }
        : item
    );
    items.map((item: SortItem) =>
      item.columnName === columnName
        ? { ...item, checked: !item.checked }
        : item
    );

    setItems(updatedSortData);
  };

  const handleNext = () => {
    console.log(SelectedFilterValues());
    displayFilterTab();
  };

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    let updatedItems = arrayMove(items, oldIndex, newIndex);
    setItems(updatedItems);
    dispatch(setSortData(updatedItems));
  };

  return (
    <div className="field-sort">
      <p>Class and Subclass location fixed</p>

      {loading ? (
        <SpinnerComponent text="Loading..." />
      ) : (
        <SortableList
          className="list mb-3"
          onSortEnd={onSortEnd}
          draggedItemClassName="dragged"
        >
          {sortData.map(
            (item: SortItem, index: React.Key | null | undefined) => (
              <SortableItem key={index}>
                <div className="item">
                  <Form.Check
                    type="checkbox"
                    label={item.columnHeader}
                    value={item.columnName}
                    title={item.columnHeader}
                    name="Sorting"
                    id={item.columnName}
                    onChange={chkonChange}
                    checked={item.checked}
                  />
                  <SortableKnob>
                    <div style={{ cursor: "grab" }}>
                      <GrDrag />
                    </div>
                  </SortableKnob>
                </div>
              </SortableItem>
            )
          )}
        </SortableList>
      )}

      <button className="btn btn-dark btn-sm" onClick={handleNext}>
        Next
      </button>
    </div>
  );
};

export default FieldSort;
