import { SortItem } from "../../Components/Common/InterfaceSortIem";
import { SET_ROLEID_STATUS,SET_USER_ADMINSTRATION_ICON_STATUS,SET_CAN_ENTER_STATUS, SET_COMMENTARY_STATUS, SET_SELECTED_SORT_ITEMS, SET_SORT_DATA, SORT_SORT_CHECKED_NULL, SORT_TOGGLE_CHECKED, SET_CAN_VIEW_STATUS }  from "../ActionContants/constants";

export const setSortData = (data: any) => ({
  type: SET_SORT_DATA,
  payload: data,
});

export const setSelectedSortItems = (selectedSortItems:SortItem[]) => ({
    type: SET_SELECTED_SORT_ITEMS,
    payload: selectedSortItems,
})

export const sortToggleChecked = (columnName: any) => ({
  type: SORT_TOGGLE_CHECKED,
  payload: columnName,
});

export const sortSortChecked = () => ({
  type: SORT_SORT_CHECKED_NULL
});

export const setCommentaryStatusAction= (data: any) => ({
  type: SET_COMMENTARY_STATUS,
  payload: data,
});

export const setRolIDAction= (data: any) => ({
  type: SET_ROLEID_STATUS,
  payload: data,
});
export const setUserAdminstrationIconAction= (data: any) => ({
  type: SET_USER_ADMINSTRATION_ICON_STATUS,
  payload: data,
});
export const setCanViewAction= (data: any) => ({
  type: SET_CAN_VIEW_STATUS,
  payload: data,
});
export const setCanEnterAction= (data: any) => ({
  type: SET_CAN_ENTER_STATUS,
  payload: data,
});

